import React from "react";
import Layout from "../Layout/Layout";

import Form from "react-bootstrap/Form";
// import { useContext } from "react";
// import { myContext } from "../../App";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { api } from "../../assets/API/api";
import { Button, Modal, Table } from "react-bootstrap";
// import Table from "react-bootstrap/esm/Table";
import { useRef } from "react";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import UploadProfileImage from "../UploadImage/UploadImage";
import no_image from "../../assets/images/no_image.png";
import Loader from "../Loader/loader";
import NoDataFound from "../NoDataFound/noDataFound";

var arr = [];

export default function UserControl() {
  // const st = useContext(myContext);
  // const [authenticate, setAuthenticate] = st.authentication;
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    profile: "",
  });
  const [image, setImage] = useState();
  const [profileImage, setProfileImage] = useState("");
  const [checked, setChecked] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();

  const handleClose = () => {
    setShow(false);
    setForm({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      profile: "",
    });
  };

  useEffect(() => {
    setForm({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      profile: "",
    });
    if (temp?.user_id && temp?.userType === "Company Head") {
      setLoading(true);
      axios
        .get(
          `${api}/getAllUsers/?tokenMail=${temp?.email}&boss=true&pageNo=${pageNo}`
        )
        .then((res) => {
          // console.log(res.data);
          if (!res.data?.length) {
            setPageNo(1);
          }
          setUsers(res.data);
          arr = res.data;
          let pState = [];
          // for (let i = 0; i < res.data.length; i++) {
          //     pState.push(false);
          // }
          setChecked(pState);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [pageNo]);

  const fullUserList = () => {
    setUsers([]);
    if (!temp.user_id || !temp?.userType === "Company Head") {
      return;
    }
    setLoading(true);
    axios
      .get(`${api}/getAllUsers/?tokenMail=${temp?.email}&boss=true`)
      .then((res) => {
        // console.log(res.data);
        if (!res.data?.length) {
          setPageNo(1);
        }
        setUsers(res.data);
        arr = res.data;
        let pState = [];
        for (let i = 0; i < res.data.length; i++) {
          pState.push(false);
        }
        setChecked(pState);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    if (name === "state") {
      if (!val) setUsers(arr);
      else
        setUsers(
          arr.filter((el) =>
            el.state?.toLowerCase().includes(val.toLowerCase())
          )
        );
    }
    if (name === "city") {
      if (!val) setUsers(arr);
      else
        setUsers(
          arr.filter((el) => el.city?.toLowerCase().includes(val.toLowerCase()))
        );
    }
    if (name == "country") {
      if (!val) setUsers(arr);
      else
        setUsers(
          arr.filter((el) =>
            el.country?.toLowerCase().includes(val.toLowerCase())
          )
        );
    }
    if (name == "email-filter") {
      if (!val) setUsers(arr);
      else
        setUsers(
          arr.filter((el) =>
            el.email?.toLowerCase().includes(val.toLowerCase())
          )
        );
    }

    setForm({
      ...form,
      [name]: val,
    });
  };

  const updateUser = (e) => {
    e.preventDefault();

    if (!temp?.user_id) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/updateUser?tokenMail=${temp?.email}`, form)
      .then((res) => {
        // console.log(res.data);
        // uploadProfile();
        alert("Profile updated successfully!");
        setShow(false);
        axios
          .get(
            `${api}/getAllUsers/?tokenMail=${temp?.email}&boss=true&pageNo=${pageNo}`
          )
          .then((res) => {
            // console.log(res.data);
            if (!res.data?.length) {
              setPageNo(1);
            }
            setUsers(res.data);
            arr = res.data;
            let pState = [];
            for (let i = 0; i < res.data.length; i++) {
              pState.push(false);
            }
            setChecked(pState);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => alert(err?.response?.data))
      .finally((f) => setLoading(false));
  };

  const blockUser = (el) => {
    // e.preventDefault();

    if (!temp?.user_id) {
      return;
    }

    let ok = window.confirm(
      `Are you sure to ${el?.block ? "Unblock" : "Block"} this user?`
    );

    if (!ok) {
      return;
    }

    setLoading(true);
    axios
      .post(`${api}/blockUser?tokenMail=${temp?.email}`, { id: el.user_id })
      .then(async (res) => {
        // console.log(res.data);

        await axios
          .get(
            `${api}/getAllUsers/?tokenMail=${temp?.email}&boss=true&pageNo=${pageNo}`
          )
          .then((res) => {
            // console.log(res.data);
            if (!res.data?.length) {
              setPageNo(1);
            }
            setUsers(res.data);
            arr = res.data;
            let pState = [];
            for (let i = 0; i < res.data.length; i++) {
              pState.push(false);
            }
            setChecked(pState);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
        // alert(`${el?.block ? "Unblock" : "Block"}ed Successfully!`);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const ref = useRef(null);

  useEffect(() => {
    if (show) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [show]);

  const deleteUser = async (user) => {
    if (!temp?.user_id) {
      return;
    }

    setLoading(true);
    try {
      let ok = window.confirm("Are you sure to delete the user?");
      if (!ok) {
        return;
      }
      await axios.delete(
        `${api}/delete-user/?tokenMail=${temp?.email}&user_id=${user?.user_id}`
      );
      // alert('Deleted successfully!')
      fullUserList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // console.log(selectedUsers);
  // console.log(form)

  return (
    <Layout sideBarActive={10}>
      <div>
        <h1 style={{ fontWeight: "600", fontSize: "1rem" }}>Filter Options</h1>
        <div className="filter_admin">
          <div>
            <div>City:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="city"
              onChange={handleChange}
              placeholder="filter users based on city"
              type={"text"}
            />
          </div>
          <div>
            <div>State:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="state"
              onChange={handleChange}
              placeholder="filter users based on state"
              type={"text"}
            />
          </div>
          <div>
            <div>Country:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="country"
              onChange={handleChange}
              placeholder="filter users based on country"
              type={"text"}
            />
          </div>
          <div>
            <div>Email:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="email-filter"
              onChange={handleChange}
              placeholder="filter users based on email"
              type={"text"}
            />
          </div>
        </div>
        <h1 style={{ fontSize: "21px", fontWeight: "600" }}>List Of Users</h1>
        <div className="scrollableTabel autoHeight load_animation">
          {/* {loading ? <Loader /> : */}
          <Table
            className="user-list-table"
            style={{ minWidth: "100%" }}
            striped
            responsive1
          >
            <thead>
              <tr>
                {/* <th style={{ zIndex: "2" }}><input onChange={e => selectAll(e)} style={{ width: "20px", height: "20px", marginRight: "10px" }} type={"checkbox"} /><label>Select All</label></th> */}
                <th style={{ zIndex: "2" }}>Name</th>
                <th>Email</th>
                <th>Account Type</th>
                <th
                  style={{
                    zIndex: "2",
                    paddingLeft: "55px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!users?.length
                ? null
                : users?.map((el, idx) => {
                    return (
                      <tr>
                        <td className="fw-bold">
                          <div className="viewDiv">
                            <img
                              style={{
                                height: "42px",
                                width: "42px",
                                borderRadius: "30px",
                              }}
                              src={el.image_url || no_image}
                              className=""
                              loading="lazy"
                              alt=""
                            />
                            {el?.firstName || "NA"} {el?.lastName || ""}
                          </div>
                        </td>
                        <td>{el.email}</td>
                        <td>{el?.account_type}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              gap: "15px",
                            }}
                          >
                            <Button
                              style={{
                                // height: "30px",
                                // width: "50px",
                                boxShadow: "0px 0px 10px grey",
                                padding: "3px 5px",
                                borderRadius: "5px",
                                backgroundColor: el.block
                                  ? "lightgreen"
                                  : "crimson",
                                border: el.block
                                  ? "1px solid lightgreen"
                                  : "1px solid crimson",
                                color: el.block ? "black" : "",
                              }}
                              id={el.user_id}
                              onClick={
                                loading
                                  ? null
                                  : () => {
                                      blockUser(el);
                                    }
                              }
                            >
                              {el.block ? "Unblock" : "Block"}
                            </Button>
                            <FontAwesomeIcon
                              onClick={(e) => {
                                e.preventDefault();
                                setForm({
                                  ...form,
                                  id: el?.user_id,
                                  firstName: el?.firstName,
                                  lastName: el?.lastName,
                                  email: el?.email,
                                  password: el?.password,
                                  mobile: el?.mobile,
                                });
                                setShow(true);
                              }}
                              style={{ cursor: "pointer" }}
                              icon={faPenSquare}
                              size="2x"
                              color="#1ea5d4"
                            />
                            <span
                              onClick={
                                loading
                                  ? null
                                  : () => {
                                      deleteUser(el);
                                    }
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color={"crimson"}
                                size="2x"
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          {loading && <Loader />}
          {!loading && !users?.length && (
            <NoDataFound text={"No Users Found"} />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Button
            className="button_shadow"
            onClick={loading ? null : fullUserList}
          >
            Full User List
          </Button>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              className="button_shadow"
              onClick={
                loading
                  ? null
                  : () => {
                      if (pageNo <= 1) {
                        return;
                      }
                      setPageNo((p) => p - 1);
                    }
              }
              style={{ marginRight: "15px" }}
            >
              Prev
            </Button>
            <div style={{ marginRight: "15px", padding: "7px" }}>
              Page No: {pageNo}
            </div>
            <Button
              className="button_shadow"
              onClick={
                loading
                  ? null
                  : () => {
                      setPageNo((p) => p + 1);
                    }
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose} className="coupon_modal">
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <Form>
            <div
              ref={ref}
              className="planCreate"
              style={{ marginBottom: "0px" }}
            >
              {/* <h1>User Access Control</h1> */}

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="firstName"
                      value={form.firstName}
                      type="text"
                      placeholder="First Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="lastName"
                      value={form.lastName}
                      type="text"
                      placeholder="Last Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="email"
                      value={form.email}
                      type="Email"
                      placeholder="Email"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="mobile"
                      value={form.mobile}
                      type="text"
                      placeholder="Mobile Number"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="password"
                      value={form.password}
                      type="text"
                      placeholder="Password"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    {/* <Form.Label>Profile Image</Form.Label> */}
                    <UploadProfileImage user_id={form.id} personal={false} />
                    {/* <Form.Control onChange={(e) => { setImage(e.target.files[0]) }} name="profile" type="file" placeholder="Profile Image" /> */}
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Button
                      disabled={loading}
                      className="button_shadow"
                      datatype="new-search"
                      onClick={loading ? null : updateUser}
                      style={{ width: "100%" }}
                    >
                      {loading ? "Updating..." : "Update"}
                    </Button>
                    {/* <Form.Control type="submit" value="Update" /> */}
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
