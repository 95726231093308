import React from "react";
// import { Link } from 'react-router-dom'

import login_logo_footer from "../../assets/images/login_logo_footer.png";
import linkdin_icon from "../../assets/images/linkdin_icon.png";
import facebook_icon from "../../assets/images/facebook_icon.png";
import twiteer_icon from "../../assets/images/twiteer_icon.png";
import insta_icon from "../../assets/images/insta_icon.png";

const FooterTwo = () => {
  return (
    <div style={{ position: "absolute", left: 0, right: 0 }}>
      <div className="footerTwo">
        <div className="left">
          <ul>
            <li>
              <p className="head">Join Our Exclusive Community</p>
              <p className="social_footer_icon" style={{ display: "flex" }}>
                <a
                  href="https://www.linkedin.com/company/workplace-readiness-solutions/"
                  target={"_blank"}
                >
                  <img src={linkdin_icon} className="" loading="lazy" alt="" />
                </a>
                <a
                  href="https://www.facebook.com/upskilltalent"
                  target="_blank"
                >
                  <img src={facebook_icon} className="" loading="lazy" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/upskilltalent2022/"
                  target={"_blank"}
                >
                  <img
                    style={{ width: "30px", borderRadius: "7px" }}
                    src={insta_icon}
                    className=""
                    loading="lazy"
                    alt=""
                  />
                </a>
                <a href="https://twitter.com/upskilltalent" target={"_blank"}>
                  <img
                    style={{ width: "34px" }}
                    src={twiteer_icon}
                    className=""
                    loading="lazy"
                    alt=""
                  />
                </a>
              </p>
            </li>
            <li>
              <p className="head">Our Mission</p>
              <p>
                Become the essential component in making the global workforce
                smarter.
              </p>
            </li>
            <li>
              <p className="head">Our Vision</p>
              <p>
                To provide the knowledge and insight needed to solve tomorrow’s
                skills gaps.
              </p>
            </li>
          </ul>
        </div>
        <div className="right">
          <div className="rightLeft">
            <ul>
              <li className="head">About</li>
              <li className="footer_link">
                <a href="https://upskilltalent.com/news" target={"_blank"}>
                  Blog
                </a>
              </li>
              {/* <li className='footer_link'><a >Company Info</a></li> */}
              <li className="footer_link">
                <a
                  href="https://upskilltalent.com/terms-of-use/"
                  target={"_blank"}
                >
                  Privacy & Terms of Use
                </a>
              </li>
              {/* <li className='footer_link'><a href='https://upskilltalent.com/' target={'_blank'}>Product</a></li> */}
              {/* <li className='footer_link'><a >Resources</a></li> */}
              <li className="footer_link">
                <a href="https://upskilltalent.com/about-us/" target={"_blank"}>
                  Team
                </a>
              </li>
              <li className="footer_link">
                <a href="https://upskilltalent.com/tools" target={"_blank"}>
                  Tools
                </a>
              </li>
            </ul>
          </div>
          <div className="rightRight">
            <ul>
              <li>
                <p className="head">Contact</p>
              </li>
              <li>
                <p className="head">General Info:</p>
                <p>
                  <a href="mailto:info@upskilltalent.com">
                    info@upskilltalent.com
                  </a>
                </p>
              </li>
              <li>
                <p className="head">Sales:</p>
                <p>
                  <a href="mailto:info@upskilltalent.com">
                    info@upskilltalent.com
                  </a>
                </p>
              </li>
              <li>
                <p className="head">Tech support:</p>
                <p>
                  <a href="mailto:tech@upskilltalent.com">
                    tech@upskilltalent.com
                  </a>
                </p>
              </li>
              {/* <li>
                              <p className='head'>Email:</p>
                              <p><Link to='#'>tech@upskilltalent.com</Link></p>
                          </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
          © 2019 Upskill Talent. All rights reserved.
        </div>
      {/* <div className="footerTwoBottom">
        <ul>
          <li className="left">
            <img src={login_logo_footer} className="" loading="lazy" alt="" />
          </li>
          <li className="right">© 2019 Upskill Talent. All rights reserved.</li>
        </ul>
      </div> */}
    </div>
  );
};

export default FooterTwo;
