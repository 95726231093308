import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import logo_inner from "../../assets/images/logo_inner.png";
import Search_icon from "../../assets/images/Search_icon.png";
import log_out_icon from "../../assets/images/log_out_icon.png";
import add_user from "../../assets/images/add_user.png";
// import home from '../../assets/images/home.png'
import info from "../../assets/images/info.png";
import { useContext } from "react";
import { myContext } from "../../App";
// import { api } from '../../assets/API/api'
import "../Users/style.css";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faBrain,
  faGift,
  faHome,
  faInfoCircle,
  faMagnifyingGlassChart,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
// import axios from 'axios';
// import { getSideBar, setSideBar } from '../../customHooks/sideBar'

const LeftMenu = (props) => {
  const { sideBarActive } = props;

  const st = useContext(myContext);
  // const [authenticate, setAuthenticate] = st.authentication;
  const [userInfo, setUserInfo] = st.userInfo;
  const [activePlans, setActivePlans] = st.activePlans;
  const [trial, setTrial] = st.trial;
  // const [sideBarActive, setSideBarActive] = st.sideBar;
  const temp = getSessionStorage();
  const actPlans = JSON.parse(localStorage.getItem("active-plans")) || [];

  const profile = JSON.parse(localStorage.getItem("profile"));

  // console.log(sideBarActive);

  // const handleClick = e => {
  //   let name = e.target.name;

  //   // if (name === 'Dashboard') { setSideBarActive(0) }
  //   // if (name === 'PreviousSearchResults') { setSideBarActive(1) }
  //   // if (name === 'FavoriteSearches') { setSideBarActive(2) }
  //   // if (name === 'FAQ') { setSideBarActive(4) }
  //   // if (name === 'TransactionHistory') { setSideBarActive(5) }
  //   // if (name === 'GiftCard') { setSideBarActive(8) }
  //   // if (name === 'About') { setSideBarActive(7) }
  //   // if (name === 'SubscriptionDetails') { setSideBarActive(6) }
  //   // if (name === 'UserControl') { setSideBarActive(10) }
  //   // if (name === 'SubscriptionControl') { setSideBarActive(11) }
  //   // if (name === 'NotificationControl') { setSideBarActive(12) }
  //   // if (name === 'CouponControl') { setSideBarActive(13) }
  //   // if (name === 'GiftCardControl') { setSideBarActive(14) }

  // }

  // console.log(profile?.organisation_logo);

  return (
    <div>
      {userInfo.id || temp?.user_id ? (
        <div
          style={{ overflow: "auto", height: "100vh", paddingTop: "0" }}
          className="left_menu_outer"
        >
          <div
            className="left_menu_logo"
            style={{
              position: "sticky",
              top: "0",
              paddingTop: "25px",
              backgroundColor: "white",
            }}
          >
            <Link to={"/DashboardOldUser"}>
              <img
                style={{ width: "130px", height: "92px" }}
                src={
                  (temp?.added_by?.includes("@") &&
                    profile?.organisation_logo) ||
                  logo_inner
                }
                className=""
                loading="lazy"
              />
            </Link>
          </div>
          <div style={{ textAlign: "center", marginTop: "14px" }}>
            <Link to="/DashboardOldUser">
              <Button
                variant="primary"
                className="login"
                style={{
                  backgroundColor: "#0f4c62",
                  border: "1px solid #0f4c62",
                  width: "100%",
                  display: "flex",
                  padding: "7px 12px",
                }}
              >
                <FontAwesomeIcon icon={faHome} size="lg" />
                <span style={{ marginLeft: "11px", fontWeight: "600" }}>
                  Home
                </span>
              </Button>
            </Link>
          </div>
          {temp?.userType == "Company Head" ? null : (
            <div className="left_menu_searchButton">
              {temp?.userType == "enterprise-user" ? null : trial ? (
                <Link to={"/NewSearch"}>
                  <Button
                    style={{
                      backgroundColor: "#0f4c62",
                      border: "1px solid #0f4c62",
                    }}
                    variant="primary"
                    type="submit"
                    className="login"
                  >
                    <img src={Search_icon} className="" loading="lazy" />
                    {"ILLUMINATE NOW"}
                  </Button>
                </Link>
              ) : (
                <Link
                  to={
                    activePlans[0]?.no_of_users > 1 ||
                    actPlans[0]?.no_of_users > 1
                      ? "/AddUsers"
                      : "/NewSearch"
                  }
                >
                  <Button
                    variant="primary"
                    type="submit"
                    className="login"
                    style={{
                      backgroundColor: "#0f4c62",
                      border: "1px solid #0f4c62",
                    }}
                  >
                    <img
                      src={
                        activePlans[0]?.no_of_users > 1 ||
                        actPlans[0]?.no_of_users > 1
                          ? add_user
                          : Search_icon
                      }
                      className=""
                      loading="lazy"
                    />
                    {activePlans[0]?.no_of_users > 1 ||
                    actPlans[0]?.no_of_users > 1
                      ? "Add Users"
                      : "ILLUMINATE NOW"}
                  </Button>
                </Link>
              )}
              {temp?.userType === "enterprise-user" ? (
                <Link to={"/enterprise-menu"}>
                  <Button
                    variant="primary"
                    className="login"
                    style={{
                      backgroundColor: "#0f4c62",
                      border: "1px solid #0f4c62",
                      width: "100%",
                      display: "flex",
                      padding: "8px 15px",
                      gap: "16px",
                    }}
                  >
                    <FontAwesomeIcon icon={faSitemap} />
                    <span>Enterprise Menu</span>
                  </Button>
                </Link>
              ) : null}
            </div>
          )}

          {temp?.userType == "Company Head" ? (
            <ul className="left_menu_ul">
              <li
                className={
                  sideBarActive == 0 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="Dashboard" to="/DashboardOldUser">
                  Dashboard
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 10 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="UserControl" to={"/UserControl"}>
                  User's Control
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 11 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="SubscriptionControl" to={"/SubscriptionControl"}>
                  Subscription Control
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 12 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="NotificationControl" to="/NotificationControl">
                  Notification Control
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 13 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="CouponControl" to="/CouponControl">
                  Coupon Control
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 14 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="GiftCardControl" to="/GiftCardControl">
                  Gift Card Control
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 15 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="EnterpriseControl" to="/EnterpriseControl">
                  Enterprise Control
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="left_menu_ul">
              <li
                className={
                  sideBarActive == 0 ? "Dashboard active" : "Dashboard"
                }
              >
                <Link name="Dashboard" to="/DashboardOldUser">
                  Dashboard
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 1
                    ? "PreviousSearchResults active"
                    : "PreviousSearchResults"
                }
              >
                <Link name="PreviousSearchResults" to="/PreviousSearchResults">
                  Previous Search History
                </Link>
              </li>
              <li
                className={
                  sideBarActive == 2
                    ? "FavoriteSearches active"
                    : "FavoriteSearches"
                }
              >
                <Link name="FavoriteSearches" to="/FavouriteSearches">
                  Favorite Searches
                </Link>
              </li>
              <li style={{ display: "flex", gap: "13px" }} className={""}>
                <span style={{ marginLeft: "2px" }}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassChart}
                    color={sideBarActive == 9 && "#1EA5D4"}
                  />
                </span>
                <Link
                  name="GiftCard"
                  style={{ color: sideBarActive === 9 ? "#1EA5D4" : "" }}
                  to="/search-by-skills"
                >
                  Skill and Certification Search
                </Link>
              </li>
              {/* <li className={sideBarActive == 3 ? 'SearchHistory active' : 'SearchHistory'}><Link to='/SearchHistory'>Search History</Link></li> */}
              {temp?.added_by?.includes("@") ? null : (
                <li
                  className={
                    sideBarActive == 5
                      ? "TransactionHistory active"
                      : "TransactionHistory"
                  }
                >
                  <Link name="TransactionHistory" to="/TransactionHistory">
                    Transaction History
                  </Link>
                </li>
              )}
              {temp?.added_by?.includes("@") ? null : (
                <li style={{ display: "flex", gap: "11px" }} className={""}>
                  <span style={{ marginLeft: "3px" }}>
                    <FontAwesomeIcon
                      icon={faGift}
                      color={sideBarActive == 8 && "#1EA5D4"}
                    />
                  </span>
                  <Link
                    name="GiftCard"
                    style={{ color: sideBarActive === 8 ? "#1EA5D4" : "" }}
                    to="/GiftCard"
                  >
                    Have a Gift Card?
                  </Link>
                </li>
              )}
              <li style={{ display: "flex", gap: "11px" }} className={""}>
                {/* <span><img style={{ height: "19px", width: '19px' }} src={info} loading='lazy' /></span> */}
                <span style={{ marginLeft: "3px" }}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color={sideBarActive == 7 && "#1EA5D4"}
                  />
                </span>
                <Link
                  name="About"
                  style={{ color: sideBarActive === 7 ? "#1EA5D4" : "" }}
                  to="/About"
                >
                  Why Use Illuminate?
                </Link>
              </li>
              <li className={sideBarActive == 4 ? "FAQ active" : "FAQ"}>
                <Link name="FAQ" to="/FAQ">
                  FAQs
                </Link>
              </li>
            </ul>
          )}
          {temp?.userType !== "Company Head" ? (
            temp?.userType === "enterprise-user" ||
            temp?.added_by?.includes("@") ? null : (
              <ul className="SubscriptionDetails_ul">
                <li
                  className={
                    sideBarActive == 6
                      ? "SubscriptionDetails active"
                      : "SubscriptionDetails"
                  }
                >
                  <Link name="SubscriptionDetails" to="/Subscription">
                    Subscription Page
                  </Link>
                </li>
              </ul>
            )
          ) : null}
          <div className="left_menu_logoutButton">
            <Link to="/">
              <Button variant="primary" type="submit" className="Logout">
                <img src={log_out_icon} className="" loading="lazy" />
                Log Out
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div
          style={{ overflow: "auto", height: "100vh", paddingTop: "0" }}
          className="left_menu_outer"
        >
          <div
            className="left_menu_logo"
            style={{
              position: "sticky",
              top: "0",
              paddingTop: "25px",
              backgroundColor: "white",
            }}
          >
            <Link to={"/DashboardOldUser"}>
              <img src={logo_inner} className="" loading="lazy" />
            </Link>
          </div>
          <div style={{ textAlign: "center", marginTop: "14px" }}>
            <Link to="/DashboardOldUser">
              <Button
                variant="primary"
                className="login"
                style={{
                  backgroundColor: "#0f4c62",
                  border: "1px solid #0f4c62",
                  width: "100%",
                  display: "flex",
                  padding: "7px 12px",
                }}
              >
                <FontAwesomeIcon icon={faHome} size="lg" />
                <span style={{ marginLeft: "11px", fontWeight: "600" }}>
                  Home
                </span>
              </Button>
            </Link>
          </div>
          <div className="left_menu_searchButton">
            <Link to={"#"}>
              <Button
                style={{
                  backgroundColor: "#0f4c62",
                  border: "1px solid #0f4c62",
                }}
                onClick={() => {
                  alert("Please create an account!");
                }}
                variant="primary"
                type="submit"
                className="login"
              >
                <img src={Search_icon} className="" loading="lazy" />
                {"ILLUMINATE NOW"}
              </Button>
            </Link>
          </div>

          <ul className="left_menu_ul">
            <li
              className={sideBarActive == 0 ? "Dashboard active" : "Dashboard"}
            >
              <Link to="#">Dashboard</Link>
            </li>
            <li
              className={
                sideBarActive == 1
                  ? "PreviousSearchResults active"
                  : "PreviousSearchResults"
              }
            >
              <Link to="#">Previous Search History</Link>
            </li>
            <li
              className={
                sideBarActive == 2
                  ? "FavoriteSearches active"
                  : "FavoriteSearches"
              }
            >
              <Link to="#">Favorite Searches</Link>
            </li>
            {/* <li className={sideBarActive == 3 ? 'SearchHistory active' : 'SearchHistory'}><Link to='/SearchHistory'>Search History</Link></li> */}

            <li
              className={
                sideBarActive == 5
                  ? "TransactionHistory active"
                  : "TransactionHistory"
              }
            >
              <Link to="#">Transaction History</Link>
            </li>
            <li style={{ display: "flex", gap: "11px" }} className={""}>
              <span>
                <img
                  style={{ height: "19px", width: "19px" }}
                  src={info}
                  loading="lazy"
                />
              </span>
              <Link
                style={{ color: sideBarActive === 7 ? "#1EA5D4" : "" }}
                to="/About"
              >
                Why Use Illuminate?
              </Link>
            </li>
            <li className={sideBarActive == 4 ? "FAQ active" : "FAQ"}>
              <Link to="/FAQ">FAQs</Link>
            </li>
          </ul>

          <ul className="SubscriptionDetails_ul">
            <li
              className={
                sideBarActive == 6
                  ? "SubscriptionDetails active"
                  : "SubscriptionDetails"
              }
            >
              <Link to="/Subscription">Subscription Page</Link>
            </li>
          </ul>

          <div className="left_menu_logoutButton">
            <Link to="/">
              <Button variant="primary" type="submit" className="Logout">
                <img src={log_out_icon} className="" loading="lazy" />
                Log Out
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftMenu;
