import React from "react";
import Layout from "../Layout/Layout";
import "../Subscription/style.css";
import faq_banner from "../../assets/images/faq_banner.png";
import { useEffect } from "react";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout sideBarActive={4}>
      <div className="faq_continer load_animation">
        <div className="faq_banner">
          <img src={faq_banner} className="" loading="lazy" alt="" />
        </div>
        <div className="faq_body">
          <div className="accordion" id="accordionExample">
            <div style={{ fontSize: "14px" }} className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Account
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {/* <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the, though the transition does limit overflow. */}
                  <strong style={{ fontWeight: "700" }}>
                    Duplicate accounts
                  </strong>
                  <br></br>
                  Merging accounts is easy. Email to{" "}
                  <a href="mailto:tech@upskilltalent.com" target={"_blank"}>
                    tech@upskilltalent.com
                  </a>{" "}
                  with the header “Duplicate Accounts.” In the email body, state
                  both accounts and which account will be the primary account.
                </div>
              </div>
            </div>
            <div style={{ fontSize: "14px" }} className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne-o-Account-Outside-of-Your-Organizations"
                  aria-expanded="true"
                  aria-controls="collapseOne-o-Account-Outside-of-Your-Organizations"
                >
                  Account Outside of Your Organizations?
                </button>
              </h2>
              <div
                id="collapseOne-o-Account-Outside-of-Your-Organizations"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  If you have an account outside of this one, click on the
                  Profile section and click on the button.
                  <br />
                  <br />
                  Log in with that account, and you can access your previous
                  searches.
                  <br />
                  <br />
                  Note that the plans are separate and will not be merged.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven166677o-Add-Users"
                  aria-expanded="false"
                  aria-controls="collapseEleven166677o-Add-Users"
                >
                  Add Users
                </button>
              </h2>
              <div
                id="collapseEleven166677o-Add-Users"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Click on the Enterprise button on the left side and then click
                  on the Manage Users. <br></br>
                  <br></br>A new screen appears where a blue bar appears.
                  <br></br>
                  <br></br> Click on the bar where there are two options: upload
                  by single user separated by a comma and upload by Excel Sheet.
                  <br></br>
                  <br></br>
                  Those that upload an Excel sheet must list all emails in
                  Column A.<br></br>
                  <br></br> Cell A1 must contain the word “Email,” Each email
                  must be under a separate cell.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven166677"
                  aria-expanded="false"
                  aria-controls="collapseEleven166677"
                >
                  Annual Plans
                </button>
              </h2>
              <div
                id="collapseEleven166677"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Annual Plans have two distinct features. The first is that
                  users will receive bonus searches. Second, the Annual Plans
                  are set up to renew automatically. This occurs during the
                  checkout process, and you won’t have to worry about anything
                  else.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Cancel Subscription
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Users who wish to cancel their subscription should email{" "}
                  <a href="mailto:tech@upskilltalent.com">
                    tech@upskilltalent.com
                  </a>{" "}
                  with their email addresses and name. Kindly help us understand
                  why you want to cancel the subscription, and the Upskill
                  Talent Team will adjust.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree-o-Change-Administrators"
                  aria-expanded="false"
                  aria-controls="collapseThree-o-Change-Administrators"
                >
                  Change Administrators
                </button>
              </h2>
              <div
                id="collapseThree-o-Change-Administrators"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  To change Enterprise Administrators, the current administrator
                  must email{" "}
                  <a href="mailto:tech@upskilltalent.com">
                    tech@upskilltalent.com
                  </a>
                  , where Upskill Talent will update the changes. When emailing,
                  include the date that the new administrator will start.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#testthree"
                  aria-expanded="false"
                  aria-controls="testthree"
                >
                  Credit Cards
                </button>
              </h2>
              <div
                id="testthree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Credit cards are only needed when a user pays in the PayPal
                  portal. It is an option should a user not have a PayPal
                  account.<br></br>
                  <br></br>
                  Card information is NOT stored at all and is secured through
                  the transaction.<br></br>
                  <br></br>
                  Users will receive an email confirmation for the transaction.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Coupons-GiftCards"
                  aria-expanded="false"
                  aria-controls="Coupons-GiftCards"
                >
                  Coupons & Gift Cards
                </button>
              </h2>
              <div
                id="Coupons-GiftCards"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● <span style={{ fontWeight: "600" }}>Coupon Codes</span> -We
                  love to offer coupons to our customers and from time to time
                  you will see them. Users who get a coupon code redeemed on a
                  Pay By Search item will have a percent of the price taken off
                  whereas if a coupon is offered on a Monthly or Annual plan,
                  then the user will receive additional searches.
                  <br></br>
                  <br></br>Coupon codes can be redeemed in the Subscription Page
                  area.<br></br>
                  <br></br>●{" "}
                  <span style={{ fontWeight: "600" }}>Gift Card Codes</span>{" "}
                  will arrive in an email. Once you have logged into your
                  account, proceed to the “Have a Gift Card” section on your
                  menu. Click on the Redeem button on the upper right-hand side
                  and enter your code. They will be added to your account
                  automatically.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Coupons-GiftCards-o-Enterprise-Menu"
                  aria-expanded="false"
                  aria-controls="Coupons-GiftCards-o-Enterprise-Menu"
                >
                  Enterprise Menu
                </button>
              </h2>
              <div
                id="Coupons-GiftCards-o-Enterprise-Menu"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  There are three options: Manage Users, Manage Notifications,
                  and Export Subordinate Lists.
                  <br />
                  <br /> Here, the Enterprise Administrator will do most of the
                  work for the organization.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#Coupons-GiftCardso-Export-Accounts"
                  aria-expanded="false"
                  aria-controls="Coupons-GiftCardso-Export-Accounts"
                >
                  Export Accounts
                </button>
              </h2>
              <div
                id="Coupons-GiftCardso-Export-Accounts"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Accounts can be exported to the Enterprise Menu. Go to the
                  Remove Subordinate List and click on the names that should be
                  removed.
                  <br />
                  <br /> Note that those users will have individual accounts
                  established at that point and will pay for services should
                  they wish.
                  <br />
                  <br /> Users will keep their prior and favorite searches.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Favorite Searches
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● In this search mode, the searches have a red heart. This
                  denotes that you like this enough to make it a favorite.
                  <br></br> ● The searches will come by date and can be
                  searched.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#four4"
                  aria-expanded="false"
                  aria-controls="four4"
                >
                  Forgotten Password
                </button>
              </h2>
              <div
                id="four4"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Click on the profile picture in the top right-hand corner to
                  take you to the profile page.
                  <br />
                  <br />
                  Next, click the "Reset Password" button, where your email will
                  appear.
                  <br />
                  <br />
                  Click on the "Send Now" button to receive an email to reset
                  your password.
                  <br />
                  <br />
                  In the email, copy the temporary password and paste it back
                  into the temporary password box. Click on Submit.
                  <br />
                  <br />
                  Here you will be able to reset your password and click on
                  submit.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree55">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree55"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Getting Started
                </button>
              </h2>
              <div
                id="collapseThree55"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● You can “Get Started” on the Dashboard menu by clicking on
                  the blue box under the rocket. It will take you to the New
                  Search, the starting point for all searches.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Login
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <span>● Forgot your login?</span>
                  <br></br>
                  Click on the LinkedIn button, and you’ll proceed to the
                  dashboard. You can also click on the “Forgot Password,” and
                  you’ll be instructed how to regain access
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive-o-Manage-Users"
                  aria-expanded="false"
                  aria-controls="collapseFive-o-Manage-Users"
                >
                  Manage Users
                </button>
              </h2>
              <div
                id="collapseFive-o-Manage-Users"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Enterprise Administrators can manually add the new
                  subordinates individually or in bulk via Excel.
                  <br />
                  <br /> Under the add section, you can see each account’s
                  information.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive-o-Manage-Users-o-Manage-Notifications"
                  aria-expanded="false"
                  aria-controls="collapseFive-o-Manage-Users-o-Manage-Notifications"
                >
                  Manage Notifications
                </button>
              </h2>
              <div
                id="collapseFive-o-Manage-Users-o-Manage-Notifications"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Select the users to whom you want to notify. <br />
                  <br />
                  A screen appears below, and the choice is to email, create an
                  announcement in Illuminate, or both.
                  <br />
                  <br />
                  Complete the message and click on send.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven166688556"
                  aria-expanded="false"
                  aria-controls="collapseEleven166688556"
                >
                  Monthly Plans
                </button>
              </h2>
              <div
                id="collapseEleven166688556"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Monthly Plans are designed for users who want to test out
                  Illuminate or want to search for a period of time.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#eleven11"
                  aria-expanded="false"
                  aria-controls="eleven11"
                >
                  Multiple Payments
                </button>
              </h2>
              <div
                id="eleven11"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Users often get a subscription plan and additional searches.
                  <br></br>
                  <br></br>
                  To do this will require you to pay for a plan if you have not
                  done so already. Once done, go to the{" "}
                  <a
                    href="https://illuminate.upskilltalent.com/Subscription"
                    target={"_blank"}
                  >
                    Subscription Page
                  </a>{" "}
                  and click on the Pay By Search tab. Select the one you’d like
                  to purchase.<br></br>
                  <br></br>
                  The process is similar if you started with a Pay By Search and
                  want to add a subscription plan.<br></br>
                  <br></br>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    New Search - “Illuminate Now”
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    ● You only need to type in the search occupation to conduct
                    a new search. It spells the occupation out for you, so you
                    need not worry about having it correctly spelled.
                    <br></br> ● After the main “search occupation,” key in the
                    other occupations you want to analyze. After that, click on
                    the submit button.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven166688556233"
                    aria-expanded="false"
                    aria-controls="collapseEleven166688556233"
                  >
                    Platinum / Premiere Subscribers
                  </button>
                </h2>
                <div
                  id="collapseEleven166688556233"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Users who have either plan can easily add users when
                    clicking the blue “Add Users” button. From there, they will
                    enter every email address and separate them with a comma.
                    <br></br>
                    <br></br>
                    Subordinates under the manager will receive instructions in
                    an email to create an account where they will have the same
                    search access as the manager.<br></br>
                    <br></br>
                    The Premiere/Platinum accounts manager can see every search
                    and their subordinate occupation favorites.<br></br>
                    <br></br>
                    {/* (Under the Platinum/Premiere Subscribers tab) – add the following to what is existing there already.<br></br><br></br> */}
                    <p>
                      Here's a{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/d/1Uvz3cclxJQUAcjaW3o-ZCZegFqx9HjEX/edit?usp=share_link&ouid=110496207302822454405&rtpof=true&sd=true"
                        target={"_blank"}
                      >
                        tool
                      </a>{" "}
                      to help you in this process.
                    </p>
                    <br></br>
                    Note that managers of these accounts can only create new
                    subordinate accounts. If a subordinate has an account
                    already created on Illuminate, a manager cannot add them as
                    their subordinate. We are aware of the issue and will make
                    that a future update.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Payment Info
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Upskill Talent uses PayPal and Stripe since they are secure
                  payment portals. Credit Cards are never housed here and all
                  purchases are done through their respective portals.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Previous Search Results
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● By typing in the occupation, you can find all searches
                  through the search bar. It works for the primary occupation
                  and if it’s an occupation being compared.
                  <br></br>● A particular search can be saved as a favorite by
                  clicking on the red heart outline.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Print
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● The orange Print button enables you to print out the
                  respective page. However, the page must be landscaped where
                  it’s easier to read the information in this format.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Profile
                </button>
              </h2>
              <div
                id="collapseNine"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● Look in the upper right corner for the profile icon next to
                  the announcements. When you click on it, the profile page
                  appears to which you can review and update your information.
                  <br></br> ● Your saved credit card information is at the
                  bottom of the profile section.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Refunds
                </button>
              </h2>
              <div
                id="collapseTen"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● Pay By Search and Subscriptions are nonrefundable. Pay By
                  Search units do not expire and can be used anytime.
                  Subscriptions will expire based on the plan.
                  <br></br> ● If you have used the auto-renewal option, you will
                  need to cancel it.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  Save as a PDF
                </button>
              </h2>
              <div
                id="collapseEleven"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● Click on the orange Print Button and then change from your
                  printer to PDF.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven12-o-Subordinate-Access"
                  aria-expanded="false"
                  aria-controls="collapseEleven12-o-Subordinate-Access"
                >
                  Subordinate Access
                </button>
              </h2>
              <div
                id="collapseEleven12-o-Subordinate-Access"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Enterprise Administrators may access subordinates in the
                  Manage Users button.
                  <br />
                  <br />
                  Look in the window for an eye where the administrator can view
                  the subordinate’s search history and a pencil where an
                  administrator can edit their account information such as email
                  addresses and first and last names.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven12"
                  aria-expanded="false"
                  aria-controls="collapseEleven12"
                >
                  Subscriptions
                </button>
              </h2>
              <div
                id="collapseEleven12"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● Change subscription plans?
                  <br />
                  Yes! On the{" "}
                  <a
                    href="https://illuminate.upskilltalent.com/Subscription"
                    target={"_blank"}
                  >
                    Subscription Plan
                  </a>{" "}
                  page, click on the plan you want to upgrade and complete the
                  process.
                  <br />
                  <br />
                  ● Change from Pay By Search to a Subscription Plan?
                  <br />
                  Should you wish to add a subscription plan, the extra searches
                  will remain in your account and will only be used when you
                  have run out of your searches in your plan.
                  <br />
                  <br />● Pay By Search users can add a monthly or annual plan
                  anytime by completing the process on the{" "}
                  <a
                    href="https://illuminate.upskilltalent.com/Subscription"
                    target={"_blank"}
                  >
                    Subscription Page
                  </a>
                  .<br />
                  <br />
                  ● Stop Subscription?
                  <br />
                  Send an email to{" "}
                  <a href="mailto:tech@upskilltalent.com">
                    tech@upskilltalent.com
                  </a>
                  . In the subject line, type Stop Subscription. Enter the email
                  account associated with Illuminate and the plan you currently
                  have enrolled in.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven12test"
                  aria-expanded="false"
                  aria-controls="collapseEleven12test"
                >
                  Subscription Plan + Pay By Search
                </button>
              </h2>
              <div
                id="collapseEleven12test"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Yes! Users can combine both. They would go to the{" "}
                  <a
                    href="https://illuminate.upskilltalent.com/Subscription"
                    target={"_blank"}
                  >
                    Subscription Page
                  </a>{" "}
                  and proceed to add on whatever you’d like.<br></br>
                  <br></br>
                  If a user is in the trial stage, they will first get the
                  subscription, then add the Pay By Search in a separate
                  transaction.<br></br>
                  <br></br>
                  When this happens, two transactions and emails will take
                  place.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven122"
                  aria-expanded="false"
                  aria-controls="collapseEleven122"
                >
                  Suggestions
                </button>
              </h2>
              <div
                id="collapseEleven122"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● We love suggestions and greatly appreciate them. Kindly
                  email us your thoughts and even a screenshot of your idea so
                  our team can look deeper into seeing if and how we can make it
                  happen. Email us at{" "}
                  <a href="mailto:tech@upskilltalent.com">
                    tech@upskilltalent.com
                  </a>{" "}
                  with the header “Suggestions.”
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven15555"
                  aria-expanded="false"
                  aria-controls="collapseEleven15555"
                >
                  Terminate Account
                </button>
              </h2>
              <div
                id="collapseEleven15555"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● You can delete your account. However, once you do it, the
                  account will lose all search favorites & history.
                  <br></br> ● No refunds will be given regardless of
                  subscription of searches remaining.
                  <br></br> ● The request should be sent to{" "}
                  <a href="mailto:tech@upskilltalent.com">
                    tech@upskilltalent.com
                  </a>
                  . Include in the header “Delete account.”
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven166666"
                  aria-expanded="false"
                  aria-controls="collapseEleven166666"
                >
                  Trial Period
                </button>
              </h2>
              <div
                id="collapseEleven166666"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  ● What happens after my 5 days free trial period expires?
                  <br />
                  Users cannot conduct any new searches but will still have
                  access to previous searches.
                  <br />
                  <br />
                  Should a user have remaining searches left over after the 5
                  days, they will not be able to access them. Users must then
                  purchase a Pay By Search option or Monthly/Annual plan.
                  <br />
                  <br />
                  Accounts will become inactive within 60 days if there is no
                  login.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven1"
                  aria-expanded="false"
                  aria-controls="collapseEleven1"
                >
                  Updating Subscription Plans
                </button>
              </h2>
              <div
                id="collapseEleven1"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  There’s nothing special you will need to do should you want to
                  upgrade your plan!<br></br>
                  <br></br>
                  Go to the{" "}
                  <a
                    href="https://illuminate.upskilltalent.com/Subscription"
                    target={"_blank"}
                  >
                    Subscription Page
                  </a>{" "}
                  and click on the Subscription Plan tab. Select the Annual or
                  Monthly Price that best fits you and complete the process.
                  Once the payment has been finalized, you will then be
                  upgraded!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
