import { useEffect, useState } from "react";
import { api } from '../../assets/API/api';
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
// import { loadStripe } from "@stripe/stripe-js";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { RotatingLines } from "react-loader-spinner";
// import { AxiosContext } from "react-axios/lib/components/AxiosProvider";
import axios from "axios";
// import { Button } from "react-bootstrap";
import stripe_logo from '../../assets/images/stripe_logo.png';


// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function Stripe({ plan, amount }) {
    // const [stripePromise, setStripePromise] = useState(process.env.REACT_APP_STRIPE_KEY);
    const [clientSecret, setClientSecret] = useState("");
    const temp = getSessionStorage();
    const [loading, setLoading] = useState(false);
    const [pay, setPay] = useState(false);

    // useEffect(() => {
    //     fetch("/config").then(async (r) => {
    //         const { publishableKey } = await r.json();
    //         setStripePromise(loadStripe(publishableKey));
    //     });
    // }, []);
    // console.log('amount', amount, plan);

    const makePayment = async () => {
        if (!temp?.user_id) { return alert("Please login!") }
        // setLoading(true)

        if (amount) {
            setLoading(true)
            axios.post(`${api}/create-payment-intent?tokenMail=${temp?.email}`,
                {
                    user_id: temp?.user_id,
                    email: temp?.email,
                    name: `${temp?.firstName} ${temp?.lastName}`,
                    amount: amount,
                    currency_code: plan?.currency_code,
                    plan: plan
                }
            ).then(async (result) => {
                var { clientSecret } = await result.data;
                setClientSecret(clientSecret);
                window.location.href = result.data;
            }).catch(e => console.log(e))
                .finally(() => { setLoading(false) });
        }
    }

    return (
        <>
            {/* <h1 style={{ textAlign: "center", margin: '15px 0px', fontWeight: '600' }}>Stripe Payment</h1> */}
            <button className={loading ? "loading_payment" : ""} disabled={loading} style={{ height: "50px", width: "300px", paddingBottom: '5px' }} variant="primary"
                onClick={makePayment}>
                {loading ? <span style={{ display: 'flex', justifyContent: 'center' }}><RotatingLines
                    strokeColor="black"
                    strokeWidth="3"
                    animationDuration="0.75"
                    width="34"
                    visible={true} />
                </span> :
                    // <span style={{ fontSize: "20px", fontWeight: "600", fontStyle: "italic" }}>Stripe</span>
                    <img style={{}} src={stripe_logo} alt="Stripe" />
                }
            </button>
            {
            // clientSecret && stripePromise && (
            //     <Elements stripe={stripePromise} options={{ clientSecret }}>
            //         {/* <CheckoutForm /> */}
            //     </Elements>
            // )
            }
        </>
    );
}





export default Stripe;