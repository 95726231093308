import React, { createContext, Fragment, useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Redirect
} from "react-router-dom";


import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './assets/css/style.css';

import Dashboard from './components/Dashboard/Dashboard';
import Contact from './components/Contact/Contact';
import About from "./components/About/About";
import SignIn from "./components/SignIn/SignIn";
import Signup from "./components/Signup/Signup";
import LeftMenu from "./components/LeftMenu/LeftMenu";
import Option from "./components/Option/Option";
import DashboardOldUser from "./components/DashboardOldUser/DashboardOldUser";
import MyProfile from "./components/MyProfile/MyProfile";
import NewSearch from "./components/NewSearch/NewSearch";
import SearchResults from "./components/SearchResults/SearchResults";
import PreviousSearchResults from "./components/PreviousSearchResults/PreviousSearchResults";
import FavouriteSearches from "./components/FavouriteSearches/FavouriteSearches";
import FAQ from "./components/FAQ/FAQ";
import SearchHistory from "./components/SearchHistory/SearchHistory";
import TransactionHistory from "./components/TransactionHistory/TransactionHistory";
// import AddUsers from "./components/AddUsers/AddUsers";
import Users from "./components/Users/Users";
import UserList from "./components/Users/UserList";
import Subscription from "./components/Subscription/Subscription";
import Tableau from "./components/Tableu/Tableu";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import PaymentGateway from "./components/Payments/PaymentGateway";
import Notifications from "./components/DashboardOldUser/Notifications";
import PaymentOptions from "./components/Subscription/payment";
import UserControl from "./components/admin/userControl";
import SubscriptionControl from "./components/admin/subscriptioncontrol";
import CouponControl from "./components/admin/couponControl";
import NotificationControl from "./components/admin/notificationControl";
// import LinkedInPage from "./components/LinkedIn/LinkedIn";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import AdminTableau from "./components/AdminTableau/AdminTableau";
// import Test from "./components/test";
import { Helmet } from "react-helmet";
import UpskillTalent from "./components/DashboardOldUser/UpskillTalent";
import StripeSuccess from "./components/stripe/success";
import FailedPayment from "./components/stripe/cancel";
import PaypalSuccess from "./components/PayPal/success";
import PaypalSubSuccess from "./components/PayPal/subSuccess";
import GiftCardControl from "./components/admin/giftcardcontrol";
import GiftCard from "./components/GiftCard/giftCard";
import getSessionStorage from "./customHooks/getSessionStorage";
import axios from "axios";
import EnterpriseControl from "./components/admin/enterpriseControl";
import EnterpriseMenu from "./components/EnterpriseMenu/enterpriseMenu";
import SearchBySkills from "./components/SearchBySkills/searchBySkills";
import OccupationResults from "./components/SearchBySkills/occupationResults";
import ExportedUsers from "./components/EnterpriseMenu/exportedUsers";




export const myContext = createContext();

function App() {
  const [userInfo, setUserInfo] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    title: 'Mr.',
    mobile: '',
    dob: '',
    companyName: '',
    yourRole: '',
    companyWebsite: '',
    businessCategories: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    userType: '',
    profileImage: '',
    oldUser: false,
    addedBy: '',
    date: '',
    highestQualification: '',
    profileImage: ''
  });

  const [searchParams, setSearchParams] = useState({
    primaryProfession: '',
    firstProfession: '',
    secondProfession: '',
    thirdProfession: '',
    fourthProfession: '',
    fifthProfession: ''
  })

  const [search_id, setSearch_id] = useState(null);
  const [authenticate, setAuthenticate] = useState(false);
  const [displayArr, setDisplayArr] = useState([]);
  const [balance, setBalance] = useState({ got: 0, used: 0 });
  const [myBalance, setMyBalance] = useState({
    subscription_balance: 0,
    addon_baance: 0
  })
  const [trial, setTrial] = useState(false);
  const [days, setDays] = useState(0);
  const [activePlans, setActivePlans] = useState([]);
  const [ipData, setIpData] = useState({ curr_code: 'USD', exchange_rate: 1, country: '' });
  const [addon, setAddon] = useState([]);
  const [sideBarActive, setSideBarActive] = useState(null);
  const [usedPoints, setUsedPoints] = useState(0);
  const [recentSearches, setRecentSearches] = useState([]);
  const [recentFavSearches, setRecentFavSearches] = useState([]);
  const [recentTransactions, setRecenttransactions] = useState([]);


  const temp = getSessionStorage();

  axios.defaults.headers["x-access-token"] = temp?.token;


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Fragment>
      <BrowserRouter basename={process.env.REACT_APP_MODE === "TEST" ? "/test" : "/"}>
        <div>

          <myContext.Provider
            value={{
              userInfo: [userInfo, setUserInfo],
              authentication: [authenticate, setAuthenticate],
              searchParams: [searchParams, setSearchParams],
              displayArr: [displayArr, setDisplayArr],
              search_id: [search_id, setSearch_id],
              balance: [balance, setBalance],
              myBalance: [myBalance, setMyBalance],
              trial: [trial, setTrial],
              days: [days, setDays],
              activePlans: [activePlans, setActivePlans],
              ipData: [ipData, setIpData],
              addon: [addon, setAddon],
              sideBar: [sideBarActive, setSideBarActive],
              usedPoints: [usedPoints, setUsedPoints],
              recentSearches: [recentSearches, setRecentSearches],
              recentFavSearches: [recentFavSearches, setRecentFavSearches],
              recentTransactions: [recentTransactions, setRecenttransactions]

            }}>

            <Helmet>
              <title>Skills On Resumes Dashboard</title>
              <meta name="description" content="The best skills to list on your resume are easy to find with a free trial of the most powerful soft and hard skill management software. Try it today!" />
              <meta name="keywords" content="Skills on Resume" />
              <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
            </Helmet>

            {/* <Route exact path="/Dashboard" component={Dashboard} /> */}
            <Route exact path="/linkedin" component={LinkedInCallback} />
            <Route exact path="/AddUsers" component={Users} />
            <Route exact path="/UserList" component={UserList} />
            <Route exact path="/Contact" component={Contact} />
            {/* <Route exact path="/about" component={About} /> */}
            <Route exact path="/" component={SignIn} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/LeftMenu" component={LeftMenu} />
            <Route exact path="/Option" component={Option} />
            <Route exact path="/DashboardOldUser" component={DashboardOldUser} />
            <Route exact path="/NewSearch" component={NewSearch} />
            <Route exact path="/SearchResults" component={SearchResults} />
            <Route exact path="/PreviousSearchResults" component={PreviousSearchResults} />
            <Route exact path="/FavouriteSearches" component={FavouriteSearches} />
            {/* <Route exact path="/SearchHistory" component={SearchHistory} /> */}
            <Route exact path="/MyProfile" component={MyProfile} />
            <Route exact path="/FAQ" component={FAQ} />
            <Route exact path="/TransactionHistory" component={TransactionHistory} />
            <Route exact path="/Subscription" component={Subscription} />
            <Route exact path="/Tableau" component={Tableau} />
            <Route exact path="/adimn-tableau" component={AdminTableau} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/PaymentGateway" component={PaymentGateway} />
            <Route exact path="/Notifications" component={Notifications} />
            <Route exact path="/PaymentOptions" component={PaymentOptions} />
            <Route exact path="/UserControl" component={UserControl} />
            <Route exact path="/SubscriptionControl" component={SubscriptionControl} />
            <Route exact path="/CouponControl" component={CouponControl} />
            <Route exact path="/GiftCardControl" component={GiftCardControl} />
            <Route exact path="/GiftCard" component={GiftCard} />
            <Route exact path="/NotificationControl" component={NotificationControl} />
            {/* <Route path="/test" component={LinkedInPage} /> */}
            <Route path="/AdminTableau" component={AdminTableau} />
            {/* <Route path="/base" component={Test} /> */}
            <Route exact path="/About" component={UpskillTalent} />
            <Route exact path="/stripe-success" component={StripeSuccess} />
            <Route exact path="/cancel" component={FailedPayment} />
            <Route exact path="/paypal-success" component={PaypalSuccess} />
            <Route exact path="/paypal-subscription-success" component={PaypalSubSuccess} />
            <Route exact path="/EnterpriseControl" component={EnterpriseControl} />
            <Route exact path="/enterprise-menu" component={EnterpriseMenu} />
            <Route exact path="/search-by-skills" component={SearchBySkills} />
            <Route exact path="/occupation-results" component={OccupationResults} />
            <Route exact path="/exported-users" component={ExportedUsers} />





          </myContext.Provider>
        </div>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
