import React from "react";
import Layout from "../Layout/Layout";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../Loader/loader";
import { Button, Modal, Table } from "react-bootstrap";
import { api } from "../../assets/API/api";
import axios from "axios";
import { useContext } from "react";
import { myContext } from "../../App";
import BackArrow from "../backArrow/backArrow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { useDebouncedCallback } from "use-debounce";
import { exportToExcel } from "../ExportTOExcel/exportToExcel";
import NoDataFound from "../NoDataFound/noDataFound";

// let arr = [];

export default function OccupationResults() {
  const temp = getSessionStorage();
  const [occupations, setOccupations] = useState([]);
  const [selectedOccupations, setSelectedOccupations] = useState([]);
  const st = useContext(myContext);
  const [searchParams, setSearchParams] = st.searchParams;
  const [search_id, setSearch_id] = st.search_id;
  const [balance, setBalance] = st.balance;
  const [userInfo, setUserInfo] = st.userInfo;
  const [displayArr, setDisplayArr] = st.displayArr;
  const [show, setShow] = useState(false);
  const [trial, setTrial] = st.trial;
  const [myBalance, setMyBalance] = st.myBalance;
  const [lowBal, setLowBal] = useState(false);
  const [noBal, setNoBal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);

  const handleClose = () => {
    setShow(false);
    setLowBal(false);
  };

  const hist = useHistory();

  useEffect(() => {
    if (!hist.location.state) {
      return hist.push("/search-by-skills");
    }
    setOccupations(
      hist.location.state?.map((e) => {
        return { ...e, selected: false, primary: false, show: true };
      }) || []
    );
    setSearchParams({
      ...searchParams,
      primaryProfession: "",
      firstProfession: "",
      secondProfession: "",
      thirdProfession: "",
      fourthProfession: "",
      fifthProfession: "",
    });
  }, []);

  useEffect(() => {
    if (occupations?.length) {
      setSelectedOccupations(
        occupations?.filter((e) => e.selected || e.primary)
      );
    }
  }, [occupations]);

  useEffect(() => {
    if (selectedOccupations?.length) {
      let nonPrimary = selectedOccupations?.filter((e) => e.selected);
      setSearchParams({
        ...searchParams,
        primaryProfession:
          selectedOccupations?.filter((e) => e.primary)[0]?.Occupation || "",
        firstProfession: nonPrimary[0]?.Occupation || "",
        secondProfession: nonPrimary[1]?.Occupation || "",
        thirdProfession: nonPrimary[2]?.Occupation || "",
        fourthProfession: nonPrimary[3]?.Occupation || "",
        fifthProfession: nonPrimary[4]?.Occupation || "",
      });
    }
  }, [selectedOccupations]);

  const handleCheck = (idx) => {
    let tempArr = [...occupations];

    let selectedOccupations = occupations?.filter((e) => e.selected);
    if (selectedOccupations?.length >= 5) {
      let target = occupations.filter(
        (e) => e.Occupation === occupations[idx]?.Occupation
      );
      if (target?.length && target[0]?.selected) {
        tempArr[idx].selected = false;
        tempArr[idx].primary = false;
        setOccupations(tempArr);
      } else {
        return;
      }
      return;
    }

    tempArr[idx].selected = !tempArr[idx]?.selected;
    tempArr[idx].primary = false;

    setOccupations(tempArr);
  };

  const handlePrimaryCheck = (idx) => {
    let tempArr = [...occupations];
    tempArr = tempArr?.map((e, i) => {
      return i === idx
        ? { ...e, selected: false, primary: false }
        : { ...e, primary: false };
    });

    tempArr[idx].primary = true;
    setOccupations(tempArr);
  };

  // search function
  async function handleSearch(e) {
    e.preventDefault();

    const arrOfProfessions = Object.values(searchParams).filter((e) => e);

    // const arrOfProfessions1 = Object.values(searchParams);

    if (
      trial &&
      parseInt(myBalance.subscription_balance) < arrOfProfessions?.length
    ) {
      // alert("You do not have sifficient balance to make the search. Kindly upgrade your plan to enjoy the service!");
      setNoBal(true);
      setShow(true);
      return;
    }

    if (
      !trial &&
      parseInt(myBalance.subscription_balance) +
        parseInt(myBalance.addon_balance) <
        arrOfProfessions.length
    ) {
      alert(
        "You do not have sufficient balance to make the search. Kindly upgrade your plan to enjoy the service!"
      );
      return;
    }

    setSearch_id(null);

    if (searchParams.primaryProfession === "") {
      alert("Primary profession can not be empty!");
      return;
    }

    if (arrOfProfessions.length < 2) {
      console.log(true);
      alert(
        "At least one profession is required to compare it with primary profession!"
      );
      return;
    }

    // for (let i = 0; i < arrOfProfessions.length - 1; i++) {
    //   for (let j = i + 1; j < arrOfProfessions.length; j++) {
    //     if (arrOfProfessions[i] == arrOfProfessions[j]) {
    //       alert("Please insert unique professions!");
    //       return;
    //     }
    //   }
    // }

    // for (let i = 0; i < arrOfProfessions1.length; i++) {
    //   if (
    //     arrOfProfessions1[i] &&
    //     !arr.filter(
    //       (el) =>
    //         el.Occupation.toLowerCase() === arrOfProfessions1[i].toLowerCase()
    //     ).length
    //   ) {
    //     console.log("special test", i);
    //     if (i == 0) {
    //       setSearchParams({ ...searchParams, primaryProfession: "" });
    //     }
    //     if (i == 1) {
    //       setSearchParams({ ...searchParams, firstProfession: "" });
    //     }
    //     if (i == 2) {
    //       setSearchParams({ ...searchParams, secondProfession: "" });
    //     }
    //     if (i == 3) {
    //       setSearchParams({ ...searchParams, thirdProfession: "" });
    //     }
    //     if (i == 4) {
    //       setSearchParams({ ...searchParams, fourthProfession: "" });
    //     }
    //     if (i == 5) {
    //       setSearchParams({ ...searchParams, fifthProfession: "" });
    //     }
    //     setShow(true);
    //     return;
    //   }
    // }

    if (!temp.user_id) {
      alert("You have been logged out! Please Login again!");
      hist.push("/DashboardOldUser");
      return;
    }

    setLoadingData(true);
    localStorage.setItem("search-params", JSON.stringify(searchParams));

    await axios
      .post(`${api}/getResults?tokenMail=${temp?.email}`, searchParams)
      .then((res) => {
        setDisplayArr(res.data);
        localStorage.setItem("main-array", JSON.stringify(res.data));
        // console.log('all data: ', res.data);

        // saving search result to the database so that we can fetch the data from it whenever search history is required

        // hist.push('/SearchResults', hist.location.pathname);
      })
      .catch((err) => console.log(err));

    // console.log("first api call")

    let fullName = userInfo.firstName || temp.firstName;
    let lName = userInfo.lastName || temp.lastName;

    fullName = fullName + " ";
    fullName = fullName + lName;

    await axios
      .post(`${api}/saveSearchHistory?tokenMail=${temp?.email}`, {
        ...searchParams,
        id: userInfo.id || temp.user_id,
        trial: trial,
        addedBy: userInfo.addedBy || temp.added_by,
        fav: 0,
        points: arrOfProfessions.length,
        suggestion: suggestion,
        name: fullName,
        senderMail: userInfo.email || temp.email,
      })
      .then((res) => {
        // console.log("response=", res.data);
        setSearch_id(res.data.insertId);
        localStorage.setItem("search-id", JSON.stringify(res.data.insertId));
      })
      .catch((err) => console.log("error= ", err));
    // console.log("second api call");

    setLoadingData(false);
    hist.push("/SearchResults", hist.location.pathname);
  }

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      handleFilter(value);
    },
    // delay in ms
    400
  );

  const handleFilter = (value) => {
    // e.preventDefault();
    // const { value } = e.target;

    if (!value) {
      setOccupations(
        occupations?.map((e) => {
          return { ...e, show: true };
        })
      );
    } else {
      let tempArr = occupations?.map((e) => {
        return e.Occupation?.toLowerCase()?.includes(value?.toLowerCase())
          ? { ...e, show: true }
          : { ...e, show: false };
      });

      setOccupations(tempArr);
    }
  };

  // console.log(searchParams);

  return (
    <Layout>
      <div className="occupations-container">
        <div className="occupation-list-header">
          <BackArrow back={"/search-by-skills"} />
          <div
            className=""
            style={{
              textAlign: "center",
              background: "rgb(15, 76, 98)",
              color: "white",
              width: "fit-content",
              borderRadius: "5px",
              padding: "3px 8px",
              margin: "0 auto",
              fontWeight: "500",
              height: "31px",
            }}
          >
            Number of selected occupations: {selectedOccupations?.length}
          </div>
          <Button
            disabled={!occupations?.length}
            onClick={() => {
              exportToExcel({
                key: "export-occupations-list",
                data: occupations,
              });
            }}
            style={{
              backgroundColor: "green",
              border: 0,
              padding: "4px 5px",
            }}
          >
            <FontAwesomeIcon icon={faTable} style={{ marginRight: "8px" }} />
            <span
              style={{
                backgroundColor: "green",
                fontWeight: 600,
              }}
            >
              Export to Excel
            </span>
          </Button>
        </div>
        <div className="occupations-head2">
          <span>
            1st column should be primary, followed by secondary (5) choices.
          </span>
          <span>
            No of occupations:{" "}
            <span style={{ fontWeight: "500" }}>
              {occupations?.filter((e) => e.show)?.length}
            </span>
          </span>
          <input
            type="text"
            placeholder="search occupation"
            onChange={(e) => debounced(e.target.value)}
          />
        </div>
        {/* <div className="occupations-list">
          <div
            className="occupations-list-table-head"
            style={{
              display: "flex",
              borderBottom: "1px solid #444",
              fontSize: "1rem",
              fontWeight: "600",
              position: "sticky",
              top: "0",
              background: "rgb(15, 76, 98)",
              color: "white",
            }}
          >
            <span>
              Check<br></br>(1)
            </span>
            <span>
              Check<br></br>(5)
            </span>
            <span style={{ background: "rgb(15, 76, 98)" }}>Occupations</span>
          </div>
          {occupations?.length > 0 ? (
            occupations?.map((el, idx) => {
              return (
                <div className="each-occupation">
                  <span className="first_box_occupation">
                    <input
                      checked={el?.primary}
                      onChange={() => {
                        handlePrimaryCheck(idx);
                      }}
                      type="checkbox"
                    />
                  </span>
                  <input
                    className="second_box_occupation"
                    type="checkbox"
                    checked={el?.selected}
                    onChange={() => {
                      handleCheck(idx);
                    }}
                  />
                  <span>{el?.Occupation}</span>
                </div>
              );
            })
          ) : (
            <NoDataFound text={"No Data Found"} />
          )}
        </div> */}

        <div className="occupations-table">
          <Table>
            <thead>
              <th>
                Check<br></br>(1)
              </th>
              <th>
                Check<br></br>(5)
              </th>
              <th>Occupations</th>
            </thead>
            <tbody>
              {occupations?.length > 0
                ? occupations?.map((el, idx) => {
                    return (
                      el?.show && (
                        <tr className="load_animation">
                          <td>
                            <input
                              checked={el?.primary}
                              onChange={() => {
                                handlePrimaryCheck(idx);
                              }}
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <input
                              className="second_box_occupation"
                              type="checkbox"
                              checked={el?.selected}
                              onChange={() => {
                                handleCheck(idx);
                              }}
                            />
                          </td>
                          <td>{el?.Occupation}</td>
                        </tr>
                      )
                    );
                  })
                : null}
            </tbody>
          </Table>
          {occupations?.filter((e) => e.show)?.length ? null : <NoDataFound />}
        </div>

        {occupations?.filter((e) => e.selected || e.primary)?.length ? (
          <div className="selected-profession">
            Primary Profession:{" "}
            <span>{occupations?.filter((e) => e.primary)[0]?.Occupation}</span>
            <br></br>
            Secondary Profession:{" "}
            {occupations
              ?.filter((e) => e.selected)
              ?.map((o, idx) => {
                return <span>{o.Occupation}</span>;
              })}
            {/* <span>{occupations?.filter((e) => e.selected)[0]?.Occupation}</span> */}
          </div>
        ) : null}
        <div className="skill_button_box">
          <Button disabled={loadingData} onClick={handleSearch}>
            {loadingData ? (
              <>
                <span
                  style={{
                    background: "transparent",
                    borderRadius: "3px",
                    marginRight: "5px",
                  }}
                >
                  <Loader width={25} color={"white"} />
                </span>
                <span>Please wait...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>

      <Modal
        centered
        show={show}
        onHide={handleClose}
        size="md"
        className="coupon_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lowBal ? (
            <div>
              <div style={{ padding: "0 25px" }}>
                Your Balance is{" "}
                <span style={{ color: "red", fontWeight: "600" }}>low!</span>
                <br></br>
                Please upgrade your plan or purchase additional searches for
                uninterrupted services.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "30px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  onClick={() => {
                    hist.push("/Subscription");
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          ) : noBal ? (
            <div
              style={{
                display: "grid",
                gap: "15px",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                You do not have sufficient balance to make the search. <br />
                Kindly upgrade your plan to enjoy the service!
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ width: "30%" }}
                  onClick={() => {
                    handleClose();
                    setNoBal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div style={{ fontSize: "15px" }}>
                Please select professions from the list only!<br></br>
                <br></br>
                You might have misspelled the occupation name or it’s not in our
                database.<br></br>
                <br></br>
                You can also send us your occupation suggestion in the box so we
                can add it to our occupations.<br></br>
                <br></br>
                Thanks so much!
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "5px auto",
                }}
              >
                <Button
                  onClick={() => {
                    handleClose();
                    setShowSuggestion(true);
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
