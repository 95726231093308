import { React, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../Layout/Layout";
import { Button } from "react-bootstrap";
// import Table from 'react-bootstrap/Table'
import Modal from "react-bootstrap/Modal";
import ReactToPrint from "react-to-print";

// import back_icon from "../../assets/images/back_icon.png";
// import search_icon_blue from "../../assets/images/search_icon_blue.png"
import VisitStats_icon from "../../assets/images/VisitStats_icon.png";
// import edit_icon from "../../assets/images/edit_icon.png"
// import favorite_icon from "../../assets/images/favorite_icon.png"
// import printer_icon from "../../assets/images/printer_icon.png";
// import logo_inner from "../../assets/images/logo_inner.png";
// import pdf_icon from "../../assets/images/pdf_icon.png"
// import print_icon from "../../assets/images/print_icon.png"
// import image_01 from "../../assets/images/image_01.png"
// import image_02 from "../../assets/images/image_02.png"
// import image_03 from "../../assets/images/image_03.png"
// import image_04 from "../../assets/images/image_04.png"
// import image_05 from "../../assets/images/image_05.png"
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import getSessionStorage from "../../customHooks/getSessionStorage";
import "../Subscription/style.css";
// import { RotatingLines } from 'react-loader-spinner'
import Loader from "../Loader/loader";
// import Watermark from "react-awesome-watermark";
import SaveAsPDF from "../PDF Generatror/pdfGenerator";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faTable } from "@fortawesome/free-solid-svg-icons";
import TodayDate from "../Date/date";
import BackArrow from "../backArrow/backArrow";
import { exportToExcel } from "../ExportTOExcel/exportToExcel";

let moreStyle = {
  color: "blue",
  cursor: "pointer",
  textAlign: "right",
  fontSize: "10px",
};

let myStyle = {
  maxWidth: "250px",
  minWidth: "250px",
};

// let h2Style = { bottom: "0px", textAlign: "right", cursor: "pointer", marginBottom: "0px" };

//  search result component
const SearchResults = () => {
  const st = useContext(myContext);
  const [show, setShow] = useState(false);
  const [displayArr, setDisplayArr] = st.displayArr;
  const [searchParams, setSearchParams] = st.searchParams;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [search_id, setSearch_id] = st.search_id;
  const [prof0, setProf0] = useState([]);
  const [prof1, setProf1] = useState([]);
  const [prof2, setProf2] = useState([]);
  const [prof3, setProf3] = useState([]);
  const [prof4, setProf4] = useState([]);
  const [prof5, setProf5] = useState([]);
  const [fav, setFav] = useState(false);
  const [expand, setExpand] = useState({});
  const [activePlans, setActivePlans] = st.activePlans;
  // const [clipboard, setClipboard] = useState();
  const temp = getSessionStorage();
  const [loading, setLoading] = useState(false);
  const [profArr, setProfArr] = useState([]);
  let hist = useHistory();

  useEffect(() => {
    let {
      primaryProfession,
      firstProfession,
      secondProfession,
      thirdProfession,
      fourthProfession,
      fifthProfession,
    } = JSON.parse(localStorage.getItem("search-params"));
    if (
      !primaryProfession &&
      !firstProfession &&
      !secondProfession &&
      !thirdProfession &&
      !fourthProfession &&
      !fifthProfession
    ) {
      return hist.push("/DashboardOldUser");
    }
  }, []);

  const funcc = async () => {
    await setSearch_id(JSON.parse(localStorage.getItem("search-id")));

    await setDisplayArr(JSON.parse(localStorage.getItem("main-array")));

    await setSearchParams(JSON.parse(localStorage.getItem("search-params")));
  };

  let componentRef = useRef();
  let pdfRef = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setProf0(displayArr.filter((e) => e.Occupation === profArr[0]));
    setProf1(displayArr.filter((e) => e.Occupation === profArr[1]));
    setProf2(displayArr.filter((e) => e.Occupation === profArr[2]));
    setProf3(displayArr.filter((e) => e.Occupation === profArr[3]));
    setProf4(displayArr.filter((e) => e.Occupation === profArr[4]));
    setProf5(displayArr.filter((e) => e.Occupation === profArr[5]));
  }, [profArr, displayArr]);

  useEffect(async () => {
    funcc();

    await setSearchParams(JSON.parse(localStorage.getItem("search-params")));

    // var profs = JSON.parse(localStorage.getItem("search-params"));

    // filtering out array from main array response with respect to corresponding profession
    if (!temp?.user_id) {
      return;
    }

    setExpand(false);

    if (search_id || JSON.parse(localStorage.getItem("search-id"))) {
      axios
        .post(`${api}/getSearchHistory?tokenMail=${temp?.email}`, {
          searchId:
            search_id || JSON.parse(localStorage.getItem("search-id")),
        })
        .then((res) => {
          // console.log(res.data[0].favourite);
          if (activePlans[0]?.no_of_searches > 1) {
            setFav(res.data[0]?.manager_fav);
          } else {
            setFav(res.data[0]?.favourite);
          }
        })
        .catch((err) => console.error(err));
    }

    // console.log("search params= ", displayArr);
  }, []);

  function handleFav(search_id) {
    if (!temp?.user_id) {
      return;
    }

    setFav((p) => !p);
    localStorage.setItem("search-id", JSON.stringify(search_id));

    if (activePlans[0]?.no_of_users > 1) {
      axios
        .post(`${api}/markManagerFav?tokenMail=${temp?.email}`, {
          searchId: search_id,
        })
        .then((res) => {
          //   console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${api}/markFavourite?tokenMail=${temp?.email}`, {
          searchId: search_id,
        })
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }

  const salary = (x) => {
    // console.log("salary test", x);
    if (x === "NA" || !x) {
      return "NA";
    }
    let sal = x.toString();
    let newSal = "";
    let c = 0;
    for (let i = sal.length - 1; i >= 0; i--) {
      c++;
      if (c % 3 === 0 && i !== 0) {
        newSal += sal[i];
        newSal += ",";
      } else newSal += sal[i];
    }
    let tempSal = newSal;
    newSal = "";
    for (let i = tempSal.length - 1; i >= 0; i--) {
      newSal += tempSal[i];
    }

    return "$" + newSal;
  };

  // const copyTable = () => {
  //     let text = document.getElementById('resultTable').textContent;
  //     // console.log("text", text)
  //     setClipboard(text);
  // }

  const handleExpand = (e) => {
    e.preventDefault();

    if (e.target.className === "descriptions") {
      setExpand({ ...expand, Descriptions: !expand.Descriptions });
    }
    if (e.target.className === "alternate_titles") {
      setExpand({ ...expand, Alternate_Titles: !expand.Alternate_Titles });
    }
    if (e.target.className === "knowledge") {
      setExpand({ ...expand, Knowledge: !expand.Knowledge });
    }
    if (e.target.className === "education") {
      setExpand({ ...expand, Education: !expand.Education });
    }
    if (e.target.className === "Skills_Applied") {
      setExpand({ ...expand, Skills_Applied: !expand.Skills_Applied });
    }
    if (e.target.className === "Soft_Skills") {
      setExpand({ ...expand, Soft_Skills: !expand.Soft_Skills });
    }
    if (e.target.className === "Hard_Skills") {
      setExpand({ ...expand, Hard_Skills: !expand.Hard_Skills });
    }
    if (e.target.className === "Technology_Tools") {
      setExpand({ ...expand, Technology_Tools: !expand.Technology_Tools });
    }
  };

  useEffect(() => {
    setProfArr(Object.values(searchParams).filter((el) => el));
  }, [searchParams]);

  // console.log(displayArr);
  // console.log('prof0 : ', prof0.filter(e => e.Soft_Skills));

  // console.log('prof  : ', prof0[0], prof1[0], prof3[0]);
  // console.log("super array : ", displayArr);

  return (
    <Layout>
      <div className="seacrchResult_container">
        <div className="seacrchResult_containerTop_container">
          {/* <div className="left">
            <Link to={`${hist.location.state || "/DashboardOldUser"}`}>
              <img
                // onClick={() =>
                //   hist.push(`${hist.location.state || "/DashboardOldUser"}`)
                // }
                src={back_icon}
                loading="lazy"
                alt=""
              />
            </Link>
          </div> */}
          <BackArrow />
          <div style={{ width: "100%" }} className="right">
            {/* <div className="welcomeTextTop">
              {currDate}
              <sup>
                {currDate > 10 && currDate < 20
                  ? "th"
                  : currDate % 10 == 1
                  ? "st"
                  : currDate % 10 == 2
                  ? "nd"
                  : currDate % 10 == 3
                  ? "rd"
                  : "th"}
              </sup>{" "}
              {month}, {day}, {date.slice(10, 15)}
            </div> */}
            <TodayDate />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="searchResult_topText">
                Detailed Analysis Comparing (Searched Occupation)
              </div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Link to={"/NewSearch"}>
                  <Button className="button_shadow" datatype="new-search">
                    New Search?
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            textAlign: "right",
            color: "green",
            fontSize: "10px",
            fontStyle: "italic",
            wordSpacing: "3px",
            fontWeight: "600",
          }}
        >
          PDF file or Excel file is saved in Downloads folder.
        </div>

        <div className="result_table_top_bar">
          <div className="left">
            <div className="visitStats_link">
              {/* <a href='https://prod-useast-a.online.tableau.com/t/upskilltalent/views/WRSFINAL/AlternateTitleDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link' target={"_blank"}><img src={VisitStats_icon} loading='lazy' alt='' />Visit Stats</a> */}
              <Link to={"/Tableau"}>
                <img src={VisitStats_icon} loading="lazy" alt="" />
                Show Graph
              </Link>
            </div>

            <div style={{ marginLeft: "2rem" }} className="right plans2">
              <label className="plan2 basic-plan2">
                <input type="checkbox" checked={fav} name="plan2" id="letter" />
                <div
                  onClick={() => handleFav(search_id)}
                  className="plan-content2"
                >
                  &nbsp;
                </div>
              </label>
              <span
                style={{
                  color: "#EB4C4B",
                  fontWeight: "bold",
                  marginLeft: "1rem",
                }}
              >
                Mark as Favorite
              </span>
            </div>
          </div>
          <div
            className="search-results-save-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              flexWrap: "wrap",
            }}
          >
            <SaveAsPDF table_id={"pdf-table"} len={profArr?.length} />
            <Button
              style={{
                backgroundColor: "green",
                border: 0,
                padding: "8px",
                fontWeight: "600",
              }}
              onClick={() => {
                exportToExcel({
                  key: "export-detailed-analysis",
                  data: displayArr,
                });
              }}
            >
              <FontAwesomeIcon icon={faTable} style={{ marginRight: "8px" }} />
              <span>Export to Excel</span>
            </Button>
            <div
              onClick={() => {
                // alert('Please print or save the pdf in landscape mode as the content may not fit in portrait mode!');
                setShow(true);
              }}
              // onMouseEnter={() => { setExpand({ Descriptions: true, Alternate_Titles: true, Education: true, Knowledge: true, Soft_Skills: true, Hard_Skills: true, Skills_Applied: true, Technology_Tools: true }) }}
            >
              <Button
                className="print_button_orange"
                style={{ fontWeight: "600", padding: "8px" }}
                onClick={handleShow}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{ marginRight: "8px" }}
                />{" "}
                Print
              </Button>
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="table-scroll_padding load_animation">
            <div className="table-scroll_round">
              {/* <div role="region" aria-label="data table" tabindex="0" class="primary">
                            <table id='resultTable' ref={componentRef} className='top_left_fixed_table'> */}
              <div id="table-scroll" className="table-scroll">
                <table id="main-table" className="main-table">
                  <thead>
                    <tr>
                      <th className="pin blue_td">Occupation</th>
                      {profArr.map((el, idx) => {
                        return (
                          <th style={myStyle}>
                            {el}
                            <br></br>
                            <span
                              style={{
                                color: "#516731",
                                fontWeight: "600",
                                fontSize: "13px",
                              }}
                            >
                              {idx === 0 ? "( Primary Profession )" : null}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody onClick={handleExpand}>
                    <tr>
                      <th className="blue_td">Description</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {displayArr?.filter(
                              (e) => e.Occupation === profession
                            )?.length ? (
                              displayArr
                                ?.filter((e) => e.Occupation === profession)
                                ?.map((e) => {
                                  return e?.Descriptions ? (
                                    <>
                                      <div className="descriptions">
                                        {!expand.Descriptions
                                          ? e.Descriptions.slice(0, 150)
                                          : e.Descriptions}
                                      </div>
                                      {!expand.Descriptions ? (
                                        <div
                                          className="descriptions"
                                          style={moreStyle}
                                        >
                                          Show more
                                        </div>
                                      ) : (
                                        <div
                                          className="descriptions"
                                          style={moreStyle}
                                        >
                                          Show less
                                        </div>
                                      )}
                                    </>
                                  ) : null;
                                })
                            ) : (
                              <h5>No Data Available</h5>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th className="blue_td">Alternative Titles</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Alternate_Titles).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Alternate_Titles)
                                  .map((e, i) => {
                                    return (
                                      !expand.Alternate_Titles
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Alternate_Titles}</li>
                                    ) : null;
                                  })}
                                {!expand.Alternate_Titles &&
                                displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Alternate_Titles)?.length >
                                  5 ? (
                                  <div
                                    className="alternate_titles"
                                    style={moreStyle}
                                  >
                                    Show more
                                  </div>
                                ) : (
                                  displayArr
                                    ?.filter((e) => e.Occupation === profession)
                                    .filter((e) => e.Alternate_Titles)?.length >
                                    5 && (
                                    <div
                                      className="alternate_titles"
                                      style={moreStyle}
                                    >
                                      Show less
                                    </div>
                                  )
                                )}
                              </ul>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th className="blue_td">Education</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Education).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Education)
                                  .map((e, i) => {
                                    return (
                                      !expand.Education
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Education}</li>
                                    ) : null;
                                  })}
                                {!expand.Education &&
                                displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Education)?.length > 5 ? (
                                  <div className="education" style={moreStyle}>
                                    Show more
                                  </div>
                                ) : (
                                  displayArr
                                    ?.filter((e) => e.Occupation === profession)
                                    .filter((e) => e.Education)?.length > 5 && (
                                    <div
                                      className="education"
                                      style={moreStyle}
                                    >
                                      Show less
                                    </div>
                                  )
                                )}
                              </ul>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th className="blue_td">Knowledge</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Knowledge).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Knowledge)
                                  .map((e, i) => {
                                    return (
                                      !expand.Knowledge
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Knowledge}</li>
                                    ) : null;
                                  })}
                              </ul>
                            )}
                            {!expand.Knowledge &&
                            displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((e) => e.Knowledge)?.length > 5 ? (
                              <div className="knowledge" style={moreStyle}>
                                Show more
                              </div>
                            ) : (
                              displayArr
                                ?.filter((e) => e.Occupation === profession)
                                .filter((e) => e.Knowledge)?.length > 5 && (
                                <div className="knowledge" style={moreStyle}>
                                  Show less
                                </div>
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th className="blue_td">Skills Applied</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Skills_Applied).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Skills_Applied)
                                  .map((e, i) => {
                                    return (
                                      !expand.Skills_Applied
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Skills_Applied}</li>
                                    ) : null;
                                  })}
                              </ul>
                            )}
                            {!expand.Skills_Applied &&
                            displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((e) => e.Skills_Applied)?.length > 5 ? (
                              <div className="Skills_Applied" style={moreStyle}>
                                Show more
                              </div>
                            ) : (
                              displayArr
                                ?.filter((e) => e.Occupation === profession)
                                .filter((e) => e.Skills_Applied)?.length >
                                5 && (
                                <div
                                  className="Skills_Applied"
                                  style={moreStyle}
                                >
                                  Show less
                                </div>
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="blue_td">Soft Skills</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Soft_Skills).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Soft_Skills)
                                  .map((e, i) => {
                                    return (
                                      !expand.Soft_Skills
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Soft_Skills}</li>
                                    ) : null;
                                  })}
                              </ul>
                            )}
                            {!expand.Soft_Skills &&
                            displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((e) => e.Soft_Skills)?.length > 5 ? (
                              <div className="Soft_Skills" style={moreStyle}>
                                Show more
                              </div>
                            ) : (
                              displayArr
                                ?.filter((e) => e.Occupation === profession)
                                .filter((e) => e.Soft_Skills)?.length > 5 && (
                                <div className="Soft_Skills" style={moreStyle}>
                                  Show less
                                </div>
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="blue_td">Hard Skills</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Hard_Skills).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Hard_Skills)
                                  .map((e, i) => {
                                    return (
                                      !expand.Hard_Skills
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Hard_Skills}</li>
                                    ) : null;
                                  })}
                              </ul>
                            )}
                            {!expand.Hard_Skills &&
                            displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((e) => e.Hard_Skills)?.length > 5 ? (
                              <div className="Hard_Skills" style={moreStyle}>
                                Show more
                              </div>
                            ) : (
                              displayArr
                                ?.filter((e) => e.Occupation === profession)
                                .filter((e) => e.Hard_Skills)?.length > 5 && (
                                <div className="Hard_Skills" style={moreStyle}>
                                  Show less
                                </div>
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="blue_td">Technology Tools</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {!displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Technology_Tools).length ? (
                              <h3>No Data Available</h3>
                            ) : (
                              <ul>
                                {displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .filter((e) => e.Technology_Tools)
                                  .map((e, i) => {
                                    return (
                                      !expand.Technology_Tools
                                        ? i >= 0 && i < 5
                                        : i >= 0
                                    ) ? (
                                      <li>{e.Technology_Tools}</li>
                                    ) : null;
                                  })}
                              </ul>
                            )}
                            {!expand.Technology_Tools &&
                            displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((e) => e.Technology_Tools)?.length > 5 ? (
                              <div
                                className="Technology_Tools"
                                style={moreStyle}
                              >
                                Show more
                              </div>
                            ) : (
                              displayArr
                                ?.filter((e) => e.Occupation === profession)
                                .filter((e) => e.Technology_Tools)?.length >
                                5 && (
                                <div
                                  className="Technology_Tools"
                                  style={moreStyle}
                                >
                                  Show less
                                </div>
                              )
                            )}
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="blue_td">Min Salary ($USD)</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Salary_Min)?.length
                              ? displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .map(
                                    (e) =>
                                      e.Salary_Min && (
                                        <h5>{salary(e.Salary_Min)}</h5>
                                      )
                                  )
                              : "No Data Available"}
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="blue_td">Max Salary ($USD)</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Salary_Max)?.length
                              ? displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .map(
                                    (e) =>
                                      e.Salary_Max && (
                                        <h5>{salary(e.Salary_Max)}</h5>
                                      )
                                  )
                              : "No Data Available"}
                          </td>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="blue_td">Average Salary ($USD)</th>
                      {profArr.map((profession, idx) => {
                        return (
                          <td className="">
                            {displayArr
                              ?.filter((e) => e.Occupation === profession)
                              .filter((el) => el.Average_sal)?.length
                              ? displayArr
                                  ?.filter((e) => e.Occupation === profession)
                                  .map(
                                    (e) =>
                                      e.Average_sal && (
                                        <h5>{salary(e.Average_sal)}</h5>
                                      )
                                  )
                              : "No Data Available"}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <div className="result_table_top_bar">
          <div className="left">
            <div className="visitStats_link">
              <Link to={"/Tableau"}>
                <img src={VisitStats_icon} loading="lazy" alt="" />
                Show Graph
              </Link>
            </div>

            <div style={{ marginLeft: "2rem" }} className="right plans2">
              <label className="plan2 basic-plan2">
                <input type="checkbox" checked={fav} name="plan2" id="letter" />
                <div
                  onClick={() => handleFav(search_id)}
                  className="plan-content2"
                >
                  &nbsp;
                </div>
              </label>
              <span
                style={{
                  color: "#EB4C4B",
                  fontWeight: "bold",
                  marginLeft: "1rem",
                }}
              >
                Mark as Favorite
              </span>
            </div>
          </div>
          <div
            className="search-results-save-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              flexWrap: "wrap",
            }}
          >
            <SaveAsPDF table_id={"pdf-table"} len={profArr?.length} />
            <Button
              style={{
                backgroundColor: "green",
                border: 0,
                padding: "8px",
                fontWeight: "600",
              }}
              onClick={() => {
                exportToExcel({
                  key: "export-detailed-analysis",
                  data: displayArr,
                });
              }}
            >
              <FontAwesomeIcon icon={faTable} style={{ marginRight: "8px" }} />
              <span>Export to Excel</span>
            </Button>
            <div
              onClick={() => {
                // alert('Please print or save the pdf in landscape mode as the content may not fit in portrait mode!');
                setShow(true);
              }}
              // onMouseEnter={() => { setExpand({ Descriptions: true, Alternate_Titles: true, Education: true, Knowledge: true, Soft_Skills: true, Hard_Skills: true, Skills_Applied: true, Technology_Tools: true }) }}
            >
              <Button
                className="print_button_orange"
                style={{ fontWeight: "600", padding: "8px" }}
                onClick={handleShow}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{ marginRight: "8px" }}
                />{" "}
                Print
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* pdf table */}
      <div style={{ display: "none" }} className="table-scroll_padding">
        <div className="table-scroll_round" ref={pdfRef}>
          {/* <div><img src={logo_inner} loading='lazy' alt='' /></div> */}
          <div id="table-scroll" className="table-scroll">
            <table ref={componentRef} id="pdf-table" className="main-table">
              <thead>
                <tr>
                  <th className="pin blue_td">Occupation</th>
                  {profArr.map((el, idx) => {
                    return idx === 0 ? (
                      <th style={myStyle}>
                        {el}
                        <br></br>
                        <span
                          style={{
                            color: "#516731",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          ( Primary Profession )
                        </span>
                      </th>
                    ) : (
                      <th style={myStyle}>{el}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="blue_td">Description</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).map((e) => e.Descriptions)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th className="blue_td">Alternative Titles</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Alternate_Titles).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Alternate_Titles)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Alternate_Titles}
                                  </li>
                                  <br />
                                </>
                              ))}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th className="blue_td">Education</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Education).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Education)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Education}
                                  </li>
                                  <br />
                                </>
                              ))}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th className="blue_td">Knowledge</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Knowledge).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul style={{ listStyleType: "square" }}>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Knowledge)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Knowledge}
                                  </li>
                                  <br />
                                </>
                              ))}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th className="blue_td">Skills Applied</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Skills_Applied).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Skills_Applied)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Skills_Applied}
                                  </li>
                                  <br />
                                </>
                              ))}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th className="blue_td">Soft Skills</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Soft_Skills).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Soft_Skills)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Soft_Skills}
                                  </li>
                                  <br />
                                </>
                              ))}{" "}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th className="blue_td">Hard Skills</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Hard_Skills).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Hard_Skills)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Hard_Skills}
                                  </li>
                                  <br />
                                </>
                              ))}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th className="blue_td">Technology Tools</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {!(
                          idx == 0
                            ? prof0
                            : idx == 1
                            ? prof1
                            : idx == 2
                            ? prof2
                            : idx == 3
                            ? prof3
                            : idx == 4
                            ? prof4
                            : prof5
                        ).filter((el) => el.Technology_Tools).length ? (
                          <h3>No Data Available</h3>
                        ) : (
                          <ul>
                            {(idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            )
                              .filter((e) => e.Technology_Tools)
                              .map((e, i) => (
                                <>
                                  <li>
                                    {i + 1}
                                    {". "}
                                    {e.Technology_Tools}
                                  </li>
                                  <br />
                                </>
                              ))}
                          </ul>
                        )}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th className="blue_td">Min Salary ($USD)</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).filter((el) => el.Salary_Min)?.length
                          ? (idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            ).map(
                              (e) =>
                                e.Salary_Min && <h5>{salary(e.Salary_Min)}</h5>
                            )
                          : "No Data Available"}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th className="blue_td">Max Salary ($USD)</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).filter((el) => el.Salary_Max)?.length
                          ? (idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            ).map(
                              (e) =>
                                e.Salary_Max && <h5>{salary(e.Salary_Max)}</h5>
                            )
                          : "No Data Available"}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th className="blue_td">Average Salary ($USD)</th>
                  {profArr.map((profession, idx) => {
                    return (
                      <td className="">
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).filter((el) => el.Average_sal)?.length
                          ? (idx == 0
                              ? prof0
                              : idx == 1
                              ? prof1
                              : idx == 2
                              ? prof2
                              : idx == 3
                              ? prof3
                              : idx == 4
                              ? prof4
                              : prof5
                            ).map(
                              (e) =>
                                e.Average_sal && (
                                  <h5>{salary(e.Average_sal)}</h5>
                                )
                            )
                          : "No Data Available"}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* excel table */}
      {/* <div style={{ display: "none" }}>
        <table id="main-table-excel" className="main-table watermark_table">
          <thead>
            <tr>
              <th class="pin blue_td">Occupation</th>
              {profArr.map((el, idx) => {
                return idx === 0 ? (
                  <th style={myStyle}>
                    {el}
                    <br></br>
                    <span
                      style={{
                        color: "#516731",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}
                    >
                      ( Primary Profession )
                    </span>
                  </th>
                ) : (
                  <th style={myStyle}>{el}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="blue_td">Description</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {(idx == 0
                      ? prof0
                      : idx == 1
                      ? prof1
                      : idx == 2
                      ? prof2
                      : idx == 3
                      ? prof3
                      : idx == 4
                      ? prof4
                      : prof5
                    ).map((e) => e.Descriptions)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th className="blue_td">Alternative Titles</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Alternate_Titles).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Alternate_Titles)
                          .map((e, i) => (
                            <li>{e.Alternate_Titles}</li>
                          ))}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th className="blue_td">Education</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Education).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Education)
                          .map((e, i) => (
                            <li>{e.Education}</li>
                          ))}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th className="blue_td">Knowledge</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Knowledge).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Knowledge)
                          .map((e, i) => (
                            <li>{e.Knowledge}</li>
                          ))}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th className="blue_td">Skills Applied</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Skills_Applied).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Skills_Applied)
                          .map((e, i) => (
                            <li>{e.Skills_Applied}</li>
                          ))}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>

            <tr>
              <th className="blue_td">Soft Skills</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Soft_Skills).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Soft_Skills)
                          .map((e, i) => (
                            <li>{e.Soft_Skills}</li>
                          ))}{" "}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>

            <tr>
              <th className="blue_td">Hard Skills</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Hard_Skills).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Hard_Skills)
                          .map((e, i) => (
                            <li>{e.Hard_Skills}</li>
                          ))}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>

            <tr>
              <th className="blue_td">Technology Tools</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {!(
                      idx == 0
                        ? prof0
                        : idx == 1
                        ? prof1
                        : idx == 2
                        ? prof2
                        : idx == 3
                        ? prof3
                        : idx == 4
                        ? prof4
                        : prof5
                    ).filter((el) => el.Technology_Tools).length ? (
                      <h3>No Data Available</h3>
                    ) : (
                      <ul>
                        {(idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        )
                          .filter((e) => e.Technology_Tools)
                          .map((e, i) => (
                            <li>{e.Technology_Tools}</li>
                          ))}
                      </ul>
                    )}
                  </td>
                );
              })}
            </tr>

            <tr>
              <th className="blue_td">Min Salary ($USD)</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {(idx == 0
                      ? prof0
                      : idx == 1
                      ? prof1
                      : idx == 2
                      ? prof2
                      : idx == 3
                      ? prof3
                      : idx == 4
                      ? prof4
                      : prof5
                    ).filter((el) => el.Salary_Min)?.length
                      ? (idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).map(
                          (e) => e.Salary_Min && <h5>{salary(e.Salary_Min)}</h5>
                        )
                      : "No Data Available"}
                  </td>
                );
              })}
            </tr>

            <tr>
              <th className="blue_td">Max Salary ($USD)</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {(idx == 0
                      ? prof0
                      : idx == 1
                      ? prof1
                      : idx == 2
                      ? prof2
                      : idx == 3
                      ? prof3
                      : idx == 4
                      ? prof4
                      : prof5
                    ).filter((el) => el.Salary_Max)?.length
                      ? (idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).map(
                          (e) => e.Salary_Max && <h5>{salary(e.Salary_Max)}</h5>
                        )
                      : "No Data Available"}
                  </td>
                );
              })}
            </tr>

            <tr>
              <th className="blue_td">Average Salary ($USD)</th>
              {profArr.map((profession, idx) => {
                return (
                  <td className="">
                    {(idx == 0
                      ? prof0
                      : idx == 1
                      ? prof1
                      : idx == 2
                      ? prof2
                      : idx == 3
                      ? prof3
                      : idx == 4
                      ? prof4
                      : prof5
                    ).filter((el) => el.Average_sal)?.length
                      ? (idx == 0
                          ? prof0
                          : idx == 1
                          ? prof1
                          : idx == 2
                          ? prof2
                          : idx == 3
                          ? prof3
                          : idx == 4
                          ? prof4
                          : prof5
                        ).map(
                          (e) =>
                            e.Average_sal && <h5>{salary(e.Average_sal)}</h5>
                        )
                      : "No Data Available"}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div> */}

      <Modal
        centered
        show={show}
        onHide={handleClose}
        size="md"
        className="coupon_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "orangered" }}>Print Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactToPrint
            trigger={() => {
              return (
                <div>
                  <div style={{ fontWeight: "600" }}>
                    Please print or save the pdf in landscape mode as the
                    content may not fit in portrait mode!
                  </div>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        setShow(false);
                      }}
                      style={{ margin: "5px auto" }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            }}
            content={() => componentRef.current}
            copyStyles={true}
            documentTitle="illuminate.upskilltalent.com @All Rights Reserved"
            pageStyle={true}
          />
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default SearchResults;
