import React from "react";
import Layout from "../Layout/Layout";
// import './css/style1.css';
import axios from "axios";
import { api } from "../../assets/API/api";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import Table from "react-bootstrap/esm/Table";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";
import Loader from "../Loader/loader";
import { useHistory } from "react-router-dom";
import NoDataFound from "../NoDataFound/noDataFound";
import BackArrow from "../backArrow/backArrow";

var arr = [];

export default function NotificationControl() {
  const st = useContext(myContext);
  const [activePlans, setActivePlans] = st.activePlans;
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    header: "",
    body: "",
    notificationType: "both",
  });
  const [checked, setChecked] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();

  const hist = useHistory();
  const fetchType = hist.location.state?.type;

  const selectAll = (e) => {
    let pState = [];

    if (e.target.checked) {
      for (let i = 0; i < users.length; i++) {
        pState.push(true);
      }
      setChecked(pState);
    } else {
      for (let i = 0; i < users.length; i++) {
        pState.push(false);
      }
      setChecked(pState);
    }

    let tempArr = [];
    for (let i = 0; i < users.length; i++) {
      if (pState[i] == true) {
        tempArr.push(users[i]);
      }
      setSelectedUsers(tempArr);
    }
  };

  const handleCheck = (e, idx) => {
    let pState = [...checked];
    if (e.target.checked) {
      pState[idx] = true;
      setChecked(pState);
    } else {
      pState[idx] = false;
      setChecked(pState);
    }

    let tempArr = [];
    for (let i = 0; i < users.length; i++) {
      if (pState[i] == true) {
        tempArr.push(users[i]);
      }
      setSelectedUsers(tempArr);
    }
  };

  useEffect(() => {
    setForm({
      header: "",
      body: "",
      notificationType: "both",
    });
    if (
      temp?.user_id ||
      temp?.userType === "Company Head" ||
      (temp?.userType === "enterprise-user" &&
        activePlans[0]?.purchase_type == "enterprise-plan")
    ) {
      setLoading(true);
      if (fetchType === "enterprise") {
        axios
          .get(
            `${api}/getAllUsers/?tokenMail=${temp?.email}&email=${temp?.email}`
          )
          .then((res) => {
            // console.log(res.data);
            if (!res.data?.length) {
              setPageNo(1);
            }
            setUsers(res.data);
            arr = res.data;
            let pState = [];
            for (let i = 0; i < res.data.length; i++) {
              pState.push(false);
            }
            setChecked(pState);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
      } else {
        axios
          .get(
            `${api}/getAllUsers/?tokenMail=${temp?.email}&boss=true&pageNo=${pageNo}`
          )
          .then((res) => {
            // console.log(res.data);
            if (!res.data?.length) {
              setPageNo(1);
            }
            setUsers(res.data);
            arr = res.data;
            let pState = [];
            for (let i = 0; i < res.data.length; i++) {
              pState.push(false);
            }
            setChecked(pState);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [pageNo]);

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    // let pState = [];
    // for (let i = 0; i < users.length; i++) {
    //     pState.push(false);
    // }
    // setChecked(pState);

    if (name === "state") {
      if (!val) {
        setUsers(arr);
        setSelectedUsers([]);
      } else {
        setUsers(
          arr.filter((el) =>
            el.state?.toLowerCase().includes(val.toLowerCase())
          )
        );
        setSelectedUsers([]);
      }
    }
    if (name === "city") {
      if (!val) {
        setUsers(arr);
        setSelectedUsers([]);
      } else {
        setUsers(
          arr.filter((el) => el.city?.toLowerCase().includes(val.toLowerCase()))
        );
        setSelectedUsers([]);
      }
    }
    if (name == "country") {
      if (!val) {
        setUsers(arr);
        setSelectedUsers([]);
      } else {
        setUsers(
          arr.filter((el) =>
            el.country?.toLowerCase().includes(val.toLowerCase())
          )
        );
        setSelectedUsers([]);
      }
    }
    if (name == "email") {
      if (!val) {
        setUsers(arr);
        setSelectedUsers([]);
      } else {
        setUsers(
          arr.filter((el) =>
            el.email?.toLowerCase().includes(val.toLowerCase())
          )
        );
        setSelectedUsers([]);
      }
    }
    if (name == "user-type") {
      if (!val) {
        setUsers(arr);
        setSelectedUsers([]);
      } else {
        setUsers(
          arr.filter((el) =>
            el.userType?.toLowerCase().includes(val.toLowerCase())
          )
        );
        setSelectedUsers([]);
      }
    }

    setForm({
      ...form,
      [name]: val,
    });
  };

  const fullUserList = () => {
    setUsers([]);
    if (!temp?.user_id || !temp?.userType === "Company Head") {
      return;
    }
    setLoading(true);
    axios
      .get(`${api}/getAllUsers/?tokenMail=${temp?.email}&boss=true`)
      .then((res) => {
        // console.log(res.data);
        if (!res.data?.length) {
          setPageNo(1);
        }
        setUsers(res.data);
        arr = res.data;
        let pState = [];
        for (let i = 0; i < res.data.length; i++) {
          pState.push(false);
        }
        setChecked(pState);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const sendNotification = (e) => {
    e.preventDefault();
    if (!temp?.user_id) {
      return;
    }

    if (!selectedUsers?.length) {
      alert("Please select atleast one user!");
      return;
    }

    axios
      .post(`${api}/sendNewNotification?tokenMail=${temp?.email}`, {
        users: selectedUsers,
        data: form,
      })
      .then((res) => {
        // console.log(res.data);
        alert("Notification sent!");
        setForm({
          ...form,
          header: "",
          body: "",
          // notificationType: "both",
        });
      })
      .catch((err) => console.log(err));
  };

  // console.log(fetchType)
  console.log(form);
  return (
    <Layout sideBarActive={12}>
      <div className="notificationControl">
        {fetchType === "enterprise" ? (
          <BackArrow back={"/enterprise-menu"} />
        ) : null}
        <h1 style={{ fontWeight: "600", fontSize: "1rem" }}>Filter Options</h1>
        <div className="filter_admin">
          <div>
            <div>City:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="city"
              onChange={handleChange}
              placeholder="filter users based on city"
              type={"text"}
            />
          </div>
          <div>
            <div>State:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="state"
              onChange={handleChange}
              placeholder="filter users based on state"
              type={"text"}
            />
          </div>
          <div>
            <div>Country:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="country"
              onChange={handleChange}
              placeholder="filter users based on country"
              type={"text"}
            />
          </div>
          <div>
            <div>Email:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="email"
              onChange={handleChange}
              placeholder="filter users based on email"
              type={"text"}
            />
          </div>
          {fetchType === "enterprise" ? null : (
            <div>
              <div>User Type:</div>
              <input
                style={{ paddingLeft: "3px", borderRadius: "5px" }}
                name="user-type"
                onChange={handleChange}
                placeholder="filter users based on user type"
                type={"text"}
              />
            </div>
          )}
        </div>
        <h1 style={{ fontWeight: "600", fontSize: "21px" }}>
          Notification Control Center
        </h1>
        <div
          style={{
            fontWeight: "600",
            paddingLeft: "5px",
            fontStyle: "italic",
            fontSize: "10px",
            height: "10px",
            marginBottom: "3px",
            textAlign: "center",
            // display: selectedUsers?.length
            //   ? "block"
            //   : "none",
          }}
        >
          Selected Users: {selectedUsers?.length}
        </div>
        <div className="scrollableTabel autoHeight load_animation">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Table style={{ minWidth: "100%" }} striped responsive1>
                <thead>
                  <tr>
                    <th
                      style={{
                        zIndex: "2",
                        backgroundColor:
                          fetchType == "enterprise" ? "#0f4c62" : "",
                        color: fetchType == "enterprise" ? "white" : "",
                      }}
                    >
                      <input
                        onChange={(e) => selectAll(e)}
                        style={{
                          // width: "20px",
                          // height: "20px",
                          marginRight: "10px",
                        }}
                        type={"checkbox"}
                      />
                      <label>Select All</label>
                    </th>
                    <th
                      style={{
                        zIndex: "2",
                        backgroundColor:
                          fetchType == "enterprise" ? "#0f4c62" : "",
                        color: fetchType == "enterprise" ? "white" : "",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        backgroundColor:
                          fetchType == "enterprise" ? "#0f4c62" : "",
                        color: fetchType == "enterprise" ? "white" : "",
                      }}
                    >
                      Email
                    </th>
                    {fetchType === "enterprise" ? null : (
                      <th
                        style={{
                          backgroundColor:
                            fetchType == "enterprise" ? "#0f4c62" : "",
                          color: fetchType == "enterprise" ? "white" : "",
                        }}
                      >
                        User Type
                      </th>
                    )}
                    <th
                      style={{
                        backgroundColor:
                          fetchType == "enterprise" ? "#0f4c62" : "",
                        color: fetchType == "enterprise" ? "white" : "",
                      }}
                    >
                      City
                    </th>
                    <th
                      style={{
                        backgroundColor:
                          fetchType == "enterprise" ? "#0f4c62" : "",
                        color: fetchType == "enterprise" ? "white" : "",
                      }}
                    >
                      State
                    </th>
                    <th
                      style={{
                        backgroundColor:
                          fetchType == "enterprise" ? "#0f4c62" : "",
                        color: fetchType == "enterprise" ? "white" : "",
                      }}
                    >
                      Country
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.length > 0
                    ? users.map((el, idx) => {
                        return (
                          <tr
                            style={{ boxShadow: "0px 0px 0px" }}
                            onClick={() => {
                              // setUsers(arr.filter(e => e.email === el.email));
                              setForm({
                                ...form,
                                id: el?.user_id,
                                firstName: el?.firstName,
                                lastName: el?.lastName,
                                email: el?.email,
                                password: el?.password,
                                mobile: el?.mobile,
                              });
                            }}
                          >
                            <td>
                              <input
                                onChange={(e) => handleCheck(e, idx)}
                                checked={checked[idx]}
                                // style={{ width: "20px", height: "20px" }}
                                type="checkbox"
                              />
                            </td>
                            <td
                              style={{
                                fontWeight: "600",
                                color: "#F16623",
                              }}
                            >
                              {el.firstName || "NA"} {el.lastName}
                            </td>
                            <td>{el.email}</td>
                            {fetchType === "enterprise" ? null : (
                              <td>
                                <span className="status" datatype="active">
                                  {el?.userType}
                                </span>
                              </td>
                            )}
                            <td>{el?.city || "NA"}</td>
                            <td>{el?.state || "NA"}</td>
                            <td>{el?.country || "NA"}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              {!users?.length && <NoDataFound text={"No Users Found"} />}
            </>
          )}
        </div>
        <div
          style={{
            display: fetchType === "enterprise" ? "none" : "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Button className="button_shadow" onClick={fullUserList}>
            Full User List
          </Button>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              className="button_shadow"
              onClick={() => {
                if (pageNo <= 1) {
                  return;
                }
                setPageNo((p) => p - 1);
              }}
              style={{ marginRight: "15px" }}
            >
              Prev
            </Button>
            <div style={{ marginRight: "15px", padding: "7px" }}>
              Page No: {pageNo}
            </div>
            <Button
              className="button_shadow"
              onClick={() => {
                setPageNo((p) => p + 1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
        {/* <div className="text-end py-3"><button type="button" className="notificationBttn">Send Notification</button></div> */}
        <div
          style={{
            marginTop: "15px",
            display: selectedUsers?.length ? "block" : "none",
          }}
          className="notificationFields"
        >
          <Form>
            <div className="planCreate">
              <h1>Send New Notification</h1>
              <div className="row">
                <div className="col-lg-12">
                  <div className="radio_row">
                    <label for="flexRadioDefault1">Notification Type</label>
                    <select onChange={handleChange} name="notificationType">
                      <option name="notificationType" value={"Both"}>
                        Email & In-App
                      </option>
                      <option name="notificationType" value="in-app">
                        In-App
                      </option>
                      <option name="notificationType" value={"email"}>
                        Email
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="header"
                      value={form.header}
                      type="text"
                      placeholder="Notification Title"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Notification</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="body"
                      value={form.body}
                      as="textarea"
                      rows={5}
                      placeholder="Notification Content"
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group>
                    <Button
                      className="button_shadow"
                      onClick={sendNotification}
                      style={{ width: "250px" }}
                    >
                      Send
                    </Button>
                    {/* <Form.Control type="submit" value="Send Notification" /> */}
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
}
