import { read, utils, writeFileXLSX } from "xlsx";



const exportToExcel = ({ key, data }) => {
    if (!data?.length || !key) { return; }

    if (key === 'export-occupations-list') {
        const ws = utils.json_to_sheet(
            data?.map((e) => {
                return { Occupation: e.Occupation };
            })
        );
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "occupations");
        writeFileXLSX(wb, "illuminate_occupations.xlsx");
    }

    if (key === 'export-detailed-analysis') {

        let descriptions = data?.filter(e => e.Descriptions)?.map(e => { return { Occupation: e.Occupation, Descriptions: e.Descriptions } });
        const ws = utils.json_to_sheet(descriptions);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "descriptions");

        let alternativeTitle = data?.filter(e => e.Alternate_Title)?.map(e => { return { Occupation: e.Occupation, Alternate_Title: e.Alternate_Title } })
        if (alternativeTitle?.length) {
            const ws1 = utils.json_to_sheet(alternativeTitle);
            utils.book_append_sheet(wb, ws1, "alternate titles");
        }

        let knowledge = data?.filter(e => e.Knowledge)?.map(e => { return { Occupation: e.Occupation, Knowledge: e.Knowledge } });
        if (knowledge?.length) {
            const ws2 = utils.json_to_sheet(knowledge);
            utils.book_append_sheet(wb, ws2, "knowledge");
        }

        let education = data?.filter(e => e.Education)?.map(e => { return { Occupation: e.Occupation, Education: e.Education } });
        if (education?.length) {
            const ws10 = utils.json_to_sheet(education);
            utils.book_append_sheet(wb, ws10, "education");
        }

        let softSkills = data?.filter(e => e.Soft_Skills)?.map(e => { return { Occupation: e.Occupation, Soft_Skills: e.Soft_Skills } });
        if (softSkills?.length) {
            const ws3 = utils.json_to_sheet(softSkills);
            utils.book_append_sheet(wb, ws3, "soft skills");
        }

        let hardSkills = data?.filter(e => e.Hard_Skills)?.map(e => { return { Occupation: e.Occupation, Hard_Skills: e.Hard_Skills } });
        if (hardSkills?.length) {
            const ws4 = utils.json_to_sheet(hardSkills);
            utils.book_append_sheet(wb, ws4, "hard skills");
        }

        let skillsApplied = data?.filter(e => e.Skills_Applied)?.map(e => { return { Occupation: e.Occupation, Skills_Applied: e.Skills_Applied } });
        if (skillsApplied?.length) {
            const ws5 = utils.json_to_sheet(skillsApplied);
            utils.book_append_sheet(wb, ws5, "skills applied");
        }

        let techTools = data?.filter(e => e.Technology_Tools)?.map(e => { return { Occupation: e.Occupation, Technology_Tools: e.Technology_Tools } });
        if (techTools?.length) {
            const ws6 = utils.json_to_sheet(techTools);
            utils.book_append_sheet(wb, ws6, "technology tools");
        }

        let minSal = data?.filter(e => e.Salary_Min)?.map(e => { return { Occupation: e.Occupation, Salary_Min: e.Salary_Min } });
        if (minSal?.length) {
            const ws7 = utils.json_to_sheet(minSal);
            utils.book_append_sheet(wb, ws7, "minimum salary");
        }

        let maxSal = data?.filter(e => e.Salary_Max)?.map(e => { return { Occupation: e.Occupation, Salary_Max: e.Salary_Max } });
        if (maxSal?.length) {
            const ws8 = utils.json_to_sheet(maxSal);
            utils.book_append_sheet(wb, ws8, "maximum salary");
        }

        let avgSal = data?.filter(e => e.Average_sal)?.map(e => { return { Occupation: e.Occupation, Average_Salary: e.Average_sal } });
        if (avgSal?.length) {
            const ws9 = utils.json_to_sheet(avgSal);
            utils.book_append_sheet(wb, ws9, "average salary");
        }

        writeFileXLSX(wb, "illuminate_detailed_analysis.xlsx");


    }

}

export { exportToExcel };