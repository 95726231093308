import React from "react";
import { RotatingLines } from "react-loader-spinner";

function Loader({ width, color }) {
  return width ? (
    <RotatingLines
      strokeColor={color || "#0F4C62"}
      strokeWidth="3"
      animationDuration="0.75"
      width={width}
      visible={true}
    />
  ) : (
    <div style={{ position: "relative", height: "200px" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <RotatingLines
          strokeColor="#0F4C62"
          strokeWidth="4"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
      </div>
    </div>
  );
}

function LoaderTwo() {
  return (
    <div
      style={{
        zIndex: "11",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "rgb(15, 76, 98)",
        borderRadius: "3px",
        padding: "1px",
        // boxShadow: "0px 0px 5px azure",
      }}
    >
      <RotatingLines
        strokeColor="white"
        strokeWidth="4"
        animationDuration="1"
        width="35"
        visible={true}
      />
    </div>
  );
}

export default Loader;

export { LoaderTwo };
