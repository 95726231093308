import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { api } from "../../assets/API/api";
import axios from "axios";
import { useHistory } from "react-router-dom";
import logo_inner from "../../assets/images/logo_inner.png";
import Modal from "react-bootstrap/Modal";
import Footer from "../Footer/Footer";
import FooterTwo from "../Footer/FooterTwo";

let btnStyle = {
  backgroundColor: "#1ea5d4",
  border: 0,
  boxShadow: "0px 0px 5px grey",
};

export default function ForgotPassword({ layout, email }) {
  const [otp, setOtp] = useState();
  const [validOTP, setValidOTP] = useState(false);
  const [inValidOTP, setInValidOTP] = useState(false);
  const [invalidPass, setInvalidPass] = useState();
  const [input, setInput] = useState({
    email: "",
    password1: "",
    password2: "",
    otp: "",
  });
  const [show, setShow] = useState(false);
  const [noUser, setNoUser] = useState(false);

  useEffect(() => {
    setInput({ ...input, email: email });
  }, [email]);

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    setInValidOTP(false);

    setInput({
      ...input,
      [name]: value,
    });

    if (name == "password1") validator(value);
  };

  const sendOTP = (e) => {
    e.preventDefault();
    if (!input.email) {
      return;
    }
    // setOtp("1254")
    axios
      .post(`${api}/getOTP`, { email: input.email })
      .then((res) => {
        // console.log(res.data);
        if (res.data.message == "otp sent successfully") {
          setOtp(res.data.otp);
        }
        // alert("Your OTP has been sent to your email.  If you don’t see it in your inbox, check your spam folder.");
        setShow(true);
      })
      .catch((err) => {
        console.error(err);
        setNoUser(true);
        setShow(true);
        setOtp();
      });
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${api}/verify-otp`, {
        email: input.email,
        otp: input.otp,
      });
      setValidOTP(true);
      setInValidOTP(false);
    } catch (err) {
      setInValidOTP(true);
    }

    // if (otp === input.otp) {
    //   setValidOTP(true);
    //   setInValidOTP(false);
    // } else {
    //   setInValidOTP(true);
    // }
  };

  const validator = (val) => {
    // console.log(val);
    const passRegEx = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (!passRegEx.test(val)) {
      setInvalidPass(true);
    } else {
      setInvalidPass(false);
    }
  };

  let hist = useHistory();

  // function for creating new password in the databse
  const newPassword = (e) => {
    e.preventDefault();

    if (input.password1 == "" || input.password2 == "") {
      alert("Password can not be empty");
      return;
    }

    if (invalidPass) {
      alert("please enter a valid password");
      return;
    }

    axios
      .post(`${api}/updatePassword`, {
        email: input.email,
        password: input.password1,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.message == "password updated successfully") {
          alert(
            "New password has been updated successfully. Please sign in again."
          );
          hist.push("/");
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div
      className="load_animation"
      style={{ textAlign: "center", marginTop: "3%" }}
    >
      {!layout && <img src={logo_inner} alt="company's logo"></img>}
      <div className="box_with_shadow_padding mt-5 p-5">
        {!validOTP && (
          <Form.Group className="mb-3" controlId="formBasicEmail">
            {!layout && (
              <h3 style={{ color: "crimson", fontWeight: "600" }}>
                Don't Worry!
              </h3>
            )}
            <h2 style={{ textAlign: "center" }}>
              Enter your registered email below.
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "11px",
              }}
            >
              <div style={{ margin: "auto 0" }}>
                <label style={{ marginRight: "5px" }}>Email :</label>
                <input
                  name="email"
                  value={input.email}
                  style={{
                    borderRadius: "5px",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                  onChange={handleChange}
                  type="email"
                  placeholder=""
                />
              </div>
              <div>
                <Button
                  className="button_shadow"
                  datatype="new-search"
                  onClick={sendOTP}
                  variant="primary"
                >
                  {otp && !show ? "Send Again" : "Send Now"}
                </Button>
              </div>
            </div>
          </Form.Group>
        )}
        {inValidOTP && (
          <h3 style={{ textAlign: "center", color: "red" }}>
            Invalid OTP. Please enter it again carefully.
          </h3>
        )}
        {!validOTP && otp && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <label style={{ marginTop: "5px" }}>
              Enter Temporary Password :
            </label>
            <input
              name="otp"
              value={input.otp}
              onChange={handleChange}
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
            />
            <Button
              className="button_shadow"
              datatype="new-search"
              variant="primary"
              onClick={verifyOTP}
            >
              Submit
            </Button>
            <br></br>
            <br></br>
          </div>
        )}
        {validOTP && (
          <>
            <h2>Just a click away!</h2>
            <label>New Password :</label>
            <input
              name="password1"
              type={"password"}
              value={input.password1}
              onChange={handleChange}
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
            />
            <br></br>
            <br></br>
            {input.password1 && invalidPass ? (
              <h6 style={{ color: "red" }}>
                Atleast one lowercase, one uppercase, one special character is
                required!
              </h6>
            ) : null}
            <label>Confirm Password :</label>
            <input
              name="password2"
              type={"password"}
              value={input.password2}
              onChange={handleChange}
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
            />
            <br></br>
            <br></br>
            {input.password2 !== "" && input.password1 !== input.password2 ? (
              <h4 style={{ color: "red" }}>Password mismatched!</h4>
            ) : null}
            <Button
              className="button_shadow"
              datatype="new-search"
              onClick={newPassword}
              variant="primary"
              style={{ margin: "auto" }}
            >
              Submit
            </Button>
          </>
        )}
      </div>

      {!layout && (
        <div className="forgot_password_footer">
          <FooterTwo />
        </div>
      )}

      <Modal
        centered
        show={show}
        onHide={handleClose}
        size="md"
        className="coupon_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "orangered" }}>Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {noUser
                ? "No such user found!"
                : "Your temporary password has been sent to your email.  If you don’t see it in your inbox, check your spam folder."}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "5px auto",
              }}
            >
              <Button
                onClick={() => {
                  setNoUser(false);
                  setShow(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
