import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { myContext } from "../../App";
import { api } from "../../assets/API/api";
import axios from "axios";
import { useState } from "react";
import { noOfDays } from "../Layout/Layout";

export default function Timeline() {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [activePlans, setActivePlans] = st.activePlans;
  const [history, setHistory] = useState([]);

  let temp = JSON.parse(localStorage.getItem("user-data"));

  useEffect(() => {
    if (!temp?.user_id) {
      return;
    }
    if (activePlans[0]?.no_of_users > 1) {
      // let timerId = setInterval(() => {
      axios
        .get(
          `${api}/getUserActivity/?tokenMail=${temp?.email}&email=${temp.email}`
        )
        .then((res) => {
          // console.log(res.data);
          setHistory(res.data);
        })
        .catch((err) => console.log(err));
    }
    // }, 10000)

    // return clearInterval(timerId)
  }, [activePlans.length]);

  // console.log('users activity ', history);

  return (
    <div
      className="MyActivityAreaCoverTwoBoxInnerLeft"
      style={{ boxShadow: "0px 0px 0px" }}
    >
      <p className="head">User's Activity Timeline</p>
      <div style={{ padding: "0 20px" }} className="timeLine">
        {history?.length ? (
          history.map((el, idx) => {
            return (
              idx < 8 && (
                <div className="timeLineListItem">
                  <span>
                    {noOfDays(el?.last_login) ? (
                      <>
                        {noOfDays(el?.last_login)}
                        {" Days"}
                        {" ago"}
                      </>
                    ) : (
                      "Today"
                    )}
                  </span>
                  <h6>
                    <span style={{ marginLeft: "8px" }}>
                      {el.firstName || "No Name" + " " + el.lastName || ""}
                    </span>
                    logged in on {el.login_date?.slice(0, 10)} at{" "}
                    {el.login_date?.slice(11, 16)}
                  </h6>
                </div>
              )
            );
          })
        ) : (
          <div style={{ textAlign: "center", marginTop: "30%" }}>
            No Activity Found!
          </div>
        )}
      </div>
    </div>
  );
}
