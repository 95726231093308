import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";

import login_logo_header from "../../assets/images/login_logo_header.png";
import login_img from "../../assets/images/login_img.png";
import eye_icon from "../../assets/images/eye_icon.png";
import linkend_white_login from "../../assets/images/linkend_white_login.png";
import { api } from "../../assets/API/api";
import axios from "axios";
// import LinkedInPage from '../LinkedIn/LinkedIn';

import FooterTwo from "../Footer/FooterTwo";
import { Button } from "react-bootstrap";
import { useContext } from "react";
import { myContext } from "../../App";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useEffect } from "react";
import Loader from "../Loader/loader";

const Signup = () => {
  const [input, setInput] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    password1: "",
    password2: "",
  });
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [activePlans, setActivePlans] = st.activePlans;
  const [addon, setAddon] = st.addon;
  const [invalidPass, setInvalidPass] = useState();
  const [invalidEmail, setInvalidEmail] = useState();
  const [oldUser, setOldUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sideBarActive, setSideBarActive] = st.sideBar;
  const [usedPoints, setUsedPoints] = st.usedPoints;
  const [recentSearches, setRecentSearches] = st.recentSearches;
  const [recentFavSearches, setRecentFaVSearches] = st.recentFavSearches;
  const [recentTransactions, setRecenttransactions] = st.recentTransactions;
  // linked in integration
  const [auth_code, setAuth_code] = useState("");

  let history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
    localStorage.clear();
    setActivePlans([]);
    setAddon([]);
    setSideBarActive(0);
    setRecentFaVSearches([]);
    setRecentSearches([]);
    setRecenttransactions([]);
    setUsedPoints(0);
  }, []);

  const signup = (e) => {
    e.preventDefault();

    if (
      input.firstName === "" ||
      input.lastName === "" ||
      input.email === "" ||
      input.mobile === "" ||
      input.password1 === "" ||
      input.password2 === ""
    ) {
      alert("Please fill all the fields!");
      return;
    }
    if (input.password1 !== input.password2) {
      return;
    }
    if (invalidEmail && invalidPass) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/signup`, {
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        mobile: input.mobile,
        password: input.password2,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.message) {
          setOldUser(true);
          return;
        }
        setUserInfo({
          ...userInfo,
          id: res.data.user_id,
          firstName: input.firstName,
          lastName: input.lastName,
          mobile: input.mobile,
          email: input.email,
        });
        localStorage.setItem(
          "user-data",
          JSON.stringify({
            ...userInfo,
            ...input,
            user_id: res.data.user_id,
            token: res.data?.token,
          })
        );
        history.push("/Option");
      })
      .catch((e) => {
        // console.log(e.response.data);
        if (e.response.data === "something went wrong") {
          return alert("Something went wrong!");
        }
        setOldUser(true);
      })
      .finally((f) => setLoading(false));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setOldUser(false);
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });

    if (e.target.name == "email") validator("email", e.target.value);
    if (e.target.name == "password1") validator("password", e.target.value);
  };

  const validator = (type, val) => {
    // console.log(type, val);
    const passRegEx = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    const emailRegEx = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    if (type == "email") {
      if (!emailRegEx.test(val)) {
        setInvalidEmail(true);
      } else {
        setInvalidEmail(false);
      }
    }

    if (type == "password") {
      if (!passRegEx.test(val)) {
        setInvalidPass(true);
      } else {
        setInvalidPass(false);
      }
    }
  };

  let rediUrl = "https://illuminate.upskilltalent.com/linkedin";

  var data = {
    grant_type: "authorization_code",
    code: auth_code,
    client_id: "86xka0j1n6v2z0",
    client_secret: "Z4sPGFpwvwpMWNYq",
    redirect_uri: rediUrl,
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "86xka0j1n6v2z0",
    scope: "r_liteprofile+r_emailaddress",
    clientSecret: "Z4sPGFpwvwpMWNYq",
    // redirectUri: `http://localhost:3000/illuminate/build/linkedin`,
    redirectUri: rediUrl, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      setAuth_code(code);
      console.log("auth code: ", code);
      axios
        .post(`${api}/linkedinLogin`, { ...data, code: code })
        .then((res) => {
          // console.log("user data: ", res.data);
          setUserInfo({
            ...userInfo,
            email: res.data[0]?.email,
            firstName: res.data[0]?.firstName,
            lastName: res.data[0]?.lastName,
            mobile: res.data[0]?.mobile,
            userType: res.data[0]?.userType,
            id: res.data[0]?.user_id,
            oldUser: true,
            addedBy: res.data[0]?.added_by,
            date: res.data[0]?.date_of_joining,
          });
          localStorage.setItem("user-data", JSON.stringify(res.data[0]));
          history.push("/DashboardOldUser");
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  return (
    // <Layout>
    <>
      <div className="container">
        <div className="row logiTop">
          <div className="col-lg-6 left">
            <div className="inner_box">
              <p>
                <img
                  src={login_logo_header}
                  className=""
                  loading="lazy"
                  alt=""
                />
              </p>
              <p>
                <img src={login_img} className="" loading="lazy" alt="" />
              </p>
              <h1>
                Unlocking Today's <span>Skills</span> For Tomorrow's Jobs
              </h1>
            </div>
          </div>
          <div className="col-lg-6 right">
            <div className="login_right_inner_box_outer">
              <div className="login_right_inner_box">
                <Form>
                  <p className="head">Register</p>
                  <div className="row">
                    <Form.Group className="mb-3" controlId="formFirstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <Form.Group className="mb-3" controlId="formLastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                      />
                    </Form.Group>
                  </div>
                  <div className="row">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="email"
                        type="email"
                        placeholder="Email"
                      />
                    </Form.Group>
                  </div>
                  {invalidEmail ? (
                    <h6 style={{ color: "red" }}>Invalid email format!</h6>
                  ) : null}
                  <div className="row">
                    <Form.Group className="mb-3" controlId="formMobileNumber">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="mobile"
                        type="text"
                        placeholder="Mobile Number"
                      />
                    </Form.Group>
                  </div>
                  <div className="row password_div">
                    <Form.Group className="mb-3" controlId="formCreatePassword">
                      <Form.Label>Create Password</Form.Label>
                      <div className="password_box">
                        <Form.Control
                          onChange={handleChange}
                          id="passId"
                          name="password1"
                          type="password"
                          placeholder="Password"
                        />
                        <Button variant="primary" type="submit">
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              document.getElementById("passId").type = "text";
                            }}
                            src={eye_icon}
                            className=""
                            loading="lazy"
                            alt=""
                          />
                        </Button>
                      </div>
                    </Form.Group>
                  </div>
                  {invalidPass ? (
                    <h6 style={{ color: "red" }}>
                      At Least one lowercase, one uppercase, one special
                      character is required!
                    </h6>
                  ) : null}
                  <div className="row password_div">
                    <Form.Group
                      className="mb-3"
                      controlId="formConfirmPassword"
                    >
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="password_box">
                        <Form.Control
                          onChange={handleChange}
                          name="password2"
                          type="password"
                          placeholder="Password"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  {input.password2 !== "" &&
                  input.password1 !== input.password2 ? (
                    <h4 style={{ color: "red" }}>Password must match!</h4>
                  ) : null}
                  <div className="row">
                    {oldUser && (
                      <h1 style={{ color: "red" }}>
                        Account already exists. Use a new email address or click
                        on Forgot Your Password to reset.
                      </h1>
                    )}
                    <div className="buttn_row">
                      {/* <Link to='/Option'> */}
                      <Button
                        disabled={loading}
                        onClick={signup}
                        variant="primary"
                        type="submit"
                        className="login"
                      >
                        {loading ? (
                          <>
                            <Loader color={"white"} width={25} />
                            <span style={{ marginLeft: "5px" }}>
                              Please wait..
                            </span>
                          </>
                        ) : (
                          "Sign Up"
                        )}
                      </Button>
                      {/* </Link> */}
                    </div>
                    <div className="divider">
                      <span>Or</span>
                    </div>
                  </div>
                </Form>
                <div className="buttn_row">
                  <Button
                    onClick={linkedInLogin}
                    variant="primary"
                    type="submit"
                    className="social"
                  >
                    <img
                      src={linkend_white_login}
                      className=""
                      loading="lazy"
                      alt=""
                    />{" "}
                    Sign in with LinkedIn
                  </Button>
                </div>
                <div className="newUserP">
                  <p>
                    Existing User?{" "}
                    <Link
                      style={{
                        color: "blue",
                        fontWeight: "700",
                        borderBottom: "1.5px solid red",
                      }}
                      to="/"
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loginBottom">
          <FooterTwo />
        </div>
      </div>
    </>
    // </Layout>
  );
};

export default Signup;
