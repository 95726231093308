import React from "react";
import { Button } from "react-bootstrap";

import started_icon from "../../assets/images/started_icon.png";
import skill_icon from "../../assets/images/skill_icon.png";
import pie_chart_icon from "../../assets/images/pie_chart_icon.png";
import portfolio_icon from "../../assets/images/portfolio_icon.png";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
// import axios from 'axios';
// import { api } from '../../assets/API/api';
import "../Subscription/style.css";
import getSessionStorage from "../../customHooks/getSessionStorage";
import TodayDate from "../Date/date";

const WelcomeBox = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [authenticate, setAuthenticate] = st.authentication;
  // const [day, setDay] = useState("");
  // const [month, setMonth] = useState("");
  const [addon, setAddon] = st.addon;
  const [trial, setTrial] = st.trial;
  const [activePlans] = st.activePlans;
  const temp = getSessionStorage();

  // function handleDate(date) {
  //   // let date = date.slice(8, 10);
  //   let monthh = date.slice(4, 7);
  //   let day = date.slice(0, 3);
  //   // console.dir(monthh);

  //   // manipulating days
  //   if (day == "Mon") {
  //     setDay("Monday");
  //   }
  //   if (day == "Tue") {
  //     setDay("Tuesday");
  //   }
  //   if (day == "Wed") {
  //     setDay("Wednesday");
  //   }
  //   if (day == "Thu") {
  //     setDay("Thursday");
  //   }
  //   if (day == "Fri") {
  //     setDay("Friday");
  //   }
  //   if (day == "Sat") {
  //     setDay("Saturday");
  //   }
  //   if (day == "Sun") {
  //     setDay("Sunday");
  //   }

  //   // manipulating months
  //   if (monthh == "Jan") {
  //     setMonth("January");
  //   }
  //   if (monthh == "Feb") {
  //     setMonth("February");
  //   }
  //   if (monthh == "Mar") {
  //     setMonth("March");
  //   }
  //   if (monthh == "Apr") {
  //     setMonth("April");
  //   }
  //   if (monthh == "May") {
  //     setMonth("May");
  //   }
  //   if (monthh == "Jun") {
  //     setMonth("June");
  //   }
  //   if (monthh == "Jul") {
  //     setMonth("July");
  //   }
  //   if (monthh == "Aug") {
  //     setMonth("August");
  //   }
  //   if (monthh == "Sep") {
  //     setMonth("September");
  //   }
  //   if (monthh == "Oct") {
  //     setMonth("October");
  //   }
  //   if (monthh == "Nov") {
  //     setMonth("November");
  //   }
  //   if (monthh == "Dec") {
  //     setMonth("December");
  //   }
  // }

  // let date = new Date().toString();
  let time = new Date().toLocaleTimeString();

  // useEffect(() => {
  //   handleDate(date);
  // }, []);

  // let currDate = date?.slice(8, 10);
  // console.log('addon',addon);

  return (
    <>
      <div className="WelcomeBoxCover">
        <div className="welcomeTextTop">{time}</div>
        {/* <div className="welcomeTextTop load_animation">
          {currDate}
          <sup>
            {currDate > 10 && currDate < 20
              ? "th"
              : currDate % 10 == 1
              ? "st"
              : currDate % 10 == 2
              ? "nd"
              : currDate % 10 == 3
              ? "rd"
              : "th"}
          </sup>{" "}
          {month}, {day}, {date?.slice(10, 15)}
        </div> */}
        <TodayDate />
        <div className="welcomeText">
          Welcome {userInfo.firstName || temp.firstName}{" "}
          {userInfo.lastName || temp.lastName}
          {temp.userType === "Company Head" ? null : activePlans?.length ? (
            <span className="load_animation">{activePlans[0]?.plan_name}</span>
          ) : temp?.added_by?.includes("@") ? (
            <span className="load_animation">
              {temp?.organisation || "Starter"}
            </span>
          ) : trial ? (
            <span className="load_animation">Trial</span>
          ) : null}
          {addon?.length ? (
            <span className="load_animation">Pay By Search</span>
          ) : null}
        </div>
        <div className="row welcome4Box">
          <div className="col-md-6 col-lg-3 ">
            <div className="welcome4Box_1st">
              <p>Your Ultimate Skill & Talent Machine </p>
              <p>
                <img src={started_icon} loading="lazy" alt="" />
              </p>
              <p className="getStartedButton">
                <Link
                  to={temp?.userType == "Company Head" ? "#" : "/NewSearch"}
                >
                  <Button
                    style={{
                      cursor:
                        temp?.userType == "Company Head"
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Get Started
                  </Button>
                </Link>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="welcome4Box_2nd">
              <p>
                <img src={skill_icon} loading="lazy" alt="" />
                Today's Trending Skills
              </p>
              <ul>
                <li>#1</li>
                <li>Artificial Intelligence and Machine Learning</li>
              </ul>
              <ul>
                <li>#2</li>
                <li>Data Science</li>
              </ul>
              <ul>
                <li>#3</li>
                <li>Cybersecurity</li>
              </ul>
              <ul>
                <li>#4</li>
                <li>Digital Transformation</li>
              </ul>
              <ul>
                <li>#5</li>
                <li>Sales & Digital Marketing</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="welcome4Box_3rd">
              <p>
                <img src={portfolio_icon} loading="lazy" alt="" />
                Today's Trending Jobs
              </p>
              <ul>
                <li>#1</li>
                <li>Software Engineer</li>
              </ul>
              <ul>
                <li>#2</li>
                <li>Project Manager</li>
              </ul>
              <ul>
                <li>#3</li>
                <li>Java Software Engineer</li>
              </ul>
              <ul>
                <li>#4</li>
                <li>DevOps Engineer</li>
              </ul>
              <ul>
                <li>#5</li>
                <li>Full Stack Engineer</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="welcome4Box_4th">
              <p>
                <img src={pie_chart_icon} loading="lazy" alt="" />
                Today’s Trending Sectors
              </p>
              <ul>
                <li>#1</li>
                <li>Information Technology</li>
              </ul>
              <ul>
                <li>#2</li>
                <li>Telecom</li>
              </ul>
              <ul>
                <li>#3</li>
                <li>Healthcare</li>
              </ul>
              <ul>
                <li>#4</li>
                <li>Infrastructure</li>
              </ul>
              <ul>
                <li>#5</li>
                <li>Retail</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeBox;
