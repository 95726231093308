import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Layout from "../Layout/Layout";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { myContext } from "../../App";
import axios from "axios";
// import { useCallback } from 'react'
// import { useDebouncedCallback } from 'use-debounce';
import { api } from "../../assets/API/api";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { useRef } from "react";
import Loader from "../Loader/loader";
import TodayDate from "../Date/date";

var arr = [];

const NewSearch = () => {
  const st = useContext(myContext);
  const [searchParams, setSearchParams] = st.searchParams;
  const [authenticate, setAuthenticate] = st.authentication;
  const [search_id, setSearch_id] = st.search_id;
  const [balance, setBalance] = st.balance;
  const [variableData, setVariableData] = useState([]);
  const [userInfo, setUserInfo] = st.userInfo;
  const [displayArr, setDisplayArr] = st.displayArr;
  const [showOptions, setShowOptions] = useState({
    zero: false,
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  });
  const [popUp, setPopUp] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [trial, setTrial] = st.trial;
  const [myBalance, setMyBalance] = st.myBalance;
  const [lowBal, setLowBal] = useState(false);
  const [noBal, setNoBal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const temp1 = getSessionStorage();
  // console.log('trial st= ', myBalance);

  const handleClose = () => {
    setShow(false);
    setLowBal(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(value);

    if (e.target.name === "suggestion") {
      setSuggestion(value);
      return;
    }

    setSearchParams({
      ...searchParams,
      [name]: value,
    });

    if (name == "primaryProfession") {
      if (!searchParams.primary_profession) {
        setShowOptions(false);
      }
      setShowOptions({
        // ...showOptions,
        zero: true,
      });
    }

    if (name == "firstProfession") {
      if (!searchParams.first_profession) {
        setShowOptions(false);
      }
      setShowOptions({
        // ...showOptions,
        one: true,
      });
    }

    if (name == "secondProfession") {
      if (!searchParams.second_profession) {
        setShowOptions(false);
      }
      setShowOptions({
        // ...showOptions,
        two: true,
      });
    }

    if (name == "thirdProfession") {
      if (!searchParams.third_profession) {
        setShowOptions(false);
      }
      setShowOptions({
        // ...showOptions,
        three: true,
      });
    }

    if (name == "fourthProfession") {
      if (!searchParams.fourth_profession) {
        setShowOptions(false);
      }
      setShowOptions({
        // ...showOptions,
        four: true,
      });
    }
    if (name == "fifthProfession") {
      if (!searchParams.fifth_profession) {
        setShowOptions(false);
      }
      setShowOptions({
        // ...showOptions,
        five: true,
      });
    }
    if (value.length >= 2) {
      setVariableData(
        arr.filter(
          (el) =>
            el.Occupation &&
            el.Occupation.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setVariableData([]);
    }
  };

  const hist = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      !trial &&
      parseInt(myBalance.subscription_balance) +
        parseInt(myBalance.addon_balance) <
        10
    ) {
      setLowBal(true);
      setShow(true);
    }

    setSearchParams({
      primaryProfession: "",
      firstProfession: "",
      secondProfession: "",
      thirdProfession: "",
      fourthProfession: "",
      fifthProfession: "",
    });

    localStorage.setItem(
      "search-params",
      JSON.stringify({
        primaryProfession: "",
        firstProfession: "",
        secondProfession: "",
        thirdProfession: "",
        fourthProfession: "",
        fifthProfession: "",
      })
    );

    setLoading(true);
    axios
      .get(`${api}/getAllOccupations?tokenMail=${temp1?.email}`)
      .then((res) => {
        // console.log(res.data);
        arr = res.data;
      })
      .catch((err) => console.error(err))
      .finally((f) => setLoading(false));
  }, []);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  async function handleSearch(e) {
    e.preventDefault();

    const arrOfProfessions = Object.values(searchParams).filter((e) => e);

    const arrOfProfessions1 = Object.values(searchParams);

    if (
      trial &&
      parseInt(myBalance.subscription_balance) < arrOfProfessions?.length
    ) {
      // alert("You do not have sifficient balance to make the search. Kindly upgrade your plan to enjoy the service!");
      setNoBal(true);
      setShow(true);
      return;
    }

    if (
      !trial &&
      parseInt(myBalance.subscription_balance) +
        parseInt(myBalance.addon_balance) <
        arrOfProfessions.length
    ) {
      alert(
        "You do not have sufficient balance to make the search. Kindly upgrade your plan to enjoy the service!"
      );
      return;
    }

    setSearch_id(null);

    if (searchParams.primaryProfession === "") {
      alert("Primary profession can not be empty!");
      return;
    }

    if (arrOfProfessions.length < 2) {
      console.log(true);
      alert(
        "At least one profession is required to compare it with primary profession!"
      );
      return;
    }

    for (let i = 0; i < arrOfProfessions.length - 1; i++) {
      for (let j = i + 1; j < arrOfProfessions.length; j++) {
        if (arrOfProfessions[i] == arrOfProfessions[j]) {
          alert("Please insert unique professions!");
          return;
        }
      }
    }

    for (let i = 0; i < arrOfProfessions1.length; i++) {
      if (
        arrOfProfessions1[i] &&
        !arr.filter(
          (el) =>
            el.Occupation.toLowerCase() === arrOfProfessions1[i].toLowerCase()
        ).length
      ) {
        console.log("special test", i);
        if (i == 0) {
          setSearchParams({ ...searchParams, primaryProfession: "" });
        }
        if (i == 1) {
          setSearchParams({ ...searchParams, firstProfession: "" });
        }
        if (i == 2) {
          setSearchParams({ ...searchParams, secondProfession: "" });
        }
        if (i == 3) {
          setSearchParams({ ...searchParams, thirdProfession: "" });
        }
        if (i == 4) {
          setSearchParams({ ...searchParams, fourthProfession: "" });
        }
        if (i == 5) {
          setSearchParams({ ...searchParams, fifthProfession: "" });
        }
        setShow(true);
        return;
      }
    }

    if (!temp1.user_id) {
      alert("You have been logged out! Please Login again!");
      hist.push("/DashboardOldUser");
      return;
    }

    setLoadingData(true);
    localStorage.setItem("search-params", JSON.stringify(searchParams));

    await axios
      .post(`${api}/getResults?tokenMail=${temp?.email}`, searchParams)
      .then((res) => {
        setDisplayArr(res.data);
        localStorage.setItem("main-array", JSON.stringify(res.data));
        // console.log('all data: ', res.data);

        // saving search result to the database so that we can fetch the data from it whenever search history is required

        // hist.push('/SearchResults', hist.location.pathname);
      })
      .catch((err) => console.log(err));

    // console.log("first api call")

    let fullName = userInfo.firstName || temp.firstName;
    let lName = userInfo.lastName || temp.lastName;

    fullName = fullName + " ";
    fullName = fullName + lName;

    await axios
      .post(`${api}/saveSearchHistory?tokenMail=${temp?.email}`, {
        ...searchParams,
        id: userInfo.id || temp1.user_id,
        trial: trial,
        addedBy: userInfo.addedBy || temp1.added_by,
        fav: 0,
        points: arrOfProfessions.length,
        suggestion: suggestion,
        name: fullName,
        senderMail: userInfo.email || temp.email,
      })
      .then((res) => {
        // console.log("response=", res.data);
        setSearch_id(res.data.insertId);
        localStorage.setItem("search-id", JSON.stringify(res.data.insertId));
      })
      .catch((err) => console.log("error= ", err));
    // console.log("second api call");

    setLoadingData(false);
    hist.push("/SearchResults", hist.location.pathname);
  }

  const temp = (e) => {
    e.preventDefault();

    let name = e.target.className;
    const value = e.target.id;

    name = name?.includes("primaryProfession")
      ? "primaryProfession"
      : name?.includes("firstProfession")
      ? "firstProfession"
      : name?.includes("secondProfession")
      ? "secondProfession"
      : name?.includes("thirdProfession")
      ? "thirdProfession"
      : name?.includes("fourthProfession")
      ? "fourthProfession"
      : name?.includes("fifthProfession")
      ? "fifthProfession"
      : null;
    if (!name) {
      return;
    }

    // console.log(name, value)
    // console.log(name, e.target.value)
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
    setShowOptions(false);
  };

  let ref = useRef(null);

  const handleBlur = (e) => {
    const { className } = e.target;
    // console.log(className);
    if (className?.includes("blur-event")) {
    } else {
      setShowOptions(null);
    }
  };

  // console.log('filtered array');
  // console.log(prof0, prof1, prof2, prof3);
  // console.log(balance);

  // console.log('search params : ',Object.values(searchParams).filter(e=>e).length)

  return (
    <div ref={ref}>
      <Layout>
        {loading ? (
          <Loader />
        ) : (
          <div onClick={handleBlur}>
            <div className="new_search_continer">
              <TodayDate />
              <div class="welcomeText">New Search</div>
              <Form className="new_search_container">
                <div className="row">
                  <Form.Group
                    className="mb-3 new-search-input"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Search Occupation</Form.Label>
                    <Form.Control
                      className="blur-event"
                      name="primaryProfession"
                      type="text"
                      placeholder="Primary Profession"
                      value={searchParams.primaryProfession}
                      onChange={handleChange}
                    />
                    <div className="professions">
                      {showOptions?.zero
                        ? variableData.map((ele) => {
                            return (
                              <div
                                onClick={temp}
                                id={ele.Occupation}
                                className="primaryProfession blur-event"
                                style={{
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  padding: "5px",
                                  paddingLeft: "15px",
                                  cursor: "pointer",
                                }}
                                selected
                              >
                                {ele.Occupation}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </Form.Group>
                </div>
                <div className="row">
                  <div className=" form-group">
                    <label for="compare">Compare With</label>
                    <div className="CompareWith_form_field new-search-input">
                      <Form.Group
                        className="formBasicInput"
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          type="text"
                          placeholder="First Profession"
                          value={searchParams.firstProfession}
                          name="firstProfession"
                          onChange={handleChange}
                        />
                        <div className="professions">
                          {showOptions?.one
                            ? variableData.map((ele) => {
                                return (
                                  <div
                                    onClick={temp}
                                    id={ele.Occupation}
                                    className="firstProfession"
                                    style={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      cursor: "pointer",
                                    }}
                                    selected
                                  >
                                    {ele.Occupation}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <Form.Control
                          type="text"
                          placeholder="Second Profession"
                          value={searchParams.secondProfession}
                          name="secondProfession"
                          onChange={handleChange}
                        />
                        <div className="professions">
                          {showOptions?.two
                            ? variableData.map((ele) => {
                                return (
                                  <div
                                    onClick={temp}
                                    id={ele.Occupation}
                                    className="secondProfession"
                                    style={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      cursor: "pointer",
                                    }}
                                    selected
                                  >
                                    {ele.Occupation}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <Form.Control
                          type="text"
                          placeholder="Third Profession"
                          value={searchParams.thirdProfession}
                          name="thirdProfession"
                          onChange={handleChange}
                        />
                        <div className="professions">
                          {showOptions?.three
                            ? variableData.map((ele) => {
                                return (
                                  <div
                                    onClick={temp}
                                    id={ele.Occupation}
                                    className="thirdProfession"
                                    style={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      // backgroundColor: "#EDFAFF",
                                      cursor: "pointer",
                                    }}
                                    selected
                                  >
                                    {ele.Occupation}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <Form.Control
                          type="text"
                          placeholder="Fourth Profession"
                          value={searchParams.fourthProfession}
                          name="fourthProfession"
                          onChange={handleChange}
                        />
                        <div className="professions">
                          {showOptions?.four
                            ? variableData.map((ele) => {
                                return (
                                  <div
                                    onClick={temp}
                                    id={ele.Occupation}
                                    className="fourthProfession"
                                    style={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      // backgroundColor: "#EDFAFF",
                                      cursor: "pointer",
                                    }}
                                    selected
                                  >
                                    {ele.Occupation}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <Form.Control
                          type="text"
                          placeholder="Fifth Profession"
                          value={searchParams.fifthProfession}
                          name="fifthProfession"
                          onChange={handleChange}
                          style={{ marginBottom: "-12px" }}
                        />
                        <div className="professions">
                          {showOptions?.five
                            ? variableData.map((ele) => {
                                return (
                                  <div
                                    onClick={temp}
                                    id={ele.Occupation}
                                    className="fifthProfession"
                                    style={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      // backgroundColor: "#EDFAFF",
                                      cursor: "pointer",
                                    }}
                                    selected
                                  >
                                    {ele.Occupation}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </Form.Group>

                      {showSuggestion && (
                        <Form>
                          <Form.Group>
                            <Form.Label>Suggestion Box</Form.Label>
                            <Form.Control
                              name="suggestion"
                              type="text"
                              placeholder="Separate multiple professions with a comma"
                              value={suggestion}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Form>
                      )}
                    </div>
                    <div className="buttn_row mt-4">
                      {/* <Link to='/SearchResults'> */}
                      <Button
                        style={{
                          boxShadow: "0px 0px 4px rgba(0,0,0,0.2)",
                          fontWeight: "600",
                        }}
                        disabled={loadingData}
                        onClick={loadingData ? null : handleSearch}
                        variant="primary"
                        type="submit"
                        className="submit_button"
                      >
                        {loadingData ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            <RotatingLines
                              strokeColor="white"
                              strokeWidth="4"
                              animationDuration="0.75"
                              width="25"
                              visible={true}
                            />
                            <span>Please wait..</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </Form>

              <Modal
                centered
                show={show}
                onHide={handleClose}
                size="md"
                className="coupon_modal"
              >
                <Modal.Header
                  style={{ backgroundColor: "#EDFAFF" }}
                  closeButton
                >
                  <Modal.Title style={{ color: "red" }}>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {lowBal ? (
                    <div>
                      <div style={{ padding: "0 25px" }}>
                        Your Balance is{" "}
                        <span style={{ color: "red", fontWeight: "600" }}>
                          low!
                        </span>
                        <br></br>
                        Please upgrade your plan or purchase additional searches
                        for uninterrupted services.
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "30px",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                          onClick={() => {
                            hist.push("/Subscription");
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    </div>
                  ) : noBal ? (
                    <div
                      style={{
                        display: "grid",
                        gap: "15px",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        You do not have sufficient balance to make the search.{" "}
                        <br />
                        Kindly upgrade your plan to enjoy the service!
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{ width: "30%" }}
                          onClick={() => {
                            handleClose();
                            setNoBal(false);
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{ fontSize: "15px" }}>
                        Please select professions from the list only!<br></br>
                        <br></br>
                        You might have misspelled the occupation name or it’s
                        not in our database.<br></br>
                        <br></br>
                        You can also send us your occupation suggestion in the
                        box so we can add it to our occupations.<br></br>
                        <br></br>
                        Thanks so much!
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "5px auto",
                        }}
                      >
                        <Button
                          onClick={() => {
                            handleClose();
                            setShowSuggestion(true);
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
};

export default NewSearch;
