import React, { useState } from "react";
import Layout from "../Layout/Layout";
import Button from "react-bootstrap/Button";
// import { Link } from 'react-router-dom';
import { api } from "../../assets/API/api";
import axios from "axios";
import UserList from "./UserList";

import circle_icon from "../../assets/images/circle.png";
import { useEffect } from "react";
import add_user from "../../assets/images/add_user.png";
import { useContext } from "react";
import { myContext } from "../../App";
import { Form } from "react-bootstrap-v5";
import getSessionStorage from "../../customHooks/getSessionStorage";
import readXlsxFile from "read-excel-file";
// import { RotatingLines } from 'react-loader-spinner';
import Loader from "../Loader/loader";
import NoDataFound from "../NoDataFound/noDataFound";
import { Modal } from "react-bootstrap";
import BackArrow from "../backArrow/backArrow";

// let arr = [];

function getEachEmails(emails) {
  const arr = [];
  let tempStr = "";

  for (let i = 0; i < emails.length; i++) {
    if (emails[i] == ",") {
      arr.push(tempStr);
      tempStr = "";
      continue;
    }
    if (i == emails.length - 1) {
      tempStr += emails[i];
      arr.push(tempStr);
    }

    tempStr += emails[i];
  }

  return arr;
}

// const getManagerUsedPoints = (arr, email) => {
//   if (arr === []) { return 0 }
//   let sum = 0;
//   for (let i = 0; i < arr.length; i++) {
//     if (arr[i]?.email === email) { sum = sum + parseInt(arr[i]?.points) }
//     // console.log('test', i)
//   }
//   return sum;
// }

const Users = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [users, setUsers] = useState(null);
  const [emails, setEmails] = useState();
  const [history, setHistory] = useState([]);
  const [points, setPoints] = useState();
  const [show, setShow] = useState(false);
  const [activePlans, setActivePlans] = st.activePlans;
  const [myUsedPoints, setMyUsedPoints] = useState(0);
  const temp = getSessionStorage();
  const [allEmails, setAllEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejectedUers, setRejectedUsers] = useState([]);
  // console.log("test= ", users.length, activePlans[0]?.no_of_users)

  // const calPoints = (arr1, arr2) => {
  //   var mp = new Map();
  //   for (let i = 0; i < arr2.length; i++) {
  //     if (mp.has(arr2[i].user_id))
  //       mp.set(arr2[i].user_id, mp.get(arr2[i].user_id) + arr2[i].points)
  //     else
  //       mp.set(arr2[i].user_id, arr2[i].points)
  //   }
  //   return mp;
  // }

  const getAllUsers = async () => {
    await axios
      .get(
        `${api}/getAllUsers/?tokenMail=${temp?.email}&email=${
          userInfo?.email || temp?.email
        }`
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data?.length) setUsers(res.data);
        else setUsers([]);
        // arr = res.data;
      })
      .catch((err) => {
        console.log(err);
        setUsers([]);
      });
  };

  const readExcelFile = (e) => {
    e.preventDefault();
    let emails = [];
    let files = e.target.files[0];
    // console.log(files);
    readXlsxFile(files)
      .then((rows) => {
        // console.log(rows);
        // `rows` is an array of rows
        // each row being an array of cells.
        rows.map((row, idx) => {
          idx !== 0 &&
            row[0]?.includes("@") &&
            row[0]?.includes(".") &&
            emails.push(row[0]);
        });
        // console.log(emails);
        setAllEmails(emails);
      })
      .catch((err) => {
        console.log(err);
        alert("Falied to read file!");
      });
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }

    getAllUsers();
  }, []);

  const add = (e) => {
    e.preventDefault();

    if (!temp?.user_id) {
      return;
    }

    if (!activePlans?.length || activePlans[0]?.no_of_users <= 1) {
      alert("Please upgrade your plan to add users!");
      return;
    }

    if (
      activePlans?.length &&
      users?.length + getEachEmails(emails)?.length + 1 >
        activePlans[0]?.no_of_users
    ) {
      alert("You have reached max no. of allowed users for the current plan!");
      return;
    }

    if (!emails) {
      return;
    }

    setLoading(true);
    axios
      .post(`${api}/sendMail/?tokenMail=${temp?.email}`, {
        emails: emails,
        addedBy: userInfo.email || temp?.email,
        managerFirstName: temp?.firstName,
        managerLastName: temp?.lastName,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.rejectedMails?.length) {
          alert("Failed to add users!");
        } else {
          getAllUsers();
          alert(
            "Users added successfully. Email invitations will be sent shortly!"
          );
        }
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Failed to add some users!");
      })
      .finally((f) => setLoading(false));
  };

  const addUsers = async () => {
    if (!temp?.user_id) {
      return;
    }

    if (!activePlans?.length || activePlans[0]?.no_of_users <= 1) {
      alert("Please upgrade your plan to add users!");
      return;
    }

    if (
      activePlans?.length &&
      users?.length + allEmails?.length + 1 > activePlans[0]?.no_of_users
    ) {
      alert("You have reached max no. of allowed users for the current plan!");
      return;
    }

    if (!allEmails?.length) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${api}/add-users/?tokenMail=${temp?.email}`,
        {
          addedBy: userInfo.email || temp?.email,
          allUsers: allEmails,
          managerFirstName: temp?.firstName,
          managerLastName: temp?.lastName,
        }
      );
      getAllUsers();
      setLoading(false);
      setShow(false);
      // console.log(data);
      if (data?.rejectedMails?.length) {
        // setRejectedUsers(data.rejectedMails);
        alert("Failed to add some users.");
      } else {
        alert(
          "Users added successfully. Email invitations will be sent shortly!"
        );
      }
    } catch (error) {
      setLoading(false);
      return alert("Something went wrong.");
    }
  };

  // console.log('subordinates = ', users);

  return (
    <Layout>
      <div>
        {show ? (
          <>
            <BackArrow />
            <div
              className="userListBox load_animation"
              style={{ padding: "11px" }}
            >
              <label for="usersName" className="usersNameLabel">
                Invite your organization’s members here by separating each email
                with a comma.
              </label>
              <div
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#edfaff",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="col-lg-12"
              >
                <input
                  style={{ width: "80%", padding: "10px", borderRadius: "5px" }}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmails(e.target.value);
                  }}
                  type="email"
                  placeholder="Enter emails separated by a comma"
                  id="usersName"
                ></input>
                <Button
                  disabled={loading}
                  style={{
                    backgroundColor: "#1ea5d4",
                    border: "1px solid #1ea5d4",
                  }}
                  onClick={add}
                >
                  {loading ? "Sending Invite..." : "Send Invite"}
                </Button>
              </div>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  margin: "10px 0 0 0",
                }}
              >
                OR
              </h1>
              <div>
                <Form.Label>Import Emails From an Excel File.</Form.Label>
                <div
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#edfaff",
                    padding: "5px 10px 5px 2px",
                  }}
                  className="col-lg-12"
                >
                  <Form.Group>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        style={{ padding: "10px" }}
                        onChange={readExcelFile}
                        name=""
                        type="file"
                        placeholder=""
                      />
                      <Button
                        disabled={loading}
                        onClick={() => {
                          addUsers();
                        }}
                        style={{
                          backgroundColor: "#1ea5d4",
                          border: "1px solid #1ea5d4",
                        }}
                      >
                        {loading ? "Sending Invite..." : "Send Invite"}
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            className="left_menu_searchButton"
            style={{
              border: "0",
              display: "flex",
              gap: "1.5rem",
            }}
          >
            <BackArrow />
            <Button
              onClick={() => {
                setShow(true);
              }}
              variant="primary"
              type="submit"
              className="login"
            >
              <span style={{ margin: "0 auto" }}>
                <img src={add_user} className="" loading="lazy" />
                Invite your team here for access to Illuminate.
              </span>
            </Button>
          </div>
        )}

        {!users && <Loader />}

        {users?.length > 0 && (
          <UserList
            userList={users}
            points={points}
            myUsedPoints={myUsedPoints}
            getAllUsers={getAllUsers}
          />
        )}

        {users?.length == 0 && (
          <NoDataFound height={"300px"} text="No users found" />
        )}
      </div>
      {/* <Modal show={rejectedUers?.length}>
        <p></p>
      </Modal> */}
    </Layout>
  );
};

export default Users;
