import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

// import profile_icon from "../../assets/images/profile_icon.png"
import bell_icon from "../../assets/images/bell_icon.png";
import menu_left from "../../assets/images/menu_left.png";
import menu_right from "../../assets/images/menu-right.png";
import LeftMenu from "../LeftMenu/LeftMenu";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import no_image from "../../assets/images/no_image.png";
// import red_bell from '../../assets/images/red_bell.png';
import getSessionStorage from "../../customHooks/getSessionStorage";
import Loader from "../Loader/loader";
import { noOfDays } from "../Layout/Layout";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  // const [profileImage, setProfileImage] = useState(null);
  const [newNoti, setNewNoti] = useState(0);
  const [show, setShow] = useState(false);
  const [activePlans, setActivePlans] = st.activePlans;
  const [days, setDays] = st.days;
  const [trial, setTrial] = st.trial;
  const [addon, setAddon] = st.addon;
  const [myBalance, setMyBalance] = st.myBalance;
  const [usedPoints, setUsedPoints] = st.usedPoints;
  const [recentSearches, setRecentSearches] = st.recentSearches;
  const [recentFavSearches, setRecentFaVSearches] = st.recentFavSearches;
  const [recentTransactions, setRecenttransactions] = st.recentTransactions;
  const [loading, setLoading] = useState(false);
  const [dualSpAl, setDualSpAl] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const temp = getSessionStorage();

  useEffect(async () => {
    await axios
      .post(`${api}/profile/getImage?tokenMail=${temp?.email}`, {
        user_id: temp?.user_id || userInfo.id,
        subordinate: temp?.added_by?.includes("@") ? true : false,
        manager: temp?.added_by,
      })
      .then((res) => {
        localStorage.removeItem("profile");
        localStorage.setItem(
          "profile",
          JSON.stringify({
            profile: res.data[0]?.image_url || "",
            organisation_logo: res.data[0]?.org_img_url || "",
          })
        );
        setUserInfo({
          ...userInfo,
          profileImage: res.data[0]?.image_url || "",
        });
      })
      .catch((err) => console.error(err));
  }, [userInfo?.profileImage]);

  useEffect(() => {
    if (!temp?.user_id) {
      return;
    }

    if (temp?.userType !== "Company Head") {
      axios
        .post(`${api}/getNotifications?tokenMail=${temp?.email}`, {
          userId: userInfo.id || temp.user_id,
          unread: true,
        })
        .then((res) => {
          // console.log('unread notifications = ',res.data);
          setNewNoti(res?.data[0]?.unread);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const getUserData = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get(
        `${api}/me/?tokenMail=${temp?.email}&user_id=${temp?.user_id}&email=${temp?.email}`
      );
      const {
        user_data,
        active_plans,
        balance,
        used_points,
        recent_searches,
        recent_fav_searches,
        trial,
        recent_transactions,
        addons,
      } = data;

      // console.log(user_data);

      setUserInfo({
        ...userInfo,
        id: user_data?.user_id,
        title: user_data?.title || "Mr.",
        email: user_data.email,
        firstName: user_data.firstName,
        lastName: user_data.lastName,
        mobile: user_data.mobile,
        dob: user_data.dob,
        companyName: user_data.companyName,
        yourRole: user_data.role,
        companyWebsite: user_data.companyWebsite,
        businessCategories: user_data.businessCategories,
        address: user_data.address,
        city: user_data.city,
        state: user_data.state,
        zipCode: user_data.zipCode,
        country: user_data.country,
        userType: user_data.userType,
        highestQualification: user_data.highestQualification,
        oldUser: true,
        ...user_data,
      });

      localStorage.setItem("user-data", JSON.stringify(user_data));

      active_plans && setActivePlans([active_plans]);
      active_plans &&
        localStorage.setItem("active-plans", JSON.stringify([active_plans]));

      if (trial) {
        setDays(noOfDays(user_data?.date_of_joining));
        setMyBalance({
          subscription_balance: parseInt(balance?.trial_balance) || 0,
          addon_balance: 0,
        });
      } else {
        setMyBalance({
          subscription_balance: parseInt(balance?.subscription_balance) || 0,
          addon_balance: parseInt(balance?.addon_balance) || 0,
        });
      }

      used_points && setUsedPoints(used_points);

      recent_searches && setRecentSearches(recent_searches);

      recent_fav_searches && setRecentFaVSearches(recent_fav_searches);

      recent_transactions && setRecenttransactions(recent_transactions);

      addons && setAddon(addons);

      setTrial(trial);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const switchAccount = async () => {
    try {
      if (!temp?.user_id) {
        return;
      }
      let ok = window.confirm("Are you sure?");
      if (!ok) {
        return;
      }
      setLoading(true);
      await axios.post(`${api}/switch-account/?tokenMail=${temp?.email}`, {
        email: temp?.email,
        account_type: temp?.account_type,
      });
      localStorage.removeItem("profile");
      await getUserData();
      setDualSpAl(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return alert("Something went wrong!");
    }
  };

  // console.log("header");

  const hist = useHistory();
  // console.log("new notifications ", newNoti);

  return (
    <>
      <div
        style={{
          height: "66px",
          zIndex: "101",
          paddingRight: "6px",
          background: "aliceblue",
        }}
        className="header"
      >
        <div className="left">
          <button
            style={{ background: "transparent" }}
            className="btn btn-primary hamburger_menu"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <img src={menu_left} loading="lazy" alt="" />
          </button>

          {/* My Dashboard */}
          {hist.location.pathname.includes("DashboardOldUser")
            ? temp?.userType == "Company Head"
              ? "Admin Dashboard"
              : activePlans[0]?.no_of_users > 1
              ? temp?.userType === "enterprise-user"
                ? `${temp?.companyName || "Company Dashboard"}`
                : "Company Dashboard"
              : temp?.account_type === "company"
              ? `${temp?.organisation || "Company"}'s Dashboard`
              : "My Dashboard"
            : null}
          {hist.location.pathname.includes("FavouriteSearches")
            ? "Favourite Searches"
            : null}
          {hist.location.pathname.includes("PreviousSearchResults")
            ? "Previous Search Results"
            : null}
          {hist.location.pathname.includes("SearchHistory")
            ? "Search History"
            : null}
          {hist.location.pathname.includes("TransactionHistory")
            ? "Transaction History"
            : null}
          {hist.location.pathname === "/Subscription"
            ? "Subscription Page"
            : null}
          {hist.location.pathname.includes("SubscriptionControl")
            ? "Subscription Control Page"
            : null}
          {hist.location.pathname.includes("UserControl")
            ? "User Control Page"
            : null}
          {hist.location.pathname.includes("FAQ") ? "FAQs" : null}
          {hist.location.pathname === "/Notifications"
            ? "My Notifications"
            : null}
          {hist.location.pathname === "/MyProfile" ? "My Profile" : null}
          {hist.location.pathname === "/SearchResults" ? (
            <span>Illuminating your future starts here!</span>
          ) : null}
          {hist.location.pathname === "/NewSearch" ? (
            <span>You are just a click away!</span>
          ) : null}
          {hist.location.pathname === "/About" ? "Why Use Illuminate?" : null}
          {hist.location.pathname === "/PaymentGateway"
            ? "Payment Gateway"
            : null}
          {hist.location.pathname === "/AddUsers" ? "My Team's Area" : null}
          {hist.location.pathname === "/NotificationControl"
            ? "Notification Control Page"
            : null}
          {hist.location.pathname === "/CouponControl"
            ? "Coupon Control Page"
            : null}
          {hist.location.pathname === "/GiftCardControl"
            ? "Gift Card Control Page"
            : null}
          {hist.location.pathname === "/GiftCard"
            ? "Illuminate Gift Card"
            : null}
          {hist.location.pathname === "/EnterpriseControl"
            ? "Enterprise Control Page"
            : null}
          {hist.location.pathname === "/enterprise-menu"
            ? `${temp?.companyName || "Enterprise"}${
                temp?.companyName?.at(-1)?.toLowerCase() === "s" ? "'" : "'s"
              } Page`
            : null}
          {hist.location.pathname === "/search-by-skills"
            ? "Search By Skills"
            : null}
          {hist.location.pathname === "/occupation-results"
            ? "Skill And Certification Search Results"
            : null}
          {hist.location.pathname === "/exported-users"
            ? "Exported Users"
            : null}

          {/* {hist.location.pathname} */}
        </div>
        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            disabled={loading}
            onClick={() => {
              switchAccount();
            }}
            className="Upgrade"
            datatype="dual-account"
            style={{
              display: temp?.dual_account ? null : "none",
            }}
          >
            {loading ? (
              <Loader width={20} color={"white"} />
            ) : temp?.account_type === "individual" ? (
              "Personal Account"
            ) : (
              "Company Account"
            )}
          </Button>
          <div
            className="notification-for-mobile"
            style={{
              margin: "auto",
              display: temp?.userType === "Company Head" ? "none" : null,
            }}
          >
            <ul style={{ marginTop: "8px" }}>
              <li className="bell_icon_noti">
                <Link to="/Notifications">
                  <img src={bell_icon} className="" loading="lazy" />
                  {/* <FontAwesomeIcon icon={faBell} color='grey' size='2x' /> */}
                </Link>
                {/* </li> */}
                {newNoti ? (
                  // <li>
                  <div className="bell_text">{newNoti}</div>
                ) : // </li>
                null}
              </li>
            </ul>
          </div>
          <div className="right">
            <button
              style={{ background: "transparent" }}
              className="btn btn-primary hamburger_menu"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <img src={menu_right} loading="lazy" alt="" />
            </button>
            <ul>
              <Link to="/MyProfile">
                <li>
                  <img
                    style={{
                      width: "42px",
                      height: "42px",
                      borderRadius: "50%",
                    }}
                    src={userInfo?.profileImage || no_image}
                    className=""
                    loading="lazy"
                    alt="Profile"
                  />
                </li>
              </Link>
              {temp?.userType !== "Company Head" ? (
                <>
                  <li className="bell_icon_noti">
                    <Link to="/Notifications">
                      <img src={bell_icon} className="" loading="lazy" />
                      {/* <FontAwesomeIcon icon={faBell} color='grey' size='2x' /> */}
                    </Link>
                    {/* </li> */}
                    {newNoti ? (
                      // <li>
                      <div className="bell_text">{newNoti}</div>
                    ) : // </li>
                    null}
                  </li>
                  {temp?.userType === "enterprise-user" ||
                  temp?.added_by?.includes("@") ? null : (
                    <li>
                      <Link to="/Subscription">
                        <Button className="Upgrade">
                          {activePlans[0]?.plan_name === "Platinum"
                            ? "Need More Searches"
                            : "Upgrade"}
                        </Button>
                      </Link>
                    </li>
                  )}
                </>
              ) : null}
              {/* {userInfo.userType.toLowerCase()=="working professional" ?
                            < li > <Link to="/Subscription"><Button className='Upgrade'>Upgrade</Button></Link></li>
                    : null
                        } */}
            </ul>
          </div>
        </div>
      </div>

      {/* offcanvas start Left - mobile menu*/}
      <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        data-bs-scroll="true"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <LeftMenu />
          </div>
        </div>
      </div>
      {/* offcanvas end */}

      {/* offcanvas start Left - mobile menu*/}
      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        data-bs-scroll="true"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel"></h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            <ul className="mobile_top_menu_right">
              <li style={{ fontWeight: "600" }}>Profile</li>
              <li>
                <Link to="/MyProfile">
                  <img
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    src={userInfo?.profileImage || no_image}
                    className=""
                    loading="lazy"
                    alt="Profile"
                  />
                </Link>
              </li>
              {/* <li><Link to='/Notifications'><img src={bell_icon} className='' loading='lazy' /></Link></li> */}
              {/* <li><div className="bell_text">100+</div></li> */}
              {temp?.userType !== "Company Head" ? (
                <>
                  {/* <li><div className="bell_text">100+</div></li> */}
                  {temp?.userType === "enterprise-user" ||
                  temp?.added_by?.includes("@") ? null : (
                    <li>
                      <Link to="/Subscription">
                        <Button className="Upgrade">
                          {activePlans[0]?.plan_name === "Platinum"
                            ? "Need More Searches"
                            : "Upgrade"}
                        </Button>
                      </Link>
                    </li>
                  )}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      {/* offcanvas end */}
      <Modal show={dualSpAl}>
        <p
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "500",
            wordSpacing: "3px",
            marginTop: "1rem",
          }}
        >{`Switched to ${
          temp?.account_type === "company" ? "individual" : "company"
        } account!`}</p>
        {/* <p
          style={{
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "500",
            wordSpacing: "3px",
          }}
        >{`Please sign in again!`}</p> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            margin: "1rem 0",
          }}
        >
          <Button
            className="button_shadow"
            datatype="send-invite"
            style={{ padding: "0 1rem" }}
            onClick={() => {
              setDualSpAl(false);
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Header;
