import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react'
import CloseButton from 'react-bootstrap/CloseButton'
import { myContext } from '../../App';
// import axios from 'axios';
// import { api } from '../../assets/API/api';



const TtrialBox = ({ days }) => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [show, setShow] = useState(true);
  const [trial, setTrial] = st.trial;
  // const [points, setPoints] = useState();

  // console.log('trial status=', trial);


  useEffect(() => {

    let timer = setTimeout(() => {
      setShow(false);
    }, 5000)
    return () => { clearTimeout(timer) }
  }, [])

  return (
    <>
      {
        show ?
          <div className='TrialBoxCover'>
            {days < 5 && <div><span>{(5 - days)} Days left for your trial.</span> You are currently using the free trial. Upgrade to unveil all of the amazing benefits.</div>}
            {days === 5 && <div>Last Day of your trial.Please upgrade to unveil all of the amazing benefits.</div>}

            {/* <CloseButton /> */}
          </div>
          : null
      }
    </>
  )
}

export default TtrialBox