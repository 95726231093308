import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

const myStyle = {
  textAlign: "center",
  fontWeight: "500",
};

export default function Graph({
  myBalance,
  usedPoints,
  tr,
  trial,
  activePlans,
}) {
  const temp = getSessionStorage();
  return (
    <div className="MyActivityAreaCoverTwoBoxInnerRight">
      <p
        className="head"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span>
          {trial
            ? "Trial Search Consumption"
            : activePlans?.length && activePlans[0]?.no_of_users > 1
            ? temp?.userType === "enterprise-user"
              ? `${temp?.companyName}${
                  temp?.companyName?.at(-1)?.toLowerCase() === "s" ? "'" : "'s"
                } Consumption`
              : "Team's Consumption"
            : "My Search Consumption"}
        </span>

        <span
          style={{
            color: "green",
            fontWeight: "500",
            fontSize: "0.7rem",
            marginTop: "7px",
            fontStyle: "italic",
          }}
        >
          {tr?.length ? (
            <>
              {commaInNumber(
                tr[0]?.no_of_searches_with_coupon || tr[0]?.no_of_searches
              )}{" "}
              searches added
            </>
          ) : null}
        </span>
      </p>
      {/* <p><img src={image_consumption} className="" loading="lazy" alt="" /></p> */}
      {/* <ResponsiveContainer width="100%" height="100%"> */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "35% 60%",
          justifyContent: "center",
          gap: "5px",
          borderRadius: "1rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...myStyle,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                backgroundColor: "white",
                width: "16px",
                height: "16px",
                display: "flex",
                marginRight: "10px",
              }}
            ></span>
            Searches Purchased
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600",
              marginBottom: "16px",
            }}
          >
            {commaInNumber(
              parseInt(myBalance?.subscription_balance) +
                parseInt(myBalance?.addon_balance) +
                parseInt(usedPoints || 0) || 0
            )}
          </div>
          <div
            style={{
              ...myStyle,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                backgroundColor: "#0EA5D4",
                width: "16px",
                height: "16px",
                display: "flex",
                marginRight: "10px",
              }}
            ></span>
            Searches Consumed
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600",
              marginBottom: "16px",
            }}
          >
            {commaInNumber(usedPoints || 0)}
          </div>
          <div
            style={{
              ...myStyle,
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                backgroundColor: "#DDDDDD",
                width: "16px",
                height: "16px",
                display: "flex",
                marginRight: "10px",
              }}
            ></span>
            Searches Remaining
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600",
              marginBottom: "20px",
              color: "#EB4C4B",
            }}
          >
            {commaInNumber(
              myBalance?.subscription_balance + myBalance?.addon_balance || 0
            )}
          </div>
        </div>
        <div>
          <PieChart
            data={[
              {
                title: "Searches Consumed",
                value: parseInt(usedPoints) || 0,
                color: "#0EA5D4",
              },
              {
                title: "Searches Remaining",
                value:
                  myBalance?.subscription_balance + myBalance?.addon_balance ||
                  0,
                color: "#DDDDDD",
              },
            ]}
            viewBoxSize={[100, 100]}
          />
        </div>
      </div>
      {/* </ResponsiveContainer> */}
    </div>
  );
}
