import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { myContext } from "../../App";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";
import CameraFill from "../../assets/images/CameraFill.png";

const UploadProfileImage = ({ profile, user_id, personal, enterprise }) => {
  let cloudinaryRef = useRef();
  let widgetRef = useRef();
  const temp = getSessionStorage();
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  if (personal) {
    var [profileImage, setProfileImage] = profile;
  }
  // console.log('user-data', userInfo);

  useEffect(() => {
    if (window.cloudinary) {
      cloudinaryRef.current = window.cloudinary;
      widgetRef.current = cloudinaryRef?.current.createUploadWidget(
        {
          cloudName: "dvdbj2tr9",
          uploadPreset: "Upskill-Talent",
          cropping: true,
          multiple: false,
          maxImageFileSize: 500000,
        },
        async (error, success) => {
          if (error) {
            console.log(error);
            return alert("Failed to upload the image.");
          }
          // console.log(success);
          if (success?.event === "success") {
            const profileUrl = success?.info?.url;
            // console.log('profile url', profileUrl);
            if (personal) {
              setProfileImage(profileUrl);
              setUserInfo({
                ...userInfo,
                profileImage: profileUrl,
              });
              // localStorage.setItem("profile", JSON.stringify(profileUrl));
              localStorage.setItem("profile", JSON.stringify({
                ...JSON.parse(localStorage.getItem("profile")),
                profile: profileUrl || '',
              }));
            }
            if (enterprise) {
              localStorage.setItem(
                "profile",
                JSON.stringify({
                  ...JSON.parse(localStorage.getItem("profile")),
                  organisation_logo: profileUrl || '',
                })
              );
            }
            await axios
              .post(`${api}/profile/uploadImage?tokenMail=${temp?.email}&enterprise_logo=${enterprise ? true : false}`, {
                user_id: user_id,
                profileImageUrl: profileUrl,
              })
              .then((res) => {
                // console.log(res.data);
                // alert("Picture uploaded successfully!")
              })
              .catch((err) => { console.error(err); alert('Something went wrong.') });
          }
          // const profileUrl = success?.data?.info?.files[0]?.uploadInfo;
        }
      );
    }
  }, [window.cloudinary]);

  return (
    <>
      <Button
        style={{
          width: !personal && "100%",
          backgroundColor: !personal && "#1ea5d4",
          border: !personal && "1px solid #1ea5d4",
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          boxShadow: "0px 0px 8px grey",
        }}
        onClick={() => {
          widgetRef?.current?.open();
        }}
        className="changeProfile"
      >
        <img src={CameraFill} className="" loading="lazy" alt="" />
        <span>Change {enterprise ? "Organisation" : "Profile"} {enterprise ? "Logo" : "Image"}</span>
      </Button>
    </>
  );
};

export default UploadProfileImage;
