import React, { Fragment, useState } from "react";
import axios from "axios";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LeftMenu from "../LeftMenu/LeftMenu";
import { useEffect } from "react";
import { useContext } from "react";
import { myContext } from "../../App";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { api } from "../../assets/API/api";
// import getActivePlans from '../../customHooks/getActivePlans';
import { useRef } from "react";
// import Loader from '../Loader/loader';
// import { getAddons } from '../../customHooks/getAddons';
// import { useState } from 'react';

// import { useState } from 'react';

export const noOfDays = (joiningDate) => {
  let date1 = new Date(joiningDate);
  let date2 = new Date();

  // To calculate the time difference of two dates
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return parseInt(Difference_In_Days);
};

function Layout(props) {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [activePlans, setActivePlans] = st.activePlans;
  const [trial, setTrial] = st.trial;
  // const [addon, setAddon] = st.addon;
  const [loading, setLoading] = useState(false);
  const [myBalance, setMyBalance] = st.myBalance;
  const [usedPoints, setUsedPoints] = st.usedPoints;
  const [recentSearches, setRecentSearches] = st.recentSearches;
  const [recentFavSearches, setRecentFavSearches] = st.recentFavSearches;
  const [recentTransactions, setRecenttransactions] = st.recentTransactions;
  const [addon, setAddon] = st.addon;
  const [days, setDays] = st.days;

  const temp = getSessionStorage();

  const getUserData = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get(
        `${api}/me/?tokenMail=${temp?.email}&user_id=${temp?.user_id}&email=${temp?.email}`
      );
      // console.log(data);
      const {
        user_data,
        active_plans,
        balance,
        used_points,
        recent_searches,
        recent_fav_searches,
        trial,
        recent_transactions,
        addons,
      } = data;
      // console.log(user_data);

      user_data &&
        setUserInfo({
          ...userInfo,
          id: user_data?.user_id,
          title: user_data?.title || "Mr.",
          email: user_data?.email,
          firstName: user_data?.firstName,
          lastName: user_data?.lastName,
          mobile: user_data?.mobile,
          dob: user_data?.dob,
          companyName: user_data?.companyName,
          yourRole: user_data?.role,
          companyWebsite: user_data?.companyWebsite,
          businessCategories: user_data?.businessCategories,
          address: user_data?.address,
          city: user_data?.city,
          state: user_data?.state,
          zipCode: user_data?.zipCode,
          country: user_data?.country,
          userType: user_data?.userType,
          highestQualification: user_data?.highestQualification,
          oldUser: true,
          ...user_data,
        });

      user_data &&
        localStorage.setItem("user-data", JSON.stringify(user_data));

      active_plans && setActivePlans([active_plans]);
      active_plans &&
        localStorage.setItem("active-plans", JSON.stringify([active_plans]));

      if (trial) {
        setDays(noOfDays(user_data?.date_of_joining));
        setMyBalance({
          subscription_balance: parseInt(balance?.trial_balance) || 0,
          addon_balance: 0,
        });
      } else {
        setMyBalance({
          subscription_balance: parseInt(balance?.subscription_balance) || 0,
          addon_balance: parseInt(balance?.addon_balance) || 0,
        });
      }

      used_points && setUsedPoints(used_points);

      recent_searches && setRecentSearches(recent_searches);

      recent_fav_searches && setRecentFavSearches(recent_fav_searches);

      recent_transactions && setRecenttransactions(recent_transactions);

      addons && setAddon(addons);

      setTrial(trial);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(async () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    if (userInfo?.email) {
      return;
    }
    await getUserData();
  }, []);


  let ref = useRef(null);
  // console.log('user-info', userInfo);
  return (
    <Fragment>
      <div ref={ref} className="content-wrapper ">
        <div className="container-fluid">
          {/* <div className="container red_top_wrapper"> */}

          {/* <div className='red_top'>dsfdasf</div> */}

          <div className="admin_whute_body">
            <div className="admin_left">
              <LeftMenu sideBarActive={props.sideBarActive} />
            </div>
            <div className="admin_right">
              <div className="admin_right_wrapper">
                <Header />
                <div className="innerBodyContainer">{props.children}</div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Layout;
