import React from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../Layout/Layout";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// import print_icon from "../../assets/images/print_icon.png"
import view_details_icon from "../../assets/images/view_details_icon.png";
import trash_red_icon from "../../assets/images/trash_red_icon.png";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import CustomAlertForFavorites from "../PopUps/popUpForFavorite";
import { api } from "../../assets/API/api";
import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";
import getSessionStorage from "../../customHooks/getSessionStorage";
import Loader, { LoaderTwo } from "../Loader/loader";

const FavouriteSearches = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [displayArr, setDisplayArr] = st.displayArr;
  const [searchParams, setSearchParams] = st.searchParams;
  const [history, setHistory] = useState([]);
  const [year, setYear] = useState("All");
  const [input, setInput] = useState("");
  const [search_id, setSearch_id] = st.search_id;
  const [popUp, setPopUp] = useState(false);
  const [arr, setArr] = useState([]);
  const [users, setUsers] = useState([]);
  const [activePlans, setActivePlans] = st.activePlans;
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  // const [sideBarActive, setSideBarActive] = st.sideBar;
  const temp = getSessionStorage();

  let curYear = new Date();

  curYear = parseInt(curYear?.getFullYear());

  const getFavSearchHist = async () => {
    setLoading(true);
    await axios
      .post(`${api}/getSearchHistory?tokenMail=${temp?.email}`, {
        id: userInfo?.id || temp?.user_id,
        fav: 1,
      })
      .then((res) => {
        // console.log(res.data);
        setHistory(res.data);
        setArr(res.data);
      })
      .catch((err) => console.log(err))
      .finally((f) => {
        setLoading(false);
      });
  };

  const getManagerFavSearchHist = async () => {
    setLoading(true);
    await axios
      .post(`${api}/getSearchHistory?tokenMail=${temp?.email}`, {
        allHistory: true,
        fav: 1,
        email: userInfo?.email || temp?.email,
      })
      .then((res) => {
        // console.log(res.data);
        setHistory(res.data);
        setArr(res.data);
      })
      .catch((err) => console.log(err))
      .finally((f) => {
        setLoading(false);
      });
  };

  const getFilterYear = () => {
    let a = parseInt(temp?.date_of_joining?.slice(0, 4));
    let b = curYear;
    for (let i = a; i <= b; i++) {
      filter.push(i);
    }
    setFilter(filter);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);

    getFilterYear();

    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }

    if (activePlans[0]?.no_of_users > 1) {
      getManagerFavSearchHist();
    } else {
      getFavSearchHist();
    }
  }, [activePlans]);

  const handleYearFilter = (e) => {
    e.preventDefault();
    setYear(e.target.value);

    if (e.target.value == "All") {
      setHistory(arr);
      return;
    }
    let val = e.target.value;
    setHistory(arr.filter((e) => e.date.includes(val)));
  };

  const handleChange = (e) => {
    e.preventDefault();
    let val = e.target.value;
    if (val == "") {
      setHistory(arr);
    }
    setHistory(
      arr.filter((ele) => {
        return (
          ele.primary_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.first_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.second_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.third_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.fourth_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.fifth_profession?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.firstName?.toLowerCase().includes(val?.toLowerCase()) ||
          ele.lastName?.toLowerCase().includes(val?.toLowerCase())
        );
      })
    );

    setInput(val);
  };

  let hist = useHistory();

  const view = async (
    primary,
    first,
    second,
    third,
    fourth,
    fifth,
    search_id
  ) => {
    if (!temp?.user_id) {
      return;
    }

    setLoadingData(true);
    await setSearch_id(search_id);
    localStorage.setItem("search-id", JSON.stringify(search_id));

    await setSearchParams({
      primaryProfession: "",
      firstProfession: "",
      secondProfession: "",
      thirdProfession: "",
      fourthProfession: "",
      fifthProfession: "",
    });

    await setSearchParams({
      primaryProfession: primary,
      firstProfession: first,
      secondProfession: second,
      thirdProfession: third,
      fourthProfession: fourth,
      fifthProfession: fifth,
    });

    await localStorage.setItem(
      "search-params",
      JSON.stringify({
        primaryProfession: primary,
        firstProfession: first,
        secondProfession: second,
        thirdProfession: third,
        fourthProfession: fourth,
        fifthProfession: fifth,
      })
    );

    await axios
      .post(`${api}/getResults?tokenMail=${temp?.email}`, {
        primaryProfession: primary,
        firstProfession: first,
        secondProfession: second,
        thirdProfession: third,
        fourthProfession: fourth,
        fifthProfession: fifth,
      })
      .then((res) => {
        // console.log(res.data)
        setDisplayArr(res.data);
        localStorage.setItem("main-array", JSON.stringify(res.data));
        setLoadingData(false);
        hist.push("/SearchResults", hist.location.pathname);
      })
      .catch((err) => console.log(err));
  };

  // console.log(history);
  return (
    <Layout sideBarActive={2}>
      {popUp && (
        <CustomAlertForFavorites
          props={{
            a: [popUp, setPopUp],
            b: [search_id, setSearch_id],
            c: [history, setHistory],
            d: userInfo.id,
            e: [arr, setArr],
          }}
        />
      )}
      {loadingData && <LoaderTwo />}
      <div className="FavouriteSearchesDiv">
        <div className="searchBar">
          <div className="left">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  onChange={handleChange}
                  type="text"
                  placeholder="Search by occupation"
                />
                <Button>Search</Button>
              </Form.Group>
            </Form>
          </div>
          <div className="right">
            <select
              onChange={handleYearFilter}
              className="form-select"
              aria-label="Default select example"
            >
              <option selected value="All">
                All
              </option>
              {filter?.length
                ? filter.map((el) => {
                    return <option value={`${el}`}>{el}</option>;
                  })
                : null}
              {/* <option value="2021">2021</option>
                            <option value="2020">2020</option> */}
            </select>
          </div>
        </div>

        <div
          className="manager_used_points"
          id=""
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="" style={{ padding: "15px 0" }}>
            <h3>{year}</h3>
          </div>
          <div className="new-search-fav">
            <Link to={"/NewSearch"}>
              <Button className="button_shadow" datatype="new-search">
                New Search?
              </Button>{" "}
            </Link>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <ul className="row result_box_ul load_animation">
            {!history.length ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  marginTop: "10%",
                }}
              >
                No Data Found!
              </div>
            ) : (
              history.map((e) => {
                return (
                  <li className="col-lg-6">
                    <div className="result_box">
                      <div className="head">
                        {/* <div className='left'>April 14<sup>th</sup></div> */}
                        <div className="left">{e.date.slice(0, 10)}</div>
                        <div className="right">
                          {/* <Button onClick={() => { removeFav(e.search_id) }}><img src={trash_red_icon} loading='lazy' alt='' /></Button> */}
                          <Button
                            onClick={() => {
                              setSearch_id(e.search_id);
                              setPopUp(true);
                            }}
                          >
                            <img src={trash_red_icon} loading="lazy" alt="" />
                          </Button>
                        </div>
                      </div>
                      <div className="body_part row">
                        <div className="col-sm-6">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>
                              <ul className="contet_part">
                                <li className="heading_text">Primary Search</li>
                                <li>{e.primary_profession}</li>
                              </ul>
                            </div>
                            {activePlans[0]?.no_of_users > 1 ? (
                              <div>
                                <ul className="contet_part">
                                  <li className="heading_text">Searched By</li>
                                  <li>
                                    {e.firstName === temp.firstName
                                      ? "Me"
                                      : e.firstName}{" "}
                                    {e.lastName === temp.lastName
                                      ? null
                                      : e.lastName}
                                  </li>{" "}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <ul className="contet_part">
                            <li className="heading_text">Compared Searches</li>
                            <li>{e.first_profession}</li>
                            <li>{e.second_profession}</li>
                            <li>{e.third_profession}</li>
                            <li>{e.fourth_profession}</li>
                            <li>{e.fifth_profession}</li>
                          </ul>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="button_Row"
                      >
                        <Link to="#">
                          <Button
                            onClick={
                              loadingData
                                ? null
                                : () => {
                                    view(
                                      e.primary_profession,
                                      e.first_profession,
                                      e.second_profession,
                                      e.third_profession,
                                      e.fourth_profession,
                                      e.fifth_profession,
                                      e.search_id
                                    );
                                  }
                            }
                            variant="primary"
                            className="view_details_icon_button"
                          >
                            <img
                              src={view_details_icon}
                              loading="lazy"
                              alt=""
                            />{" "}
                            View Details
                          </Button>
                        </Link>
                        <Button
                          style={{
                            backgroundColor: "#1EA5D4",
                            border: "1px solid #1EA5D4",
                            padding: "7.5px",
                            cursor: "default",
                          }}
                        >
                          Searches Used: {e.points}
                        </Button>
                        {/* <Button variant="primary" className='print_icon_button'>
                                            <img src={print_icon} loading='lazy' alt='' /> Print
                                        </Button> */}
                      </div>
                    </div>
                  </li>
                );
              })
            )}
          </ul>
        )}
      </div>
    </Layout>
  );
};

export default FavouriteSearches;
