import React from "react";
// import { useContext } from "react";
// import { useState } from "react";
// import { useEffect } from "react";
// import { useRef } from "react";
import TableauReport from "tableau-react";
// import { myContext } from "../../App";
import "../Subscription/style.css";
import BackArrow from "../backArrow/backArrow";

// const { tableau } = window;

const options = {
  marginTop: 0,
  height: 1280,
  width: 1920,
  hideTabs: false,
  // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
  // All other vizCreate options are supported here, too
  // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
};

const filters = {
  Sizes: ["Small", "Medium"],
};

const parameters = {
  // Occupation: 'Aerospace Engineers, Actuary'
  // Param2: 'Software'
};

// var filters = {}

export default function AdminTableau() {
  // const st = useContext(myContext);
  // const [searchParams, setSearchParams] = st.searchParams;
  // const [arrOfProfession, setArrOfProfession] = useState([]);

  // const ref = useRef(null);
  // const url = 'https://prod-useast-a.online.tableau.com/t/upskilltalent/views/AdminDashboard/TrafficDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link';
  const url =
    "https://prod-useast-a.online.tableau.com/t/upskilltalent/views/AdminDashboard/AdminDashboard";
  // const url ="https://public.tableau.com/javascripts/api/viz_v1.js";

  // const { primaryProfession, firstProfession, secondProfession, thirdProfession, fourthProfession, fifthProfession } = searchParams;
  // console.log(primaryProfession, firstProfession, secondProfession);

  // const filters = {
  //     Occupation: [primaryProfession, firstProfession, secondProfession, thirdProfession, fourthProfession, fifthProfession],
  //     Sizes: ['Small', 'Medium']
  // };

  // console.log(filters);

  // const initViz = () => {
  //     tableau.Viz(ref.current, url, {
  //         width: "100%",
  //         height:"80vh"

  //     })
  // }

  // useEffect(() => {
  //     initViz();
  // },[])

  return (
    // <div style={{textAlign:"center"}} ref={ref}/>
    // <div style={{textAlign:"center", top:"0",bottom:"0",left:"0",right:"0", padding:"1%",paddingTop:"-10%"}}>
    <>
      <span style={{ padding: "1rem 0 0 1rem", position: "sticky", top: "0" }}>
        <BackArrow back={"/DashboardOldUser"} />
      </span>
      <div id="tableauId" style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="load_animation"
          style={{ textAlign: "center", overflow: "auto" }}
        >
          <TableauReport
            url={url}
            filters={filters}
            parameters={parameters}
            options={options} // vizCreate options
            // Overwrite default query params
            // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
            query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes&:jsdebug=false"
          />
        </div>
      </div>
    </>
  );
}
