import React from "react";
import { useEffect } from "react";
import Layout from "../Layout/Layout";


export default function FailedPayment() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <Layout>
            <div style={{ display: "flex", justifyContent: "center", margin: "15%", fontWeight: "600", color: "red" }}>Oops! Payment failed. Please Try Again!</div>
        </Layout>
    )
}