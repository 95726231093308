import React from "react";
import Layout from "../Layout/Layout";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
// import Pagination from 'react-bootstrap/Pagination';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { myContext } from "../../App";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import { useState } from "react";
import "../Subscription/style.css";
import getSessionStorage from "../../customHooks/getSessionStorage";
import Loader from "../Loader/loader";

let sty = { textAlign: "center" };
var arr = [];

export const commaInNumber = (x) => {
  if (!x) {
    return "0";
  }
  let sal = x.toString();
  // console.log(sal);
  let salArr;
  if (sal?.includes(".")) {
    salArr = sal?.split(".");
    sal = salArr[0];
  }
  // console.log(salArr);
  let newSal = "";
  let c = 0;
  for (let i = sal.length - 1; i >= 0; i--) {
    c++;
    if (c % 3 === 0 && i !== 0) {
      newSal += sal[i];
      newSal += ",";
    } else newSal += sal[i];
  }
  let tempSal = newSal;
  newSal = "";
  for (let i = tempSal.length - 1; i >= 0; i--) {
    newSal += tempSal[i];
  }

  if (salArr?.length) {
    return `${newSal}.${salArr[1]}`;
  } else {
    return newSal;
  }
};

const TransactionHistory = () => {
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [authenticate, setAuthenticate] = st.authentication;
  const [history, setHistory] = useState([]);
  const [noOfArtiles, setNoOfArtiles] = useState("10");
  const [pageNo, setPageNo] = useState(1);
  const [input, setInput] = useState("");
  const [year, setYear] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const temp = getSessionStorage();

  let curYear = new Date();

  curYear = parseInt(curYear?.getFullYear());

  const getFilterYear = () => {
    let a = parseInt(temp?.date_of_joining?.slice(0, 4));
    let b = curYear;
    for (let i = a; i <= b; i++) {
      filter.push(i);
    }
    setFilter(filter);
  };

  const getTransactionHistory = async () => {
    setLoading(true);
    await axios
      .post(`${api}/transactionHistory?tokenMail=${temp?.email}`, {
        user_id: userInfo?.id || temp?.user_id,
      })
      .then((res) => {
        // console.log(res.data);
        if (!res.data?.length) {
          console.log("no transaction history found !");
        } else {
          // console.log(res.data);
          setHistory(res.data);
          arr = res.data;
        }
      })
      .catch((err) => console.error(err))
      .finally((f) => setLoading(false));
  };

  useEffect(async () => {
    setLoading(true);

    window.scrollTo(0, 0);

    getFilterYear();

    if (!temp?.user_id || temp?.userType === "Company Head") {
      return;
    }

    getTransactionHistory();
  }, []);

  const handleYearFilter = (e) => {
    e.preventDefault();
    setYear(e.target.value);

    if (e.target.value == "all") {
      setHistory(arr);
      return;
    }
    let val = e.target.value;
    setHistory(arr.filter((e) => e.purchase_date?.includes(val)));
  };

  const handleClick = (e) => {
    e.preventDefault();
    setHistory(
      arr.filter((ele) =>
        ele.purchase_type?.toLowerCase().includes(input?.toLowerCase())
      )
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name == "select") {
      setNoOfArtiles(e.target.value);
      setPageNo(1);
      return;
    }

    setInput(e.target.value);
    let value = e.target.value;

    if (value == "") {
      setHistory(arr);
    }
    setHistory(
      arr.filter(
        (ele) =>
          ele.purchase_type?.toLowerCase().includes(value.toLowerCase()) ||
          ele.plan_name?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const next = (e) => {
    e.preventDefault();

    if (pageNo > history?.length / noOfArtiles) {
      return;
    }
    setPageNo((p) => p + 1);
  };

  const prev = (e) => {
    e.preventDefault();

    if (pageNo <= 1) {
      return;
    }
    setPageNo((p) => p - 1);
  };

  return (
    <Layout sideBarActive={5}>
      <div className="box_with_shadow_padding mb-5">
        <div className="searchBar">
          <div className="left">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  onChange={handleChange}
                  type="text"
                  placeholder="Search by keyword"
                />
                <Button onClick={handleClick}>Search</Button>
              </Form.Group>
            </Form>
          </div>
          <div className="right">
            <select
              onChange={handleYearFilter}
              className="form-select"
              aria-label="Default select example"
            >
              <option selected value="all">
                All
              </option>
              {filter?.length
                ? filter.map((el) => {
                    return <option value={`${el}`}>{el}</option>;
                  })
                : null}
            </select>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="bodyPart load_animation">
            <div className="table ">
              <Table striped className="colorTable" responsive>
                <thead>
                  <tr>
                    <th className="blue_th3 TransactionHistoryDate">
                      {" "}
                      Date & Time
                    </th>
                    <th className="blue_th3" style={sty}>
                      Purchase Type
                    </th>
                    <th className="blue_th3" style={sty}>
                      Length of Plan
                    </th>
                    <th className="blue_th3" style={sty}>
                      Duration
                    </th>
                    <th className="blue_th3" style={sty}>
                      No. of Searches
                    </th>
                    <th className="blue_th3" style={sty}>
                      Expires on
                    </th>
                    <th className="blue_th3" style={sty}>
                      Amount({history[0]?.cur_code || "USD"})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {history?.length
                    ? history?.map((el, idx) => {
                        return (
                          idx >= noOfArtiles * (pageNo - 1) &&
                          idx < noOfArtiles * pageNo && (
                            <tr>
                              {/* <td className='normal_text'><span className='red_text'><FontAwesomeIcon icon={faCalendarAlt} /></span> {el.purchase_date} | <span className='greenText'><FontAwesomeIcon icon={faClock} /></span></td> */}
                              <td className="normal_text">
                                <span className="red_text">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>{" "}
                                {el.purchase_date} |{" "}
                                <span className="greenText">
                                  <FontAwesomeIcon
                                    style={{ marginRight: "3px" }}
                                    icon={faClock}
                                  />
                                </span>
                                {el?.purchase_time}
                              </td>
                              <td className="greenText bold_text" style={sty}>
                                {el.plan_name}
                              </td>
                              <td className="" style={sty}>
                                {el.duration}
                              </td>
                              <td className="" style={sty}>
                                {el.duration}
                              </td>
                              <td style={sty} className="">
                                {commaInNumber(
                                  el?.no_of_searches_with_coupon ||
                                    el?.no_of_searches
                                )}
                              </td>
                              <td className="">
                                <span className="red_text">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                {el.expiry_date}
                              </td>
                              <td style={sty} className="">
                                {commaInNumber(
                                  parseFloat(el.amount)?.toFixed(2)?.toString()
                                )}
                              </td>
                            </tr>
                          )
                        );
                      })
                    : null}
                </tbody>
              </Table>
              {!history?.length && (
                <div style={{ textAlign: "center", fontWeight: "600" }}>
                  No Records Found!
                </div>
              )}
            </div>
          </div>
        )}

        {history?.length > 0 && (
          <div className="bottomPart">
            <div className="left">
              <span>Showing </span>.
              <select
                style={{ width: "80px" }}
                onChange={handleChange}
                name="select"
                className="form-select"
                aria-label="Default select example"
              >
                <option selected value={10}>
                  10
                </option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <span>of {history.length}</span>
            </div>
            <div className="right">
              <nav aria-label="...">
                <ul class="pagination">
                  <li
                    name="prev"
                    onClick={prev}
                    style={{ cursor: "pointer" }}
                    class={
                      pageNo <= 1
                        ? "page-item page-link disabled"
                        : "page-item page-link"
                    }
                  >
                    Previous
                    {/* <span class="page-link">Previous</span> */}
                  </li>
                  <li class="page-item ">
                    <a
                      onClick={() => {
                        setPageNo(pageNo <= 1 ? pageNo : pageNo - 1);
                      }}
                      class="page-link"
                      href="#"
                    >
                      {pageNo > 1 ? pageNo - 1 : null}
                    </a>
                  </li>
                  <li class="page-item active" aria-current="page">
                    <span
                      onClick={() => {
                        setPageNo(pageNo);
                      }}
                      class="page-link"
                    >
                      {pageNo}
                    </span>
                  </li>
                  <li class="page-item">
                    <a
                      onClick={() => {
                        setPageNo(
                          pageNo > history.length / noOfArtiles
                            ? pageNo
                            : pageNo + 1
                        );
                      }}
                      class="page-link"
                      href="#"
                    >
                      {pageNo + 1}
                    </a>
                  </li>
                  <li
                    name="next"
                    onClick={next}
                    style={{ cursor: "pointer" }}
                    class="page-item page-link"
                  >
                    Next
                    {/* <a class="page-link" href="#">Next</a> */}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TransactionHistory;
