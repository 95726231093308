import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
// import { useRef } from "react";
import TableauReport from "tableau-react";
import { myContext } from "../../App";
// import Layout from "../Layout/Layout";
import "./style.css";

import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import printer_icon from "../../assets/images/printer_icon.png";
import "../Subscription/style.css";

// const { tableau } = window;

const options = {
  marginTop: 0,
  height: 600,
  width: 1200,
  hideTabs: false,
  // added interval support - an integer can be passed as milliseconds in the options object and refreshDataAsync() will refresh the data automatically on your preferred interval.
  // All other vizCreate options are supported here, too
  // They are listed here: https://help.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#vizcreateoptions_record
};

// const filters = {
//     // Occupation: ['Aerospace Engineers', 'Actuary', 'Advertising Manager'],
//     Occupation: arrOfProfession,
//     Sizes: ['Small', 'Medium']
// };

const parameters = {
  // Occupation: 'Aerospace Engineers, Actuary'
  // Param2: 'Software'
};

// var filters = {}

export default function Tableau() {
  const st = useContext(myContext);
  const [searchParams, setSearchParams] = st.searchParams;
  // const [arrOfProfession, setArrOfProfession] = useState([]);
  const [show, setShow] = useState(false);
  let temp = JSON.parse(localStorage.getItem("search-params"));
  const {
    primaryProfession,
    firstProfession,
    secondProfession,
    thirdProfession,
    fourthProfession,
    fifthProfession,
  } = searchParams;

  let hist = useHistory();

  const handleClose = () => {
    setShow(false);
    hist.push("/SearchResults");
  };

  useEffect(() => {
    if (!(temp?.primaryProfession || primaryProfession)) {
      return;
    }
    setShow(true);
  }, [temp, setSearchParams]);

  // const ref = useRef(null);
  const url =
    "https://public.tableau.com/views/Illuminatedashboard/AlternateTitleDashboard?:language=en-US&:display_count=n&:origin=viz_share_link";
  // const url ="https://public.tableau.com/javascripts/api/viz_v1.js";

  // console.log(primaryProfession, firstProfession, secondProfession);

  const filters = {
    Occupation: [
      primaryProfession || temp.primaryProfession,
      firstProfession || temp.firstProfession,
      secondProfession || temp.secondProfession,
      thirdProfession || temp.thirdProfession,
      fourthProfession || temp.fourthProfession,
      fifthProfession || temp.fifthProfession,
    ],
    Sizes: ["Small", "Medium"],
  };

  // console.log(filters);

  // const initViz = () => {
  //     tableau.Viz(ref.current, url, {
  //         width: "100%",
  //         height:"80vh"

  //     })
  // }

  // useEffect(() => {
  //     initViz();
  // },[])

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width:"1200px"
        }}
      >
        <iframe
          height={"600px"}
          width={"100%"}
          src="https://public.tableau.com/views/Illuminatedashboard/AlternateTitleDashboard?:language=en-US&:display_count=n&:origin=viz_share_link&:iframeSizedToWindow=true&:embed=y&:toolbar=no&:showAppBanner=false&:display_count=no&:showVizHome=no&:device=desktop"
        />
      </div> */}
      {/* // <div style={{textAlign:"center"}} ref={ref}/>
        // <div style={{textAlign:"center", top:"0",bottom:"0",left:"0",right:"0", padding:"1%",paddingTop:"-10%"}}> */}
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        className="coupon_modal load_animation_tableau"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title>Dashboard</Modal.Title>
          <div>
            <span style={{ color: "blue", fontSize: "15px" }}>
              Primary Profession:{" "}
            </span>
            {searchParams.primaryProfession || temp.primaryProfession}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            id="tableauId"
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div style={{ textAlign: "center", overflow: "auto" }}>
              <TableauReport
                url={url}
                filters={filters}
                parameters={parameters}
                options={options} // vizCreate options
                // Overwrite default query params
                // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
                query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes&:jsdebug=false"
              />
              <div
                onClick={() => {
                  window.print();
                }}
                className="printModal"
              >
                {" "}
                <Button className="print_button_orange">
                  <img src={printer_icon} loading="lazy" alt="" /> Print
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
