import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Layout from "../Layout/Layout";
import Loader from "../Loader/loader";

import axios from "axios";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBan,
  faEye,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../NoDataFound/noDataFound";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

let headSt = {
  backgroundColor: "#0F4C62",
  color: "white",
};

let sty = { fontWeight: "600" };
let arr = [];

export default function EnterpriseControl() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    organisation: "",
  });
  const [showPlans, setShowPlans] = useState(false);
  const [newPlan, setnewPlan] = useState(false);
  const [plan, setPlan] = useState({
    plan_id: "",
    plan_name: "",
    start_date: new Date().toJSON().slice(0, 10),
    expiry_date: "",
    rate: "",
    no_of_users: "",
    no_of_searches: "",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [enterprisePlans, setEnterprisePlans] = useState([]);
  const [editPlan, setEditPlan] = useState(false);
  const temp = getSessionStorage();

  const handleClose = () => {
    setEditPlan(false);
    setShowPlans(false);
    setShow(false);
    setSelectedUser(null);
    setnewPlan(false);
    setForm({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      organisation: "",
    });
    setPlan({
      plan_id: "",
      plan_name: "",
      start_date: new Date().toJSON().slice(0, 10),
      expiry_date: "",
      rate: "",
      no_of_users: "",
      no_of_searches: "",
    });
    setLoading(false);
    setEnterprisePlans([]);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    setForm({
      ...form,
      [name]: val,
    });
  };

  const planChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    if (name === "start_date") {
      return;
    }

    if (
      name === "rate" ||
      name === "no_of_users" ||
      name === "no_of_searches"
    ) {
      if (isNaN(val)) {
        return;
      }
    }

    setPlan({
      ...plan,
      [name]: val,
    });
  };

  const addUser = async (e) => {
    e.preventDefault();

    if (!temp?.user_id) {
      return;
    }

    if (
      !(
        form?.email &&
        form?.password &&
        form?.organisation &&
        form?.firstName &&
        form?.lastName
      )
    ) {
      return alert("All fields are mandatory!");
    }
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${api}/create-enterprise-account/?tokenMail=${temp?.email}`,
        form
      );
      await getAllUsers();
      handleClose();
      setLoading(false);
      return alert("New Enterprise Account Created!");
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!");
    }
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${api}/get-enterprise-users/?tokenMail=${temp?.email}`
      );
      setUsers(data);
      arr = data;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const addPlan = async () => {
    if (
      !(
        selectedUser &&
        plan?.plan_name &&
        plan?.rate &&
        plan?.expiry_date &&
        plan?.no_of_searches &&
        plan?.no_of_users
      )
    ) {
      return alert("Please fill all the fields!");
    }
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${api}/add-enterprise-plan/?tokenMail=${temp?.email}`,
        {
          user_id: selectedUser?.user_id,
          ...plan,
          enterprise_admin_name: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
          email: selectedUser?.email,
          organisation: selectedUser?.companyName,
        }
      );
      setLoading(false);
      setnewPlan((p) => !p);
      getEnterprisePlan(selectedUser?.user_id);
      setEditPlan(false);
      return alert("New Enterprise Plan added successfully.");
    } catch (err) {
      setLoading(false);
      return alert("Something went wrong.");
    }
  };

  const getEnterprisePlan = async (user_id) => {
    if (!user_id) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${api}/get-enterprise-plan/?tokenMail=${temp?.email}&user_id=${user_id}`
      );
      // console.log(data);
      setEnterprisePlans(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert("Something wnet wrong.");
    }
  };

  const blockUser = (el) => {
    if (!temp?.user_id) {
      return;
    }

    let ok = window.confirm(
      `Are you sure to ${el?.block ? "Unblock" : "Block"} this user?`
    );

    if (!ok) {
      return;
    }

    setLoading(true);
    axios
      .post(`${api}/blockUser?tokenMail=${temp?.email}`, { id: el.user_id })
      .then(async (res) => {
        getAllUsers();
        // console.log(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    const { id, firstName, lastName, mobile, email, password, organisation } =
      form;
    try {
      if (
        !(
          id &&
          firstName &&
          lastName &&
          mobile &&
          email &&
          password &&
          organisation
        )
      ) {
        return alert("Please fill all the fields!");
      }
      setLoading(true);
      await axios.post(
        `${api}/update-enterprise-user/?tokenMail=${temp?.email}`,
        { user_id: form?.id, ...form }
      );
      setLoading(false);
      handleClose();
      getAllUsers();
      return alert("User details updated successfully.");
    } catch (err) {
      console.log(err);
      setLoading(false);
      return alert(err?.response?.data);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    if (!val) {
      return setUsers(arr);
    }

    if (name === "country-filter") {
      return setUsers(
        arr.filter((e) =>
          e.country?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    }

    if (name === "organisation-filter") {
      return setUsers(
        arr.filter((e) =>
          e.companyName?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    }

    if (name === "email-filter") {
      return setUsers(
        arr.filter((e) => e.email?.toLowerCase()?.includes(val?.toLowerCase()))
      );
    }

    if (name === "name-filter") {
      return setUsers(
        arr.filter((e) =>
          (e.firstName + " " + e.lastName)
            ?.toLowerCase()
            ?.includes(val?.toLowerCase())
        )
      );
    }
  };

  const updateEnterprisePlan = async () => {
    if (
      !(
        plan?.plan_id &&
        selectedUser &&
        plan?.plan_name &&
        plan?.rate &&
        plan?.expiry_date &&
        plan?.no_of_searches &&
        plan?.no_of_users
      )
    ) {
      return alert("All fields are mandatory!");
    }
    try {
      setLoading(true);
      await axios.post(
        `${api}/update-enterprise-plan/?tokenMail=${temp?.email}`,
        { ...plan, user_id: selectedUser?.user_id }
      );
      alert("Plan updated successfully!");
      setLoading(false);
      getEnterprisePlan(selectedUser?.user_id);
      setEditPlan(false);
      setnewPlan(false);
    } catch (err) {
      alert(err?.response?.data);
    }
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType !== "Company Head") {
      return;
    }
    getAllUsers();
  }, []);

  // console.log(plan);
  return (
    <Layout sideBarActive={15}>
      <div className="box_with_shadow_padding load_animation">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px",
            fontWeight: "600",
            fontSize: "1rem",
          }}
        >
          <div>Filter Options</div>
          {/* <div>
            Filter by:
            <span>
              <select>
                <option>Email</option>
                <option>Expiry Date</option>
              </select>
            </span>
          </div> */}
        </div>
        <div className="filter_admin" style={{ margin: "5px" }}>
          <div>
            <div>Email:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="email-filter"
              onChange={handleFilter}
              placeholder="filter by email"
              type={"text"}
            />
          </div>
          <div>
            <div>Name:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="name-filter"
              onChange={handleFilter}
              placeholder="filter by user's name"
              type={"text"}
            />
          </div>
          <div>
            <div>Country:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="country-filter"
              onChange={handleFilter}
              placeholder="filter by country name"
              type={"text"}
            />
          </div>
          <div>
            <div>Organisation:</div>
            <input
              style={{ paddingLeft: "3px", borderRadius: "5px" }}
              name="organisation-filter"
              onChange={handleFilter}
              placeholder="filter by organisation name"
              type={"text"}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "5px",
            padding: "5px",
          }}
        >
          <h1 style={{ fontWeight: "600", fontSize: "1rem" }}>
            List of Enterprise Administrator
          </h1>
          <Button
            onClick={() => {
              setShow(true);
            }}
            // style={{ backgroundColor: "#0F4C62", border: 0, fontWeight: "600" }}
            className="add-enterprise-user-btn"
          >
            Add User
          </Button>
        </div>
        <div
          style={{ boxShadow: "0 0 0" }}
          className="scrollableTabel autoHeight "
        >
          <Table
            className="user-list-table MyActivityTable2"
            style={{ minWidth: "100%" }}
            striped
            responsive1
          >
            <thead>
              <tr>
                <th style={headSt}>Name</th>
                <th style={headSt}>Email</th>
                <th style={{ ...headSt, textAlign: "center" }}>Subordinates</th>
                <th style={headSt}>Organisation Name</th>
                <th style={{ ...headSt, textAlign: "center" }}>Expires on</th>
                <th style={{ ...headSt, textAlign: "center" }}>Status</th>
                <th style={{ ...headSt, textAlign: "center" }}>
                  Enterprise Plans
                </th>
                <th style={{ ...headSt, textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {users?.length > 0
                ? users?.map((el) => {
                    return (
                      <tr style={{ boxShadow: "0 0 0" }}>
                        <td>
                          {el?.firstName || "NA"} {el?.lastName}
                        </td>
                        <td>{el?.email}</td>
                        <td style={{ textAlign: "center" }}>
                          {el?.subordinates}
                        </td>
                        <td>{el?.companyName}</td>

                        <td style={{ textAlign: "center" }}>
                          {el?.expiry_date || "no plan"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className="status"
                            datatype={
                              el?.status === "ACTIVE" ? "active" : "inactive"
                            }
                          >
                            {el?.status}
                          </span>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              onClick={() => {
                                setSelectedUser(el);
                                setShowPlans(true);
                                getEnterprisePlan(el?.user_id);
                              }}
                              style={{ cursor: "pointer" }}
                              icon={faEye}
                              size="2x"
                              color="#0F4C62"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <>
                              <FontAwesomeIcon
                                onClick={() => {
                                  setSelectedUser(el);
                                  setForm({
                                    id: el?.user_id,
                                    firstName: el?.firstName,
                                    lastName: el?.lastName,
                                    email: el?.email,
                                    password: el?.password,
                                    mobile: el?.mobile,
                                    organisation: el?.companyName,
                                  });
                                  setShow(true);
                                }}
                                style={{ cursor: "pointer" }}
                                icon={faPenSquare}
                                size="2x"
                                color="#0F4C62"
                              />
                            </>
                            <>
                              <FontAwesomeIcon
                                onClick={() => {
                                  blockUser(el);
                                }}
                                style={{ cursor: "pointer" }}
                                icon={faBan}
                                size="2x"
                                color={el?.block ? "green" : "crimson"}
                              />
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  marginTop: "4px",
                                  color: el?.block ? "green" : "crimson",
                                }}
                              >
                                {el?.block ? "Unblock" : "Block"}
                              </span>
                            </>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </div>
        {loading && !showPlans && !users?.length && <Loader />}
        {!users?.length && !loading && <NoDataFound />}
      </div>

      <Modal centered show={show} onHide={handleClose} className="coupon_modal">
        {/* <Modal.Header style={{ backgroundColor: "#0F4C62" }}>
          <Modal.Title style={{ color: "white" }}>
            Add Enterprise User
          </Modal.Title>centered show={show} onHide={handleClose} className="coupon_modal"
        </Modal.Header> */}
        <Modal.Body style={{ padding: "0px" }}>
          <div
            style={{
              backgroundColor: "#0F4C62",
              color: "white",
              textAlign: "center",
              padding: "8px",
              fontWeight: "600",
              borderRadius: "3px",
            }}
          >
            {selectedUser
              ? "Update Enterprise Administrator"
              : "Add Enterprise Administrator"}
          </div>
          <Form>
            <div
              className="planCreate"
              style={{ marginBottom: "0px", padding: "1.2rem" }}
            >
              {/* <h1>User Access Control</h1> */}

              <div className="row">
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="firstName"
                      value={form.firstName}
                      type="text"
                      placeholder="First Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="lastName"
                      value={form.lastName}
                      type="text"
                      placeholder="Last Name"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="email"
                      value={form.email}
                      type="email"
                      placeholder="Enter email of the enterprise user"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Form.Label>Organisation Name</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="organisation"
                      value={form.organisation}
                      type="text"
                      placeholder="Enter name of the organisation"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="mobile"
                      value={form.mobile}
                      type="text"
                      placeholder="Mobile Number"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      name="password"
                      value={form.password}
                      type="text"
                      placeholder="Password"
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group></Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group>
                    <Button
                      disabled={loading}
                      className="add-enterprise-user-btn"
                      datatype="modal"
                      onClick={selectedUser ? updateUser : addUser}
                    >
                      {loading ? (
                        <Loader width={20} color={"white"} />
                      ) : selectedUser ? (
                        "Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* modal for enterprise plans */}

      <Modal
        // centered
        size="lg"
        show={showPlans}
        onHide={handleClose}
        className="enterprise_modal"
        style={{ overflow: "scroll" }}
      >
        <Modal.Body style={{ padding: "10px" }}>
          <div
            style={{
              borderRadius: "0.3rem",
              marginBottom: "5px",
              display: "flex",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
          >
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                border: 0,
                fontWeight: "600",
                cursor: "default",
              }}
            >
              {newPlan ? "Add New Plan" : "List of plans"}
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                !loading && setnewPlan((p) => !p);
                !loading && setEditPlan(false);
                let p = enterprisePlans[0];
                setPlan({
                  ...plan,
                  plan_name: p?.plan_name || "",
                  expiry_date: "",
                  no_of_users: p?.no_of_users || "",
                  rate: "",
                  no_of_searches: "",
                });
              }}
              className="add-enterprise-user-btn"
            >
              {newPlan || editPlan ? (
                <FontAwesomeIcon icon={faArrowLeft} />
              ) : enterprisePlans?.length === 0 ? (
                "Add New Plan"
              ) : (
                "Renew Plan"
              )}
            </Button>
          </div>
          {newPlan || editPlan ? (
            <div>
              <Form>
                <div
                  className="planCreate"
                  style={{ marginBottom: "0px", padding: "10px" }}
                >
                  {/* <h1>User Access Control</h1> */}

                  <div className="row">
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label>Plan Name</Form.Label>
                        <Form.Control
                          onChange={planChange}
                          name="plan_name"
                          value={plan?.plan_name}
                          type="text"
                          placeholder="enter plan name"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label>Price ($)</Form.Label>
                        <Form.Control
                          onChange={planChange}
                          name="rate"
                          value={plan?.rate}
                          type="text"
                          placeholder="price of the plan"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          onChange={planChange}
                          name="start_date"
                          value={plan?.start_date}
                          type="date"
                          placeholder="Start Date"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label>Expiry Date</Form.Label>
                        <Form.Control
                          onChange={planChange}
                          name="expiry_date"
                          value={plan?.expiry_date}
                          type="date"
                          placeholder="Expiry Date"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label>Number Of Users</Form.Label>
                        <Form.Control
                          onChange={planChange}
                          name="no_of_users"
                          value={plan?.no_of_users}
                          type="text"
                          placeholder="number of users"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6">
                      <Form.Group>
                        <Form.Label>
                          Total Number Of Searches For Entire Organization
                        </Form.Label>
                        <Form.Control
                          onChange={planChange}
                          name="no_of_searches"
                          value={plan?.no_of_searches}
                          type="text"
                          placeholder="total number of searches for entire organization"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-12">
                      <Form.Group></Form.Group>
                    </div>
                    <div className="col-lg-12">
                      <Form.Group>
                        <Button
                          disabled={loading}
                          className="add-enterprise-user-btn"
                          datatype="modal"
                          onClick={
                            editPlan
                              ? () => {
                                  updateEnterprisePlan();
                                }
                              : () => addPlan()
                          }
                        >
                          {loading ? (
                            <Loader width={20} color={"white"} />
                          ) : (
                            <>{editPlan ? "Update" : "Add"}</>
                          )}
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <>
              <div
                style={{
                  boxShadow: "0 0 0",
                  marginBottom: "0px",
                  maxHeight: "80vh",
                }}
                className="scrollableTabel autoHeight enterprise-table"
              >
                <Table
                  className="MyActivityTable2"
                  style={{ minWidth: "100%" }}
                  striped
                  responsive1
                >
                  <thead>
                    <tr>
                      <th style={headSt}>Plan Name</th>
                      <th style={{ ...headSt, textAlign: "right" }}>
                        Price ($)
                      </th>
                      <th style={{ ...headSt, textAlign: "center" }}>
                        Purchase Date
                      </th>
                      <th style={{ ...headSt, textAlign: "center" }}>
                        Expiry Date
                      </th>
                      <th style={{ ...headSt, textAlign: "center" }}>
                        Duration
                      </th>
                      <th style={{ ...headSt, textAlign: "center" }}>
                        No Of Users
                      </th>
                      <th style={{ ...headSt, textAlign: "center" }}>
                        No Of Searches
                      </th>
                      <th style={{ ...headSt, textAlign: "center" }}>Status</th>
                      <th style={{ ...headSt, textAlign: "center" }}>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enterprisePlans?.length > 0
                      ? enterprisePlans?.map((el) => {
                          return (
                            <tr style={{ boxShadow: "0 0 0" }}>
                              <td style={sty}>{el?.plan_name}</td>
                              <td style={{ ...sty, textAlign: "right" }}>
                                {commaInNumber(el?.amount?.toFixed(2))}
                              </td>
                              <td style={{ ...sty, textAlign: "center" }}>
                                {el?.purchase_date}
                              </td>
                              <td style={{ ...sty, textAlign: "center" }}>
                                {el?.expiry_date}
                              </td>
                              <td style={{ ...sty, textAlign: "center" }}>
                                {el?.duration}
                              </td>
                              <td style={{ ...sty, textAlign: "center" }}>
                                {commaInNumber(el?.no_of_users)}
                              </td>
                              <td style={{ ...sty, textAlign: "center" }}>
                                {commaInNumber(el?.no_of_searches)}
                              </td>
                              <td style={{ ...sty, textAlign: "center" }}>
                                <span
                                  className="status"
                                  datatype={
                                    el?.status === "ACTIVE"
                                      ? "active"
                                      : "inactive"
                                  }
                                >
                                  {el?.status}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <FontAwesomeIcon
                                  onClick={() => {
                                    setEditPlan(true);
                                    setnewPlan(true);
                                    setPlan({
                                      ...plan,
                                      plan_id: el?.transaction_history_id,
                                      plan_name: el?.plan_name,
                                      rate: el?.amount,
                                      no_of_searches: el?.no_of_searches,
                                      no_of_users: el?.no_of_users,
                                      start_date: el?.purchase_date,
                                      expiry_date: el?.expiry_date,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  icon={faPenSquare}
                                  color="rgb(15, 76, 98)"
                                  size="2x"
                                />
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </div>

              {loading && <Loader />}
              {!enterprisePlans?.length && !loading && <NoDataFound />}
            </>
          )}
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
