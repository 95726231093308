import React from "react";
import { Table, Button, Modal } from "react-bootstrap";
import Layout from "../Layout/Layout";
import Form from "react-bootstrap/Form";
// import { useContext } from "react";
// import { myContext } from "../../App";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";
// import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenSquare,
  faShare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
// import Modal from 'react-bootstrap/esm/Modal'
// import readXlsxFile from 'read-excel-file'
import SendGiftCardInvite from "./GiftCardInvite";
import Loader from "../Loader/loader";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";

const filterStyle = { paddingLeft: "3px", borderRadius: "5px" };
// import './css/style1.css';

let arr = [];

export default function GiftCardControl() {
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [giftCards, setgGiftCards] = useState([]);
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);
  const [giftCardData, setGiftCardData] = useState({
    code: "",
    description: "",
    no_of_searches: "",
    start_date: new Date().toJSON().slice(0, 10),
    expiry_date: "",
    user_limit: "",
  });
  let temp = getSessionStorage();
  const [allEmails, setAllEmails] = useState([]);
  const [sendInvite, setSendInvite] = useState(false);
  const [showGiftCard, setShowGiftCard] = useState(false);
  const [giftCardInfo, setGiftCardInfo] = useState([]);
  const [loadGift, setLoadGift] = useState(false);

  const handleClose = () => {
    setShow(false);
    setGiftCardData({
      code: "",
      description: "",
      no_of_searches: "",
      start_date: new Date().toJSON().slice(0, 10),
      expiry_date: "",
      user_limit: "",
    });
    setSelectedGiftCard(null);
  };

  const handleFilter = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;
    if (val === "") {
      return setgGiftCards(arr);
    }
    if (name === "code")
      return setgGiftCards(
        arr.filter((e) => e?.code?.toLowerCase()?.includes(val?.toLowerCase()))
      );
    if (name === "start-date")
      return setgGiftCards(
        arr.filter((e) =>
          e?.start_date?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    if (name === "expiry-date")
      return setgGiftCards(
        arr.filter((e) =>
          e?.expiry_date?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
  };

  function showHide(el) {
    if (!temp?.user_id) {
      return;
    }

    axios
      .get(
        `${api}/get-all-giftcards/?tokenMail=${temp?.email}&id=${el.id}&visibility=${el.visibility}`
      )
      .then(() => {
        // console.log(res.data);
        getGiftCards(1);
      })
      .catch((err) => alert("Something went wrong!"))
      .finally(() => {
        setLoading(false);
      });
  }

  const deleteGiftCard = (gift) => {
    if (!temp?.user_id) {
      return;
    }
    let ok = window.confirm("Are you sure to delete?");
    if (!ok) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/delete-giftcard/?tokenMail=${temp?.email}`, { id: gift.id })
      .then((res) => {
        getGiftCards(1);
      })
      .catch((err) => {
        alert("Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllGiftCards = async () => {
    setLoading(true);
    await axios
      .get(`${api}/get-all-giftcards/?tokenMail=${temp?.email}&admin=true`)
      .then((res) => {
        // console.log(res.data);
        setgGiftCards(res.data);
        arr = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const getGiftCards = async (page) => {
    setLoading(true);
    await axios
      .get(
        `${api}/get-all-giftcards/?tokenMail=${temp?.email}&admin=true&pageNo=${page}`
      )
      .then((res) => {
        setgGiftCards(res.data);
        arr = res.data;
      })
      .catch((e) => console.log(e))
      .finally((f) => setLoading(false));
  };

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    if ((name === "no_of_searches" || name === "user_limit") && isNaN(val)) {
      return;
    }

    if (selectedGiftCard) {
      setSelectedGiftCard({
        ...selectedGiftCard,
        [name]: name === "code" ? val.toUpperCase() : val,
      });
      return;
    }

    setGiftCardData({
      ...giftCardData,
      [name]: name === "code" ? val.toUpperCase() : val,
    });
  };

  const addGiftCard = async (e) => {
    e.preventDefault();

    if (!temp?.user_id) {
      return;
    }
    let {
      code,
      description,
      no_of_searches,
      start_date,
      expiry_date,
      user_limit,
    } = giftCardData;
    if (!(code && description && no_of_searches && expiry_date && user_limit)) {
      return alert("Please fill all the fields!");
    }

    if (start_date > expiry_date) {
      return alert("Start date should be smaller than expiry date!");
    }

    setLoading(true);
    await axios
      .post(`${api}/add-giftcard?tokenMail=${temp?.email}`, giftCardData)
      .then((res) => {
        // console.log(res.data);
        getGiftCards(1);
      })
      .catch((err) => alert("Something went wrong!"))
      .finally((f) => setLoading(false));
    handleClose();
  };

  const updateGiftCard = async () => {
    if (!temp?.user_id || !selectedGiftCard) {
      return;
    }

    let {
      code,
      description,
      no_of_searches,
      start_date,
      expiry_date,
      user_limit,
    } = selectedGiftCard;
    if (!(code && description && no_of_searches && expiry_date && user_limit)) {
      return alert("Please fill all the fields!");
    }

    if (start_date > expiry_date) {
      return alert("Start date should be smaller than expiry date!");
    }

    let ok = window.confirm("Are you sure to update?");
    if (!ok) {
      return;
    }

    setLoading(true);
    await axios
      .post(`${api}/update-giftcard?tokenMail=${temp?.email}`, selectedGiftCard)
      .then(async (res) => {
        getGiftCards(1);
      })
      .catch((err) => {
        alert("Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const whoRedeemed = async (code) => {
    if (!temp?.user_id || !code) {
      return;
    }
    try {
      setLoadGift(true);
      const { data } = await axios.get(
        `${api}/who-redeemed-gift-card/?tokenMail=${temp?.email}&code=${code}`
      );
      setGiftCardInfo(data);
      setShowGiftCard(true);
      setLoadGift(false);
    } catch (error) {
      setLoadGift(false);
    }
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType !== "Company Head") {
      return;
    }
    getGiftCards(pageNo);
  }, [pageNo]);

  // console.log(giftCardData);
  console.log(allEmails);
  return (
    <Layout sideBarActive={14}>
      {sendInvite && (
        <SendGiftCardInvite
          emails={[allEmails, setAllEmails]}
          send={[sendInvite, setSendInvite]}
        />
      )}
      <div>
        <h1 style={{ fontWeight: "600", fontSize: "1rem" }}>Filter Options</h1>
        <div className="filter_admin">
          <div>
            <div>Gift Code:</div>
            <input
              style={filterStyle}
              name="code"
              onChange={handleFilter}
              placeholder="Filter using coupon code"
              type={"text"}
            />
          </div>
          <div>
            <div>Start Date:</div>
            <input
              style={filterStyle}
              name="start-date"
              onChange={handleFilter}
              placeholder="yyyy-mm-dd"
              type={"text"}
            />
          </div>
          <div>
            <div>Expiry Date:</div>
            <input
              style={filterStyle}
              name="expiry-date"
              onChange={handleFilter}
              placeholder="yyyy-mm-dd"
              type={"text"}
            />
          </div>
        </div>
        <div
          class=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "8px 0px",
            flexWrap: "wrap",
          }}
        >
          <h3 style={{ fontSize: "1.5rem", fontWeight: "600" }}>
            Illuminate Gift Cards
          </h3>
          <button
            className="button_shadow"
            datatype="send-invite"
            // style={{ backgroundColor: "#0d6efd" }}
            onClick={() => {
              setSendInvite(true);
            }}
          >
            <span>Send Invite</span>
            <span>
              <FontAwesomeIcon icon={faShare} color="white" />
            </span>
          </button>
          <button
            className="button_shadow"
            datatype="orange"
            onClick={() => {
              setShow(true);
            }}
          >
            Add New Gift Card
          </button>
        </div>
        <div className="scrollableTabel autoHeight load_animation">
          {loading ? (
            <Loader />
          ) : (
            <Table style={{ minWidth: "100%" }} striped responsive1>
              <thead>
                <tr>
                  <th>Gift Card Code</th>
                  {/* <th>Heading</th> */}
                  <th>Description</th>
                  <th style={{ textAlign: "center" }}>Redeem Count</th>
                  <th style={{ textAlign: "center" }}>User Limit</th>
                  <th style={{ textAlign: "center" }}>No Of Searches</th>
                  <th>Starts from</th>
                  <th>Expires on</th>
                  {/* <th style={{ textAlign: "center" }}>Show/Hide</th> */}
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {giftCards?.length > 0 &&
                  giftCards.map((el, idx) => {
                    return (
                      <tr>
                        {/* <td>First Coupon</td> */}
                        <td
                          onClick={
                            el?.count === 0 || loadGift
                              ? null
                              : () => {
                                  whoRedeemed(el?.code);
                                }
                          }
                        >
                          <span
                            style={{
                              borderBottom:
                                el?.count === 0 ? null : "2px solid blue",
                              cursor: el?.count === 0 ? "default" : "pointer",
                              color: "orangered",
                            }}
                          >
                            {" "}
                            {el?.code}
                          </span>
                        </td>
                        <td>{el?.description}</td>
                        <td style={{ textAlign: "center" }}>
                          {commaInNumber(el?.count || 0)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {commaInNumber(el?.user_limit)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {commaInNumber(el?.no_of_searches)}
                        </td>
                        <td>{el?.start_date}</td>
                        <td>{el?.expiry_date}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "5px",
                            }}
                          >
                            <div
                              style={{ paddingTop: "4px" }}
                              onClick={
                                loading
                                  ? null
                                  : () => {
                                      showHide(el);
                                    }
                              }
                              id={el?.coupons_table_id}
                              class="disanleEnable"
                            >
                              <input type="checkbox" checked={el?.visibility} />
                              <label for="one">onOff</label>
                            </div>
                            {/* </td> */}
                            {/* <td style={{ textAlign: "center" }}> */}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedGiftCard(giftCards[idx]);
                                setShow(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenSquare}
                                color={"#1ea5d4"}
                                size="2x"
                              />
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={
                                loading
                                  ? null
                                  : () => {
                                      deleteGiftCard(el);
                                    }
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color={"crimson"}
                                size="2x"
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
          {giftCards?.length === 0 && !loading && (
            <h3 style={{ textAlign: "center" }}>No Gift Cards Available</h3>
          )}
        </div>

        {giftCards?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Button
              className="button_shadow"
              onClick={loading ? null : getAllGiftCards}
            >
              Show All Gift Cards
            </Button>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                className="button_shadow"
                onClick={
                  loading
                    ? null
                    : () => {
                        if (pageNo <= 1) {
                          return;
                        }
                        setPageNo((p) => p - 1);
                      }
                }
                style={{ marginRight: "15px" }}
              >
                Prev
              </Button>
              <div style={{ marginRight: "15px", padding: "7px" }}>
                Page No: {pageNo}
              </div>
              <Button
                className="button_shadow"
                onClick={
                  loading
                    ? null
                    : () => {
                        setPageNo((p) => p + 1);
                      }
                }
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>

      <Modal centered show={show} onHide={handleClose} className="coupon_modal">
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>
            {selectedGiftCard ? "Update" : "Create New"} Illuminate Gift Card
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div
            style={{
              textAlign: "center",
              padding: "0 5px",
              fontSize: "12px",
              color: "green",
            }}
          >
            Gift Card always offers{" "}
            <span style={{ fontWeight: "600" }}>Pay By Search</span> units.
          </div>
          {selectedGiftCard ? (
            <Form>
              <div className="planCreate" style={{ margin: "0px" }}>
                {/* <h1>Create New Coupon</h1> */}

                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Gift Code</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="code"
                        value={selectedGiftCard?.code}
                        type="text"
                        placeholder="Gift Code"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>No of Searches</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="no_of_searches"
                        value={selectedGiftCard?.no_of_searches}
                        type="text"
                        placeholder="No of Searches"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>User Limit</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="user_limit"
                        value={selectedGiftCard?.user_limit}
                        type="text"
                        placeholder="User Limit"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>Bonus Searches</Form.Label>
                                    <Form.Control onChange={handleChange} name="bonus-searches" value={coupon.discount} type="text" placeholder="Discount" />
                                </Form.Group>
                            </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="start_date"
                        value={selectedGiftCard?.start_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="expiry_date"
                        value={selectedGiftCard?.expiry_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="description"
                        value={selectedGiftCard?.description}
                        as="textarea"
                        rows={3}
                        placeholder="Coupon Description"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Button
                        onClick={
                          loading
                            ? null
                            : () => updateGiftCard(selectedGiftCard)
                        }
                        style={{ width: "100%" }}
                      >
                        Update
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <Form>
              <div className="planCreate" style={{ margin: "0px" }}>
                {/* <h1>Create New Coupon</h1> */}

                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Gift Code</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="code"
                        value={giftCardData.code}
                        type="text"
                        placeholder="Gift Code"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>No of Searches</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="no_of_searches"
                        value={giftCardData.no_of_searches}
                        type="text"
                        placeholder="No of Searches"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>User Limit</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="user_limit"
                        value={giftCardData.user_limit}
                        type="text"
                        placeholder="User Limit"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="col-lg-6">
                                    <Form.Group>
                                        <Form.Label>Bonus Searches</Form.Label>
                                        <Form.Control onChange={handleChange} name="bonus-searches" value={coupon.discount} type="text" placeholder="Discount" />
                                    </Form.Group>
                                </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="start_date"
                        value={giftCardData.start_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="expiry_date"
                        value={giftCardData.expiry_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="description"
                        value={giftCardData.description}
                        as="textarea"
                        rows={3}
                        placeholder="Coupon Description"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Button
                        className="button_shadow"
                        onClick={loading ? null : addGiftCard}
                        style={{ width: "100%" }}
                      >
                        Add
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        // centered
        show={showGiftCard}
        onHide={() => {
          setShowGiftCard(false);
          setGiftCardInfo([]);
        }}
        className="enterprise_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title
            style={{ color: "red", fontSize: "1rem", fontWeight: "600" }}
          >
            {giftCardInfo[0]?.gift_code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div
            className="scrollableTabel autoHeight load_animation"
            style={{ margin: "0", maxHeight: "80vh" }}
          >
            <Table
              className="user-list-table"
              style={{ minWidth: "100%" }}
              striped
              responsive1
            >
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Name</th>
                  {/* <th>Last Name</th> */}
                  <th style={{ textAlign: "center" }}>Redeem Date</th>
                </tr>
              </thead>
              <tbody>
                {giftCardInfo?.map((el) => {
                  return (
                    <tr>
                      <td>{el?.email}</td>
                      <td>
                        {el?.firstName} {el?.lastName}
                      </td>
                      {/* <td>{el?.lastName}</td> */}
                      <td style={{ textAlign: "center" }}>
                        {el.time_stamp?.slice(0, 10)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={loadGift}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader width={25} />
        </div>
      </Modal>
    </Layout>
  );
}
