import React from "react";
import { Table, Button, Modal } from "react-bootstrap";
import Layout from "../Layout/Layout";
import Form from "react-bootstrap/Form";
// import { useContext } from "react";
// import { myContext } from "../../App";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api } from "../../assets/API/api";
import getSessionStorage from "../../customHooks/getSessionStorage";
// import { RotatingLines } from "react-loader-spinner";
import "../Subscription/style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader/loader";
import { commaInNumber } from "../TransactionHistory/TransactionHistory";
// import Modal from 'react-bootstrap/esm/Modal'

const filterStyle = { paddingLeft: "3px", borderRadius: "5px" };
// import './css/style1.css';

let arr = [];

export default function CouponControl() {
  // const st = useContext(myContext);
  // const [userInfo, setUserInfo] = st.userInfo;
  // const [authenticate, setAuthenticate] = st.authentication;
  const [show, setShow] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    header: "",
    body: "",
    discount: "",
    start_date: new Date().toJSON().slice(0, 10),
    expiry_date: "",
    user_limit: "",
  });
  const temp = getSessionStorage();
  const [loading, setLoading] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [couponInfo, setCouponInfo] = useState([]);
  const [showCoupon, setShowCoupon] = useState(false);
  const [loadCoupon, setLoadCoupon] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCoupon({
      header: "",
      body: "",
      discount: "",
      start_date: new Date().toJSON().slice(0, 10),
      expiry_date: "",
      user_limit: "",
    });
    setSelectedCoupon(null);
  };

  const getAllCoupons = async () => {
    setLoading(true);
    await axios
      .get(`${api}/getAllCoupons/?tokenMail=${temp?.email}&admin=true`)
      .then((res) => {
        // console.log(res.data);
        setCoupons(res.data);
        arr = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const getCoupons = async (page) => {
    setLoading(true);
    await axios
      .get(
        `${api}/getAllCoupons/?tokenMail=${temp?.email}&admin=true&pageNo=${page}`
      )
      .then((res) => {
        // console.log(res.data);
        setCoupons(res.data);
        arr = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  useEffect(() => {
    if (!temp?.user_id || temp?.userType !== "Company Head") {
      return;
    }
    getCoupons(pageNo);
  }, [pageNo]);

  function showHide(el) {
    if (!temp?.user_id) {
      return;
    }

    axios
      .get(
        `${api}/getAllCoupons/?tokenMail=${temp?.email}&id=${el.coupons_table_id}&visibility=${el.visibility}`
      )
      .then(() => {
        // console.log(res.data);
        getCoupons(1);
      })
      .catch((err) => alert("Something went wrong!"))
      .finally(() => {
        setLoading(false);
      });
  }

  function deleteCoupon(coupon) {
    if (!temp?.user_id) {
      return;
    }
    let ok = window.confirm("Are you sure to delete?");
    if (!ok) {
      return;
    }
    setLoading(true);
    axios
      .post(`${api}/delete-coupon?tokenMail=${temp?.email}`, {
        id: coupon.coupons_table_id,
      })
      .then((res) => {
        getCoupons(1);
      })
      .catch((err) => {
        alert("Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;

    if ((name === "discount" || name === "user_limit") && isNaN(val)) {
      return;
    }
    if (selectedCoupon) {
      setSelectedCoupon({
        ...selectedCoupon,
        [name]: name === "header" ? val.toUpperCase() : val,
      });
      return;
    }

    setCoupon({
      ...coupon,
      [name]: name === "header" ? val.toUpperCase() : val,
    });
  };

  const addCoupon = async (e) => {
    e.preventDefault();

    if (!temp?.user_id) {
      return;
    }
    let { header, body, discount, start_date, expiry_date, user_limit } =
      coupon;
    if (!(header && body && discount && expiry_date && user_limit)) {
      return alert("Please fill all the fields!");
    }

    if (start_date > expiry_date) {
      return alert("Start date should be smaller than expiry date!");
    }

    if (parseInt(coupon?.discount) === 100) {
      return alert("Discount cannot be 100%");
    }

    setLoading(true);
    await axios
      .post(`${api}/addCoupon?tokenMail=${temp?.email}`, coupon)
      .then((res) => {
        // console.log(res.data);
        getCoupons(1);
      })
      .catch((err) => alert("Something went wrong!"))
      .finally((f) => setLoading(false));

    handleClose();
  };

  function updateCoupon() {
    if (!temp?.user_id || !selectedCoupon) {
      return;
    }

    let { header, body, discount, start_date, expiry_date, user_limit } =
      selectedCoupon;
    if (!(header && body && discount && expiry_date && user_limit)) {
      return alert("Please fill all the fields!");
    }

    if (start_date > expiry_date) {
      return alert("Start date should be smaller than expiry date!");
    }

    if (parseInt(selectedCoupon?.discount) === 100) {
      return alert("Discount cannot be 100%");
    }

    let ok = window.confirm("Are you sure to update?");
    if (!ok) {
      return;
    }

    setLoading(true);
    axios
      .post(`${api}/update-coupon/?tokenMail=${temp?.email}`, {
        id: selectedCoupon.coupons_table_id,
        ...selectedCoupon,
      })
      .then(async (res) => {
        getCoupons(1);
      })
      .catch((err) => {
        alert("Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  }

  const handleFilter = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;
    if (val === "") {
      return setCoupons(arr);
    }
    if (name === "coupon-code")
      return setCoupons(
        arr.filter((e) =>
          e?.header?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    if (name === "start-date")
      return setCoupons(
        arr.filter((e) =>
          e?.start_date?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    if (name === "expiry-date")
      return setCoupons(
        arr.filter((e) =>
          e?.expiry_date?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
  };

  const whoRedeemed = async (code) => {
    if (!temp?.user_id || !code) {
      return;
    }
    try {
      setLoadCoupon(true);
      const { data } = await axios.get(
        `${api}/who-redeemed-coupon/?tokenMail=${temp?.email}&code=${code}`
      );
      setCouponInfo(data);
      setShowCoupon(true);
      setLoadCoupon(false);
    } catch (error) {
      setLoadCoupon(false);
    }
  };

  // let ref = useRef(null)

  // console.log(arr)
  return (
    <Layout sideBarActive={13}>
      <div>
        <h1 style={{ fontWeight: "600", fontSize: "1rem" }}>Filter Options</h1>
        <div className="filter_admin">
          <div>
            <div>Coupon Code:</div>
            <input
              style={filterStyle}
              name="coupon-code"
              onChange={handleFilter}
              placeholder="Filter using coupon code"
              type={"text"}
            />
          </div>
          <div>
            <div>Start Date:</div>
            <input
              style={filterStyle}
              name="start-date"
              onChange={handleFilter}
              placeholder="yyyy-mm-dd"
              type={"text"}
            />
          </div>
          <div>
            <div>Expiry Date:</div>
            <input
              style={filterStyle}
              name="expiry-date"
              onChange={handleFilter}
              placeholder="yyyy-mm-dd"
              type={"text"}
            />
          </div>
        </div>
        <div class="planHeading">
          <h3>Illuminate Coupons</h3>
          <button
            className="button_shadow"
            // datatype="send-invite"
            onClick={() => {
              setShow(true);
            }}
          >
            Add New Coupon
          </button>
        </div>
        <div className="scrollableTabel autoHeight load_animation">
          {loading ? (
            <Loader />
          ) : (
            <Table style={{ minWidth: "100%" }} striped responsive1>
              <thead>
                <tr>
                  <th>Coupon Code</th>
                  {/* <th>Heading</th> */}
                  <th>Description</th>
                  <th style={{ textAlign: "center" }}>Redeem Count</th>
                  <th style={{ textAlign: "center" }}>User Limit</th>
                  <th>Starts from</th>
                  <th>Expires on</th>
                  <th style={{ textAlign: "center" }}>Change (%)</th>
                  {/* <th style={{ textAlign: "center" }}>Show/Hide</th> */}
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {coupons?.length > 0 &&
                  coupons.map((el, idx) => {
                    return (
                      <tr>
                        {/* <td>First Coupon</td> */}
                        <td
                          onClick={
                            el?.count === 0
                              ? null
                              : () => {
                                  whoRedeemed(el.header);
                                }
                          }
                        >
                          <span
                            style={{
                              borderBottom:
                                el?.count === 0 ? null : "2px solid blue",
                              cursor: el?.count === 0 ? "default" : "pointer",
                              color: "orangered",
                            }}
                          >
                            {" "}
                            {el.header}
                          </span>
                        </td>
                        <td>{el?.body}</td>
                        <td style={{ textAlign: "center" }}>
                          {commaInNumber(el?.count || 0)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {commaInNumber(el?.user_limit || 0)}
                        </td>
                        <td>{el?.start_date}</td>
                        <td>{el?.expiry_date}</td>
                        <td style={{ textAlign: "center" }}>{el?.discount}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "5px",
                            }}
                          >
                            <div
                              style={{ paddingTop: "4px" }}
                              onClick={
                                loading
                                  ? null
                                  : () => {
                                      showHide(el);
                                    }
                              }
                              id={el?.coupons_table_id}
                              class="disanleEnable"
                            >
                              <input type="checkbox" checked={el?.visibility} />
                              <label for="one">onOff</label>
                            </div>
                            {/* </td> */}
                            {/* <td style={{ textAlign: "center" }}> */}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedCoupon(coupons[idx]);
                                setShow(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPenSquare}
                                color={"#1ea5d4"}
                                size="2x"
                              />
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={
                                loading
                                  ? null
                                  : () => {
                                      deleteCoupon(el);
                                    }
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color={"crimson"}
                                size="2x"
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
          {coupons?.length === 0 && !loading && (
            <h3 style={{ textAlign: "center" }}>No Coupons Available</h3>
          )}
        </div>

        {coupons?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Button
              className="button_shadow"
              onClick={loading ? null : getAllCoupons}
            >
              Show All Coupons
            </Button>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                className="button_shadow"
                onClick={
                  loading
                    ? null
                    : () => {
                        if (pageNo <= 1) {
                          return;
                        }
                        setPageNo((p) => p - 1);
                      }
                }
                style={{ marginRight: "15px" }}
              >
                Prev
              </Button>
              <div style={{ marginRight: "15px", padding: "7px" }}>
                Page No: {pageNo}
              </div>
              <Button
                className="button_shadow"
                onClick={
                  loading
                    ? null
                    : () => {
                        setPageNo((p) => p + 1);
                      }
                }
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>

      <Modal centered show={show} onHide={handleClose} className="coupon_modal">
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title style={{ color: "red" }}>
            {selectedCoupon ? "Update" : "Create New"} Illuminate Coupon
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div
            style={{
              textAlign: "center",
              padding: "0 5px",
              fontSize: "12px",
              color: "green",
            }}
          >
            Change <span style={{ fontWeight: "600" }}>%</span> applies to price
            or no of searches depending upon product.
          </div>
          {selectedCoupon ? (
            <Form>
              <div className="planCreate" style={{ margin: "0px" }}>
                {/* <h1>Create New Coupon</h1> */}

                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Coupon Code</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="header"
                        value={selectedCoupon?.header}
                        type="text"
                        placeholder="Coupon Code"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Change (%)</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="discount"
                        value={selectedCoupon?.discount}
                        type="text"
                        placeholder="Discount"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>User Limit</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="user_limit"
                        value={selectedCoupon?.user_limit}
                        type="text"
                        placeholder="User Limit"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="col-lg-6">
                                <Form.Group>
                                    <Form.Label>Bonus Searches</Form.Label>
                                    <Form.Control onChange={handleChange} name="bonus-searches" value={coupon.discount} type="text" placeholder="Discount" />
                                </Form.Group>
                            </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="start_date"
                        value={selectedCoupon?.start_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="expiry_date"
                        value={selectedCoupon?.expiry_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="body"
                        value={selectedCoupon?.body}
                        as="textarea"
                        rows={3}
                        placeholder="Coupon Description"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Button
                        onClick={
                          loading ? null : () => updateCoupon(selectedCoupon)
                        }
                        style={{ width: "100%" }}
                      >
                        Update
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <Form>
              <div className="planCreate" style={{ margin: "0px" }}>
                {/* <h1>Create New Coupon</h1> */}

                <div className="row">
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Coupon Code</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="header"
                        value={coupon.header}
                        type="text"
                        placeholder="Coupon Code"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Change (%)</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="discount"
                        value={coupon.discount}
                        type="text"
                        placeholder="Discount"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>User Limit</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="user_limit"
                        value={coupon.user_limit}
                        type="text"
                        placeholder="User Limit"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="col-lg-6">
                                    <Form.Group>
                                        <Form.Label>Bonus Searches</Form.Label>
                                        <Form.Control onChange={handleChange} name="bonus-searches" value={coupon.discount} type="text" placeholder="Discount" />
                                    </Form.Group>
                                </div> */}
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="start_date"
                        value={coupon.start_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6">
                    <Form.Group>
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="expiry_date"
                        value={coupon.expiry_date}
                        type="date"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="body"
                        value={coupon.body}
                        as="textarea"
                        rows={3}
                        placeholder="Coupon Description"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-12">
                    <Form.Group>
                      <Button
                        className="button_shadow"
                        onClick={loading ? null : addCoupon}
                        style={{ width: "100%" }}
                      >
                        Add
                      </Button>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        // centered
        show={showCoupon}
        onHide={() => {
          setShowCoupon(false);
          setCouponInfo([]);
        }}
        className="enterprise_modal"
      >
        <Modal.Header style={{ backgroundColor: "#EDFAFF" }} closeButton>
          <Modal.Title
            style={{ color: "red", fontSize: "1rem", fontWeight: "600" }}
          >
            {couponInfo[0]?.coupon}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <div
            className="scrollableTabel autoHeight load_animation"
            style={{ margin: "0", maxHeight: "80vh" }}
            datatype="coupon"
          >
            <Table style={{ minWidth: "100%" }} striped responsive1>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Name</th>
                  {/* <th>Last Name</th> */}
                  <th style={{ textAlign: "center" }}>Redeem Date</th>
                </tr>
              </thead>
              <tbody>
                {couponInfo?.map((el) => {
                  return (
                    <tr>
                      <td>{el?.email}</td>
                      <td>
                        {el?.firstName} {el?.lastName}
                      </td>
                      {/* <td>{el?.lastName}</td> */}
                      <td style={{ textAlign: "center" }}>
                        {el.time_stamp?.slice(0, 10)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={loadCoupon}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader width={25} />
        </div>
      </Modal>
    </Layout>
  );
}
