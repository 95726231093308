import React, { useState } from "react";
import Layout from "../Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import add_user from "../../assets/images/add_user.png";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faPenSquare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import logo_inner from "../../assets/images/logo_inner.png";
import Upskill_logo_w_Illuminate from "../../assets/images/Upskill_logo_w_Illuminate.png";
import Illuminate_logo from "../../assets/images/Illuminate_logo.png";
import getSessionStorage from "../../customHooks/getSessionStorage";
import UploadProfileImage from "../UploadImage/UploadImage";

export default function EnterpriseMenu() {
  const [show, setShow] = useState(false);
  const temp = getSessionStorage();
  const profile = JSON.parse(localStorage.getItem("profile"));
  let hist = useHistory();
  const goTo = (e) => {
    e.preventDefault();
    let name = e.target.name;
    if (name == "add-users") {
      hist.push("/AddUsers", "/enterprise-menu");
    }
    if (name == "notifications") {
      return hist.push("/NotificationControl", {
        type: "enterprise",
        prevURL: hist.location,
      });
    }
    if (name == "dumped-users") {
      return hist.push("/exported-users", {
        prevURL: hist.location,
      });
    }
  };
  // console.log(profile);

  return (
    <Layout>
      <div className="box_with_shadow_padding load_animation enterprise-menu-padding">
        <div className="enterprise-menu" onClick={goTo}>
          <div className="org_logo_container">
            <div className="org_logo_edit">
              <span className="org_logo_hover_text">
                Change organisation logo.
              </span>
              <FontAwesomeIcon
                onClick={() => {
                  setShow((p) => !p);
                }}
                className="edit_company_logo"
                icon={faPenSquare}
                size="2x"
                color="#0f4c62"
              />
            </div>

            <img
              style={{
                width: "200px",
                height: "120px",
                boxShadow: "0 2px 8px 0 rgba(99,99,99,.2)",
                padding: "1rem",
                borderRadius: "5px",
                // backgroundColor: "#0f4c62",
              }}
              src={profile?.organisation_logo || Illuminate_logo}
              alt="Company Logo"
            />
          </div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            {temp?.companyName || "Enterprise"} Exclusive Menu
          </h1>
          <Button
            name="add-users"
            className="button_shadow"
            datatype="enterprise-menu"
          >
            {/* <Button variant="primary" type="submit" className="login"> */}
            <img name="add-users" src={add_user} className="" loading="lazy" />
            {/* <FontAwesomeIcon icon={faUserPlus} /> */}
            Manage Users
            {/* </Button> */}
          </Button>
          <Button
            style={{ display: "flex", justifyContent: "center", gap: "9px" }}
            className="button_shadow"
            datatype="enterprise-menu"
            name="notifications"
          >
            <FontAwesomeIcon
              name="notifications"
              icon={faBell}
              style={{ marginTop: "3.5px" }}
            />
            Manage Notifications
          </Button>
          <Button
            name="dumped-users"
            className="button_shadow"
            datatype="enterprise-menu"
          >
            <FontAwesomeIcon
              icon={faUsers}
              style={{ marginTop: "3.5px" }}
              name="dumped-users"
            />
            Exported Subordinates List
          </Button>
        </div>
      </div>

      <Modal show={show} centered onHide={() => setShow(false)}>
        <div className="box_with_shadow_padding" style={{ padding: "1rem" }}>
          <UploadProfileImage user_id={temp?.user_id} enterprise={true} />
        </div>
      </Modal>
    </Layout>
  );
}
