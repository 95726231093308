import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";

import login_logo_header from "../../assets/images/login_logo_header.png";
import login_img from "../../assets/images/login_img.png";
import eye_icon from "../../assets/images/eye_icon.png";
import linkend_white_login from "../../assets/images/linkend_white_login.png";
import { myContext } from "../../App";
import { api } from "../../assets/API/api";

import FooterTwo from "../Footer/FooterTwo";
import { Button } from "react-bootstrap";
import { useContext } from "react";
import { useEffect } from "react";
import axios from "axios";
// import LinkedInPage from '../LinkedIn/LinkedIn'
import { useLinkedIn } from "react-linkedin-login-oauth2";
// import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { Helmet } from "react-helmet";
import getActivePlans from "../../customHooks/getActivePlans";
import Loader from "../Loader/loader";

const SignIn = () => {
  const [input, setInput] = useState({ email: "", password: "" });
  const st = useContext(myContext);
  const [userInfo, setUserInfo] = st.userInfo;
  const [authenticate, setAuthenticate] = st.authentication;
  const [loginStatus, setLoginStatus] = useState("");
  const [balance, setBalance] = st.balance;
  const [myBalance, setMyBalance] = st.myBalance;
  const [trial, setTrial] = st.trial;
  const [activePlans, setActivePlans] = st.activePlans;
  const [addon, setAddon] = st.addon;
  const [ipData, setIpData] = st.ipData;
  const [loading, setLoading] = useState(false);
  const [sideBarActive, setSideBarActive] = st.sideBar;
  const [usedPoints, setUsedPoints] = st.usedPoints;
  const [recentSearches, setRecentSearches] = st.recentSearches;
  const [recentFavSearches, setRecentFaVSearches] = st.recentFavSearches;
  const [recentTransactions, setRecenttransactions] = st.recentTransactions;

  // linked in integration
  const [auth_code, setAuth_code] = useState("");
  // const [noAccount, setNoAccount] = useState(false);

  let history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
    setMyBalance({
      subscription_balance: 0,
      addon_balance: 0,
    });
    setUserInfo({
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      title: "Mr.",
      mobile: "",
      companyName: "",
      yourRole: "",
      companyWebsite: "",
      businessCategories: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      userType: "",
      profileImage: "",
      oldUser: false,
      addedBy: "",
      date: "",
      highestQualification: "",
    });
    // localStorage.setItem("user-data", JSON.stringify({}));
    localStorage.clear();
    setAuthenticate(false);
    setBalance({ got: 0, used: 0 });
    setActivePlans([]);
    setAddon([]);
    setAuthenticate(false);
    setIpData({});
    setTrial(false);
    setSideBarActive(0);
    setRecentFaVSearches([]);
    setRecentSearches([]);
    setRecenttransactions([]);
    setUsedPoints(0);
  }, []);

  // const noOfDays = (dd, mm, yy) => {
  //     const month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  //     let date = new Date();
  //     let cdd = date.getDate();
  //     cdd = parseInt(cdd);
  //     let cmm = date.getMonth() + 1;
  //     cmm = parseInt(cmm);
  //     let cyy = date.getFullYear();
  //     cyy = parseInt(cyy);

  //     const myPromise = new Promise((resolve, reject) => {

  //         if (dd > cdd) {
  //             cdd = cdd + month[mm - 1];
  //             cmm = cmm - 1;
  //         }

  //         if (mm > cmm) {
  //             cyy = cyy - 1;
  //             cmm = cmm + 12;
  //         }

  //         let calculated_date = cdd - dd;
  //         let calculated_month = cmm - mm;
  //         let calculated_year = cyy - yy;

  //         calculated_date = (calculated_year * 365) + (calculated_month * 30) + calculated_date;

  //         resolve(calculated_date);

  //     })
  //     return myPromise;
  // }

  const checkSignIn = async (e) => {
    let id = null;
    e.preventDefault();
    if (input.email === "" || input.password === "") {
      alert("please enter username and password!");
      return;
    }
    setLoginStatus("");
    setLoading(true);
    await axios
      .post(`${api}/signin`, {
        email: input.email,
        password: input.password,
      })
      .then(async (res) => {
        // console.log('login details= ', res.data);
        if (res.data.message == "wrong-password") {
          return setLoginStatus("wrong-password");
        }
        if (res.data.message == "no-user") {
          // setNoAccount(true);
          return setLoginStatus("no-user");
          // alert("This is not a registered email.  Please create an account.");
        }
        if (res.data?.user_id) {
          // let date = res.data?.date_of_joining.toString().slice(0, 10);
          // let yy = parseInt(date.slice(0, 4));
          // let mm = parseInt(date.slice(5, 7));
          // let dd = parseInt(date.slice(8, 10));

          // let ans = await noOfDays(dd, mm, yy)

          // // console.log("no of days after sign up ", ans);

          // if (ans > 5) {
          //     setTrial(false);
          // }
          // else {
          //     setTrial("yes")
          // }

          await setUserInfo({
            ...userInfo,
            email: input.email,
            firstName: res.data?.firstName,
            lastName: res.data?.lastName,
            mobile: res.data?.mobile,
            userType: res.data?.userType,
            id: res.data?.user_id,
            oldUser: true,
            addedBy: res.data?.added_by,
            date: res.data?.date_of_joining,
          });

          id = res.data?.user_id;

          localStorage.setItem(
            "user-data",
            JSON.stringify({ ...res.data, oldUser: true })
          );

          let actPlns = await getActivePlans(id);
          if (actPlns?.length) {
            setTrial(false);
          }
          setActivePlans(actPlns);

          history.push("/DashboardOldUser");
        }
        // return;
      })
      .catch((err) => {
        console.log("error: ", err);
      })
      .finally((f) => setLoading(false));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setLoginStatus("");
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  // console.log('verified user : ', userInfo);

  // linked in integration

  // const [auth_code, setAuth_code] = useState("");

  let rediUrl = "https://illuminate.upskilltalent.com/linkedin";

  let data = {
    grant_type: "authorization_code",
    code: auth_code,
    client_id: "86xka0j1n6v2z0",
    client_secret: "Z4sPGFpwvwpMWNYq",
    redirect_uri: rediUrl,
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: "86xka0j1n6v2z0",
    scope: "r_liteprofile+r_emailaddress",
    clientSecret: "Z4sPGFpwvwpMWNYq",
    // redirectUri: `http://localhost:3000/illuminate/build/linkedin`,
    redirectUri: rediUrl, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      setAuth_code(code);
      console.log("auth code: ", code);
      axios
        .post(`${api}/linkedinLogin`, { ...data, code: code })
        .then((res) => {
          // console.log("user data: ", res.data);
          setUserInfo({
            ...userInfo,
            email: res.data[0]?.email,
            firstName: res.data[0]?.firstName,
            lastName: res.data[0]?.lastName,
            mobile: res.data[0]?.mobile,
            userType: res.data[0]?.userType,
            id: res.data[0]?.user_id,
            oldUser: true,
            addedBy: res.data[0]?.added_by,
            date: res.data[0]?.date_of_joining,
          });
          localStorage.setItem("user-data", JSON.stringify(res.data[0]));
          history.push("/DashboardOldUser");
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  return (
    // <Layout>
    <>
      <Helmet>
        <title>Skills On Resumes Dashboard</title>
        <meta
          name="description"
          content="The best skills to list on your resume are easy to find with a free trial of the most powerful soft and hard skill management software. Try it today!"
        />
      </Helmet>
      <div className="container">
        <div className="row logiTop">
          <div className="col-lg-6 left">
            <div className="inner_box">
              <p>
                <img
                  src={login_logo_header}
                  className=""
                  loading="lazy"
                  alt=""
                />
              </p>
              <p>
                <img src={login_img} className="" loading="lazy" alt="" />
              </p>
              <h1>
                Unlocking Today's <span>Skills</span> For Tomorrow's Jobs
              </h1>
            </div>
          </div>
          <div className="col-lg-6 right">
            <div className="login_right_inner_box_outer">
              <div className="login_right_inner_box">
                <Form>
                  <h3 style={{ color: "red" }}>
                    {loginStatus === "wrong-password"
                      ? "Your password is incorrect. Please check it and enter again."
                      : loginStatus === "no-user"
                      ? "This is not a registered email. Please create an account."
                      : null}
                  </h3>
                  <p className="head">Login</p>
                  <div className="row">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="email"
                        type="email"
                        placeholder="Email"
                      />
                    </Form.Group>
                  </div>
                  <div className="row password_div">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="password_box">
                        <Form.Control
                          onChange={handleChange}
                          id="passId"
                          name="password"
                          type="password"
                          placeholder="Password"
                        />
                        <Button variant="primary" type="submit">
                          <img
                            onClick={(e) => {
                              e.preventDefault();
                              document.getElementById("passId").type = "text";
                            }}
                            src={eye_icon}
                            className=""
                            loading="lazy"
                            alt=""
                          />
                        </Button>
                        <p>
                          <Link
                            style={{
                              fontWeight: "500",
                              borderBottom: "1px solid red",
                            }}
                            to="/ForgotPassword"
                          >
                            Forgot Password?
                          </Link>
                        </p>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="row">
                    <div className="buttn_row">
                      <Button
                        disabled={loading}
                        onClick={loading ? null : checkSignIn}
                        variant="primary"
                        type="submit"
                        className="login"
                      >
                        {loading ? (
                          <>
                            <Loader color={"white"} width={25} />
                            <span style={{ marginLeft: "5px" }}>
                              Please wait..
                            </span>
                          </>
                        ) : (
                          "Login"
                        )}
                      </Button>
                    </div>

                    {/* <LinkedInPage /> */}

                    {/* <div className='divider'>
                                            <span>Or</span>
                                        </div>
                                        <div className='buttn_row'>
                                            <Button onClick={linkedInLogin} variant="primary" type="submit" className='social'>
                                                <img src={linkend_white_login} className="" loading="lazy" alt="" /> Sign in with LinkedIn
                                            </Button>
                                        </div> */}

                    {/* <div className='newUserP'>
                                            <p>New User? <Link to='/Signup'><span><a style={{ color: "blue", fontWeight: "700", borderBottom: "1.5px solid red" }} >Sign Up</a></span></Link> for your <span>free 5 day access.</span></p>
                                        </div> */}
                  </div>
                </Form>
                <div className="divider">
                  <span>Or</span>
                </div>
                <div className="buttn_row">
                  <Button
                    onClick={linkedInLogin}
                    variant="primary"
                    type="submit"
                    className="social"
                  >
                    <img
                      src={linkend_white_login}
                      className=""
                      loading="lazy"
                      alt=""
                    />{" "}
                    Sign in with LinkedIn
                  </Button>
                </div>
                <div className="newUserP">
                  <p>
                    New User?{" "}
                    <Link to="/Signup">
                      <span>
                        <a
                          style={{
                            color: "blue",
                            fontWeight: "700",
                            borderBottom: "1.5px solid red",
                          }}
                        >
                          Sign Up
                        </a>
                      </span>
                    </Link>{" "}
                    for your <span>free 5 day access.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loginBottom">
          <FooterTwo />
        </div>
      </div>
    </>
    // </Layout>
  );
};

export default SignIn;
