import React, { useEffect, useRef, useState } from "react";
import Layout from "../Layout/Layout";
import { Button } from "react-bootstrap";
import axios from "axios";
import getSessionStorage from "../../customHooks/getSessionStorage";
import { api } from "../../assets/API/api";
import Loader from "../Loader/loader";
import { useHistory } from "react-router-dom";
// import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";

import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const customSelectStyle = {
  multiValue: (base) => ({
    ...base,
    maxWidth: "fit-content",
    minWidth: "fit-content",
    backgroundColor: "#D4E9DB",
    fontWeight: "500",
    fontSize: "1rem",
    border: "1px solid #4E9152",
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: "10px",
    fontWeight: "500",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (base) => ({
    ...base,
    color: "#101D14",
    cursor: "pointer",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: "pointer",
  }),
  valueContainer: (base) => ({
    ...base,
    maxWidth: "fit-content",
  }),
  multiValueLabel: (css) => ({
    ...css,
    maxWidth: "fit-content",
  }),
};

const customTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: "#D4E9DB",
    primary: "#1A301B",
  },
});

// let educationArr = [],
//   techToolsArr = [],
//   softSkillsArr = [],
//   hardSillsArr = [],
//   skillsAppliedArr = [],
//   knowledgeArr = [];

export default function SearchBySkills() {
  const temp = getSessionStorage();
  const [education, setEducation] = useState([]);
  const [techTools, setTechTools] = useState([]);
  const [softSkills, setsoftSkills] = useState([]);
  const [hardSills, sethardSills] = useState([]);
  const [skillsApplied, setskillsApplied] = useState([]);
  const [knowledge, setknowledge] = useState([]);
  // const [input, setInput] = useState({
  //   soft_skills: "",
  //   hard_skills: "",
  //   education: "",
  //   tech_tools: "",
  //   skills_applied: "",
  //   knowledge: "",
  // });
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  // const [show, setShow] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [options, setOptions] = useState({ softSkills: [] });

  const getAllSkills = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${api}/get-all-skills/?tokenMail=${temp?.email}`
      );
      // console.log(data);
      let {
        soft_skills,
        hard_skills,
        education,
        tech_tools,
        skills_applied,
        knowledge,
      } = data;

      setEducation(education?.map((e) => ({ ...e, selected: false })));
      // educationArr = education?.map((e) => ({ ...e, selected: false }));
      setTechTools(tech_tools?.map((e) => ({ ...e, selected: false })));
      // techToolsArr = tech_tools?.map((e) => ({ ...e, selected: false }));
      sethardSills(hard_skills?.map((e) => ({ ...e, selected: false })));
      // hardSillsArr = hard_skills?.map((e) => ({ ...e, selected: false }));
      setknowledge(knowledge?.map((e) => ({ ...e, selected: false })));
      // knowledgeArr = knowledge?.map((e) => ({ ...e, selected: false }));
      setskillsApplied(skills_applied?.map((e) => ({ ...e, selected: false })));
      // skillsAppliedArr = skills_applied?.map((e) => ({
      //   ...e,
      //   selected: false,
      // }));
      setsoftSkills(soft_skills?.map((e) => ({ ...e, selected: false })));
      // softSkillsArr = soft_skills?.map((e) => ({ ...e, selected: false }));
    } catch (err) {
      console.log(err);
      alert("Something went wrong!");
    }
    setLoading(false);
  };

  useEffect(() => {
    setOptions({
      ...options,
      softSkills: softSkills.map((e) => {
        return {
          value: e?.Soft_Skills,
          label: e?.Soft_Skills,
          Soft_Skills: e?.Soft_Skills,
          key: "Soft_Skills",
        };
      }),
      hardSkills: hardSills.map((e) => {
        return {
          value: e?.Hard_Skills,
          label: e?.Hard_Skills,
          Hard_Skills: e?.Hard_Skills,
          key: "Hard_Skills",
        };
      }),
      skillsApplied: skillsApplied.map((e) => {
        return {
          value: e?.Skills_Applied,
          label: e?.Skills_Applied,
          Skills_Applied: e?.Skills_Applied,
          key: "Skills_Applied",
        };
      }),
      techTools: techTools.map((e) => {
        return {
          value: e?.Technology_Tools,
          label: e?.Technology_Tools,
          Technology_Tools: e?.Technology_Tools,
          key: "Technology_Tools",
        };
      }),
      education: education.map((e) => {
        return {
          value: e?.Education,
          label: e?.Education,
          Education: e?.Education,
          key: "Education",
        };
      }),
      knowledge: knowledge.map((e) => {
        return {
          value: e?.Knowledge,
          label: e?.Knowledge,
          Knowledge: e?.Knowledge,
          key: "Knowledge",
        };
      }),
    });
  }, [softSkills, hardSills, skillsApplied, techTools, education, knowledge]);

  const ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    ref.current?.scrollIntoView({ behavior: "smooth" });

    if (!temp?.user_id) {
      return;
    }
    getAllSkills();
  }, []);

  useEffect(() => {
    setSelectedSkills({
      softSkills: softSkills
        ?.filter((e) => e.selected)
        ?.map((e) => {
          return {
            Soft_Skills: e.Soft_Skills,
          };
        }),
      hardSkills: hardSills
        ?.filter((e) => e.selected)
        ?.map((e) => {
          return { Hard_Skills: e.Hard_Skills };
        }),
      skillsApplied: skillsApplied
        ?.filter((e) => e.selected)
        ?.map((e) => {
          return { Skills_Applied: e.Skills_Applied };
        }),
      techTools: techTools
        ?.filter((e) => e.selected)
        ?.map((e) => {
          return { Technology_Tools: e.Technology_Tools };
        }),
      education: education
        ?.filter((e) => e.selected)
        ?.map((e) => {
          return { Education: e.Education };
        }),
      knowledge: knowledge
        ?.filter((e) => e.selected)
        ?.map((e) => {
          return { Knowledge: e.Knowledge };
        }),
    });
  }, [softSkills, hardSills, skillsApplied, techTools, education, knowledge]);

  const loadOptions = (searchValue, callback, key) => {
    setTimeout(() => {
      const filteredOptions = options?.[key]?.filter((option) =>
        option?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
      console.log(searchValue, filteredOptions, options?.knowledge);
      callback(filteredOptions);
    }, 500);
  };

  // const changeHandler = (e) => {
  //   e.preventDefault();
  //   let name = e.target.name;
  //   let val = e.target.value;

  //   if (name === "soft_skills") {
  //     setsoftSkills(
  //       softSkillsArr.filter((e) =>
  //         e?.Soft_Skills?.toLowerCase()?.includes(val?.toLowerCase())
  //       )
  //     );
  //     setShow({ softSkills: true });
  //   }
  //   if (name === "hard_skills") {
  //     sethardSills(
  //       hardSillsArr.filter((e) =>
  //         e?.Hard_Skills?.toLowerCase()?.includes(val?.toLowerCase())
  //       )
  //     );
  //     setShow({ hardSkills: true });
  //   }
  //   if (name === "tech_tools") {
  //     setTechTools(
  //       techToolsArr.filter((e) =>
  //         e?.Technology_Tools?.toLowerCase()?.includes(val?.toLowerCase())
  //       )
  //     );
  //     setShow({ techTools: true });
  //   }
  //   if (name === "skills_applied") {
  //     setskillsApplied(
  //       skillsAppliedArr.filter((e) =>
  //         e?.Skills_Applied?.toLowerCase()?.includes(val?.toLowerCase())
  //       )
  //     );
  //     setShow({ skillsApplied: true });
  //   }
  //   if (name === "education") {
  //     setEducation(
  //       educationArr.filter((e) =>
  //         e?.Education?.toLowerCase()?.includes(val?.toLowerCase())
  //       )
  //     );
  //     setShow({ education: true });
  //   }
  //   if (name === "knowledge") {
  //     setknowledge(
  //       knowledgeArr.filter((e) =>
  //         e?.Knowledge?.toLowerCase()?.includes(val?.toLowerCase())
  //       )
  //     );
  //     setShow({ knowledge: true });
  //   }

  //   setInput({ ...input, [name]: val });
  //   if (!val) {
  //     return setShow(null);
  //   }
  // };

  // const handleCheck = (name, idx) => {
  //   if (name === "soft_skills") {
  //     let tempArr = [...softSkills];
  //     tempArr[idx].selected = !tempArr[idx]?.selected;
  //     setsoftSkills(tempArr);
  //   }
  //   if (name === "hard_skills") {
  //     let tempArr = [...hardSills];
  //     tempArr[idx].selected = !tempArr[idx]?.selected;
  //     sethardSills(tempArr);
  //   }
  //   if (name === "skills_applied") {
  //     let tempArr = [...skillsApplied];
  //     tempArr[idx].selected = !tempArr[idx]?.selected;
  //     setskillsApplied(tempArr);
  //   }
  //   if (name === "tech_tools") {
  //     let tempArr = [...techTools];
  //     tempArr[idx].selected = !tempArr[idx]?.selected;
  //     setTechTools(tempArr);
  //   }
  //   if (name === "education") {
  //     let tempArr = [...education];
  //     tempArr[idx].selected = !tempArr[idx]?.selected;
  //     setEducation(tempArr);
  //   }
  //   if (name === "knowledge") {
  //     let tempArr = [...knowledge];
  //     tempArr[idx].selected = !tempArr[idx]?.selected;
  //     setknowledge(tempArr);
  //   }
  // };

  const hist = useHistory();

  const getSkillMatchedOccupations = async () => {
    try {
      if (
        selectedSkills?.softSkills?.length ||
        selectedSkills?.hardSkills?.length ||
        selectedSkills?.skillsApplied?.length ||
        selectedSkills?.techTools?.length ||
        selectedSkills?.education?.length ||
        selectedSkills?.knowledge?.length
      ) {
        setLoad(true);
        const { data } = await axios.post(
          `${api}/get-skills-matched-occupations/?tokenMail=${temp?.email}`,
          { selectedSkills: selectedSkills }
        );
        if (!data?.length) {
          setLoad(false);
          return alert("No Occupation Found!");
        }
        setLoad(false);
        hist.push("/occupation-results", data);
        return;
      } else {
        setLoad(false);
        return alert("All fileds can not be empty!");
      }
    } catch (err) {
      console.log(err);
      setLoad(false);
      return alert("No Occupation Found!");
    }
  };

  // console.log(selectedSkills);

  return (
    <Layout ref={ref} sideBarActive={9}>
      {loading ? (
        <Loader />
      ) : (
        <div className="search_by_skills_container load_animation">
          <div className="search_by_skills_lower_body">
            <div className="center bold_text">
              Skill and Certification Search Engine
            </div>
            <div className="center desc">
              Enter at least one skill and certification in the box desired.
              Check off multiple boxes if more than one skill in each area.
              Results will appear below.
            </div>
            <div className="option_container">
              <div className="options_box" datatype="blur">
                {/* <input
                  onChange={changeHandler}
                  onFocus={() => {
                    setShow(null);
                  }}
                  name="soft_skills"
                  value={selectedSkills?.softSkills[0]?.Soft_Skills || ""}
                  type="text"
                  placeholder="Type any soft skills"
                /> */}
                <div>
                  <label>Soft Skills:</label>
                  <AsyncSelect
                    styles={customSelectStyle}
                    theme={customTheme}
                    components={animatedComponents}
                    isMulti
                    loadOptions={(searchValue, callback) =>
                      loadOptions(searchValue, callback, "softSkills")
                    }
                    onChange={(e) => {
                      setSelectedSkills({
                        ...selectedSkills,
                        softSkills: e,
                      });
                    }}
                    placeholder={"Type any soft skills.."}
                    className="basic-multi-select"
                    classNamePrefix="react-select"
                  />
                  {/* <Select
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, softSkills: e });
                    }}
                    placeholder={"Type any soft skills or use the dropdown"}
                    isMulti
                    // components={animatedComponents}
                    options={options?.softSkills}
                    className="basic-multi-select"
                  /> */}
                </div>
                {/* {show?.softSkills && (
                  <div className="skills_list">
                    {softSkills.length > 1 &&
                      softSkills.map((el, idx) => {
                        return (
                          <div>
                            <input
                              name="soft_skills"
                              onChange={(e) => {
                                handleCheck(e.target.name, idx);
                              }}
                              checked={el?.selected}
                              type="checkbox"
                            />
                            <span>{el?.Soft_Skills}</span>
                          </div>
                        );
                      })}
                  </div>
                )} */}
              </div>
              <div className="options_box">
                {/* <input
                  onFocus={() => {
                    setShow(null);
                  }}
                  onChange={changeHandler}
                  name="hard_skills"
                  type="text"
                  placeholder="Type any hard skills"
                /> */}
                <div>
                  <label>Hard Skills:</label>
                  <AsyncSelect
                    styles={customSelectStyle}
                    theme={customTheme}
                    components={animatedComponents}
                    isMulti
                    loadOptions={(searchValue, callback) =>
                      loadOptions(searchValue, callback, "hardSkills")
                    }
                    onChange={(e) => {
                      setSelectedSkills({
                        ...selectedSkills,
                        hardSkills: e,
                      });
                    }}
                    placeholder={"Type any hard skills.."}
                    className="basic-multi-select"
                  />
                  {/* <Select
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, hardSkills: e });
                    }}
                    placeholder={"Type any hard skills or use the dropdown"}
                    isMulti
                    // components={animatedComponents}
                    options={options?.hardSkills}
                    className="basic-multi-select"
                  /> */}
                </div>
                {/* {show?.hardSkills && (
                  <div className="skills_list">
                    {hardSills.length > 1 &&
                      hardSills.map((el, idx) => {
                        return (
                          <div>
                            <input
                              name="hard_skills"
                              onChange={(e) => {
                                handleCheck(e.target.name, idx);
                              }}
                              checked={el?.selected}
                              type="checkbox"
                            />
                            <span>{el?.Hard_Skills}</span>
                          </div>
                        );
                      })}
                  </div>
                )} */}
              </div>
              <div className="options_box">
                {/* <input
                  onFocus={() => {
                    setShow(null);
                  }}
                  onChange={changeHandler}
                  name="skills_applied"
                  type="text"
                  placeholder="Type any skills applied"
                /> */}
                <div>
                  <label>Skills Applied:</label>
                  <AsyncSelect
                    styles={customSelectStyle}
                    theme={customTheme}
                    components={animatedComponents}
                    isMulti
                    loadOptions={(searchValue, callback) =>
                      loadOptions(searchValue, callback, "skillsApplied")
                    }
                    onChange={(e) => {
                      setSelectedSkills({
                        ...selectedSkills,
                        skillsApplied: e,
                      });
                    }}
                    placeholder={"Type any skills applied.."}
                    className="basic-multi-select"
                  />
                  {/* <Select
                    onChange={(e) => {
                      setSelectedSkills({
                        ...selectedSkills,
                        skillsApplied: e,
                      });
                    }}
                    placeholder={"Type any skills applied or use the dropdown"}
                    isMulti
                    // components={animatedComponents}
                    options={options?.skillsApplied}
                    className="basic-multi-select"
                  /> */}
                </div>
                {/* {show?.skillsApplied && (
                  <div className="skills_list">
                    {skillsApplied.length > 1 &&
                      skillsApplied.map((el, idx) => {
                        return (
                          <div>
                            <input
                              name="skills_applied"
                              onChange={(e) => {
                                handleCheck(e.target.name, idx);
                              }}
                              checked={el?.selected}
                              type="checkbox"
                            />
                            <span>{el?.Skills_Applied}</span>
                          </div>
                        );
                      })}
                  </div>
                )} */}
              </div>
              <div className="options_box">
                {/* <input
                  onFocus={() => {
                    setShow(null);
                  }}
                  onChange={changeHandler}
                  name="tech_tools"
                  type="text"
                  placeholder="Type any technology tools"
                /> */}
                <div>
                  <label>Technology Tools:</label>
                  <AsyncSelect
                    styles={customSelectStyle}
                    theme={customTheme}
                    components={animatedComponents}
                    isMulti
                    loadOptions={(searchValue, callback) =>
                      loadOptions(searchValue, callback, "techTools")
                    }
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, techTools: e });
                    }}
                    placeholder={"Type any technology tools.."}
                    className="basic-multi-select"
                  />
                  {/* <Select
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, techTools: e });
                    }}
                    placeholder={
                      "Type any technology tools or use the dropdown"
                    }
                    isMulti
                    // components={animatedComponents}
                    options={options?.techTools}
                    className="basic-multi-select"
                  /> */}
                </div>
                {/* {show?.techTools && (
                  <div className="skills_list">
                    {techTools.length > 1 &&
                      techTools.map((el, idx) => {
                        return (
                          <div>
                            <input
                              name="tech_tools"
                              onChange={(e) => {
                                handleCheck(e.target.name, idx);
                              }}
                              checked={el?.selected}
                              type="checkbox"
                            />
                            <span>{el?.Technology_Tools}</span>
                          </div>
                        );
                      })}
                  </div>
                )} */}
              </div>
              <div className="options_box">
                {/* <input
                  onFocus={() => {
                    setShow(null);
                  }}
                  onChange={changeHandler}
                  name="education"
                  type="text"
                  placeholder="Type any education and certification"
                /> */}
                <div>
                  <label>Education and Certification:</label>
                  <AsyncSelect
                    styles={customSelectStyle}
                    theme={customTheme}
                    components={animatedComponents}
                    isMulti
                    loadOptions={(searchValue, callback) =>
                      loadOptions(searchValue, callback, "education")
                    }
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, education: e });
                    }}
                    placeholder={"Type any education and certification.."}
                    className="basic-multi-select"
                  />
                  {/* <Select
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, education: e });
                    }}
                    placeholder={
                      "Type any education and certification or use the dropdown"
                    }
                    isMulti
                    // components={animatedComponents}
                    options={options?.education}
                    className="basic-multi-select"
                  /> */}
                </div>
                {/* {show?.education && (
                  <div className="skills_list">
                    {education.length > 1 &&
                      education.map((el, idx) => {
                        return (
                          <div>
                            <input
                              name="education"
                              onChange={(e) => {
                                handleCheck(e.target.name, idx);
                              }}
                              checked={el?.selected}
                              type="checkbox"
                            />
                            <span>{el?.Education}</span>
                          </div>
                        );
                      })}
                  </div>
                )} */}
              </div>
              <div className="options_box">
                {/* <input
                  onFocus={() => {
                    setShow(null);
                  }}
                  onChange={changeHandler}
                  name="knowledge"
                  type="text"
                  placeholder="Type any knowledge applied"
                /> */}
                <div>
                  <label>Knowledge:</label>
                  <AsyncSelect
                    styles={customSelectStyle}
                    theme={customTheme}
                    components={animatedComponents}
                    isMulti
                    loadOptions={(searchValue, callback) =>
                      loadOptions(searchValue, callback, "knowledge")
                    }
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, knowledge: e });
                    }}
                    placeholder={"Type any knowledge applied.."}
                    className="basic-multi-select"
                  />
                  {/* <Select
                    filterOption={createFilter({ ignoreAccents: false })}
                    onChange={(e) => {
                      setSelectedSkills({ ...selectedSkills, knowledge: e });
                    }}
                    placeholder={
                      "Type any knowledge applied or use the dropdown"
                    }
                    isMulti
                    // components={animatedComponents}
                    options={options?.knowledge}
                    className="basic-multi-select"
                  /> */}
                </div>
                {/* {show?.knowledge && (
                  <div className="skills_list">
                    {knowledge.length > 1 &&
                      knowledge.map((el, idx) => {
                        return (
                          <div>
                            <input
                              name="knowledge"
                              onChange={(e) => {
                                handleCheck(e.target.name, idx);
                              }}
                              checked={el?.selected}
                              type="checkbox"
                            />
                            <span>{el?.Knowledge}</span>
                          </div>
                        );
                      })}
                  </div>
                )} */}
              </div>
            </div>
            {/* <div style={{ margin: "2rem 0" }}>
              <div
                className="selected_skills"
                style={{
                  display: softSkills?.filter((e) => e.selected)?.length
                    ? "flex"
                    : "none",
                }}
              >
                <span>Soft Skills:</span>
                {softSkills.map((el, idx) => {
                  return el.selected ? <span>{el?.Soft_Skills}</span> : null;
                })}
              </div>
              <div
                className="selected_skills"
                style={{
                  display: hardSills?.filter((e) => e.selected)?.length
                    ? "flex"
                    : "none",
                }}
              >
                <span>Hard Skills:</span>
                {hardSills.map((el, idx) => {
                  return el.selected ? <span>{el?.Hard_Skills}</span> : null;
                })}
              </div>
              <div
                className="selected_skills"
                style={{
                  display: skillsApplied?.filter((e) => e.selected)?.length
                    ? "flex"
                    : "none",
                }}
              >
                <span>Skills Applied:</span>
                {skillsApplied.map((el, idx) => {
                  return el.selected ? <span>{el?.Skills_Applied}</span> : null;
                })}
              </div>
              <div
                className="selected_skills"
                style={{
                  display: techTools?.filter((e) => e.selected)?.length
                    ? "flex"
                    : "none",
                }}
              >
                <span>Tech Tools:</span>
                {techTools.map((el, idx) => {
                  return el.selected ? (
                    <span>{el?.Technology_Tools}</span>
                  ) : null;
                })}
              </div>
              <div
                className="selected_skills"
                style={{
                  display: education?.filter((e) => e.selected)?.length
                    ? "flex"
                    : "none",
                }}
              >
                <span>Education:</span>
                {education.map((el, idx) => {
                  return el.selected ? <span>{el?.Education}</span> : null;
                })}
              </div>
              <div
                className="selected_skills"
                style={{
                  display: knowledge?.filter((e) => e.selected)?.length
                    ? "flex"
                    : "none",
                }}
              >
                <span>Knowledge:</span>
                {knowledge.map((el, idx) => {
                  return el.selected ? <span>{el?.Knowledge}</span> : null;
                })}
              </div>
            </div> */}
            <div className="skill_button_box">
              <Button
                disabled={load}
                onClick={() => {
                  getSkillMatchedOccupations();
                }}
              >
                {load ? (
                  <>
                    <abbr
                      style={{
                        marginRight: "5px",
                        background: "",
                        borderRadius: "3px",
                      }}
                    >
                      <Loader width={25} color={"white"} />
                    </abbr>
                    Please wait...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
